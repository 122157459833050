import { Button, Flex, PasswordField, Text, TextField } from '@aws-amplify/ui-react';
import { Grid } from '@mui/material';
import React from 'react'
import awsresetcode from '../aws_utils/awsresetcode';
import awsresetpassword from '../aws_utils/awsresetpassword';
import { useNavigate } from "react-router-dom";


export default function ResetPassword() {

  const navigate = useNavigate()
  const [email, setemail] = React.useState("")
  const [noEmail, setnoEmail] = React.useState(true)
  const [verificationCodeSent, setverificationCodeSent] = React.useState(false)
  let errMessage = ""
  const getCode = async (email) => {
    const res = await awsresetcode(email)
    if(res.err){  
      errMessage = res.err
      const errorMess = errMessage.toString()
      const parts = errorMess.split(':');
      const newMessage = parts.slice(1).join(':').trim();
      alert(newMessage)
    }
    setverificationCodeSent(true)
    alert('Successfully sent verification code to email!')
  }

  const [code, setCode] = React.useState("")
  const [newPassword, setnewPassword] = React.useState("")
  const [doReset, setdoReset] = React.useState(true)
  const resetPassword = async () => {
    
      const res = await awsresetpassword(email,code,newPassword)
      if(res.err){  
        errMessage = res.err
        const errorMess = errMessage.toString()
        const parts = errorMess.split(':');
        const newMessage = parts.slice(1).join(':').trim();
        alert(newMessage)
      }
      alert('Successfully Updated Password!')
      setverificationCodeSent(false)
      navigate("/")

      
  }

  React.useEffect(() => {
    if(email === ""){
      setnoEmail(true)
    } else {
      setnoEmail(false)
    }

    if(code === "" || newPassword === ""){
      setdoReset(true)
    } else {
      setdoReset(false)
    }
  }, [email, code, newPassword])
  






  return (
    <Grid container
// spacing={2}
  // minWidth="unset"
  //  minHeight="unset"
   maxWidth="620px"
   minWidth="300px"
   height="auto"
  //  maxHeight="200vh"
  // display={(props.showsignup ? "flex" : "none")}
  flexWrap="nowrap"
  // overflow="scroll"
  // gap="unset"
  // alignItems="center"
  // justifyItems="center"
  justifyContent="center"
  // paddingLeft="20px"
  // paddingRight="20px"
  paddingTop="40px"
  paddingBottom="40px"
  position="relative"
  boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
      borderRadius="1px"
      
      backgroundColor="rgba(255,255,255,.8)"

  
  
//   backgroundColor="green"

>

  <Grid item container
    // maxWidth="524px"
    // minWidth="400px"
    // height="auto"
    display="block"
    // width="100px"
    gap="unset"
    direction="column"
    alignItems="unset"
    justifyContent="unset"
    position="relative"
    
    // top="10px"
    // marginLeft="20px"
    // marginRight="20px"
    // paddingLeft="100px"
    // paddingLeft="20px"
    // paddingRight="20px"
    // left="48px"
    // paddingTop="20px"
    paddingLeft="42px"
    paddingRight="42px"
    // padding="0px 0px 0px 0px"
    // backgroundColor="grey"
   
  >
   

{/* Sign Up Text  */}
<Grid container
position="relative"
width="100%"
height="auto"
// paddingBottom="20px"
// padding
// backgroundColor="brown"
>
    
      <Text
        fontFamily="Nunito"
        fontSize="40px"
        fontWeight="700"
        color="rgba(222,136,101,1)"
        lineHeight="48px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="-0.85px"
        width="100%"
        height="auto"
        
        gap="unset"
        alignItems="unset"
        position="relative"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Password Reset"
       
      ></Text>

    </Grid>
{/* Sign Up Text  */}


    <Grid container
      as="form"
      gap="20px"
      direction="column"
      width="unset"
      // height="100%"
      display="flex"
      // justifyContent="flex-start"
      // alignItems="flex-start"
      position="relative"
      // top="117px"
      left="0px"
      // padding="0px 0px 0px 0px"
      paddingTop="15px"
      // backgroundColor="yellow"
 
    >

{/* Enter Username, Email, Password, Forgot password. Reset New Group */}
     

{/* Enter Email TextField*/}
      <TextField
            // width="unset"
            width="unset"
            // maxWidth="524px"
            // height="48px"
            display="block"
            
            // variation="quiet"
            // size="default"
            // gap="unset"
            // alignItems="unset"
            // justifyContent="unset"
            // shrink="0"
            position="relative"
            // padding="0px 0px 0px 0px"
            disabled={verificationCodeSent}
            paddingTop="5px"
            // border="1.5px SOLID rgba(131,168,184,1)"
            // borderRadius="8px"   
            borderRadius="8px"   
            backgroundColor="rgba(255,255,255,1)"
            placeholder="Enter Email ..."
            className="Sign-Up"
            value={email}
                    onChange={(e) => {
                    let { value } = e.target;
                    setemail(value);
                    
                     }}

    //         value={username}
    // onChange={(e) => {
    //   let { value } = e.target;
    //   setusername(value);
    // }}
    // onBlur={() => runValidationTasks("username", username)}
    // errorMessage={errors.username?.errorMessage}
    // hasError={errors.username?.hasError}
           
          >  
      </TextField>

{/* Send Verification Button */}
      <Button
        variation="primary"
        width="unset"
        height="48px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        // top="12px"
        position="relative"
        borderRadius="24px"
        padding="0px 0px 0px 0px"
        isDisabled={noEmail}
        onClick={() => {
          getCode(email)
        }}
        backgroundColor="rgba(222,136,101,1)"
        // opacity="0.5"
       
      >

        <Flex
        //   gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          position="relative"
          // backgroundColor="red"
        //   top="calc(50% - 12px - 0px)"
        //   left="calc(50% - 33px - 0px)"
          padding="0px 0px 0px 0px"
          // opacity="0.6499999761581421"
         
        >
          <Text
            fontFamily="Nunito"
            fontSize="19px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Send Verification Code"
          
          ></Text>

        </Flex>
      </Button>

      <div
      className='h-3'>
      </div>

{/* Enter Email TextField */}
      <TextField
            width="unset"
            // height="48px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            // border="0px SOLID "
            borderRadius="8px"
            placeholder="Enter Code ..."
            backgroundColor="rgba(255,255,255,1)"
            
           
            className="Sign-Up"
            value={code}
            onChange={(e) => {
            let { value } = e.target;
            setCode(value);
             }}
             >
          
                {/* <Text
                  fontFamily="Nunito"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(107,107,107,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Enter Email ..."
                  {...getOverrideProps(overrides, "Placeholder2144")}
                ></Text> */}
            
         
      </TextField>

{/* Enter Password TextField */}
      <PasswordField
            width="unset"
            // height="48px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            borderRadius="8px"
            placeholder="Enter Password ..."
            // className="custom-passwordfield"
            backgroundColor="rgba(243,244,246,1)"
            name="password"
            autoComplete="new-password"
            value={newPassword}
                    onChange={(e) => {
                    let { value } = e.target;
                    setnewPassword(value);
                    
                     }}
            
            // isRequired={true}
        //     value={password}
        //    hasError={passerrors}
        //    errorMessage={errorMessage}
        //     onChange={(e) => {
        //     let { value } = e.target;
        //     if (passValidationRegex.test(value)){
        //     setpassword(value);
        //     setpasserrors(false)
        //     setisdisabled(false)
        //     } else {
        //       setpassword(value);
        //       setpasserrors(true)
        //       setisdisabled(true)
        //     }
        //      }}
          >
        
            
      </PasswordField>


      <Button
        variation="primary"
        width="unset"
        height="48px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        // top="12px"
        position="relative"
        borderRadius="24px"
        padding="0px 0px 0px 0px"
        isDisabled={doReset}
        onClick={() => {
          resetPassword()
        }}
        backgroundColor="rgba(222,136,101,1)"
        // opacity="0.5"
       
      >

        <Flex
        //   gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          position="relative"
          // backgroundColor="red"
        //   top="calc(50% - 12px - 0px)"
        //   left="calc(50% - 33px - 0px)"
          padding="0px 0px 0px 0px"
          // opacity="0.6499999761581421"
         
        >
          <Text
            fontFamily="Nunito"
            fontSize="19px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Reset Password"
          
          ></Text>

        </Flex>
      </Button>


      {/* <Text
        fontFamily="Nunito"
        fontSize="14px"
        fontWeight="600"
        color="black"
        // backgroundColor="red"
        lineHeight="26px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Questions? Email us at support@daybreak.health"
      ></Text> */}


    </Grid>

  </Grid>


  
{/* </Grid> */}


    {/* </Grid> */}


    {/* </motion.div>
    </motion.section>  */}

    

  



    </Grid>
  )
}
