
import { Button, Flex, Text } from '@aws-amplify/ui-react'
import { Grid, TextField } from '@mui/material'
import React from 'react'
import createNewProject from '../realm-scripts/createNewProject'


function NewProject(props) {
    const selectproject = props.selectproject
    const closeprojectmodal = props.closeprojectmodal
    const [projectname, setprojectname] = React.useState("")
    const [isDisabled, setisDisabled] = React.useState(true)
    const [isLoading, setisLoading] = React.useState(false)



    const anchorElement = document.getElementById('Agents');
  
    const topPos = anchorElement.getBoundingClientRect().top
    

  

    const createProject = async (projectname) => {
        if(projectname === ""){
            alert("Enter Project Name")
            setisLoading(false)
            return
        }

        try {
            await createNewProject(projectname)
            // console.log(resp)
            setisLoading(false)
            
            selectproject(projectname)
            closeprojectmodal()
            
 
        window.scrollTo({
            top: topPos,
            // top: document.body.scrollHeight - 1000,
            left:"",
            behavior: "smooth"
          });
            return
            
        } catch (error) {
            console.log(error)
            return
        }
        
    }

    React.useEffect(() => {
        if(projectname === null || projectname === ""){
            setisDisabled(true)
            return
        }
        setisDisabled(false)
     
    }, [projectname])
    

  return (

    <Grid container
    position="relative"
    // backgroundColor="grey"backgroundColor="rgba(225,255,255,.3)"
    backgroundColor="rgba(225,255,255,.3)"
    box-shadow= "0px 5px 15px rgba(0, 0, 0, 0.862)"
    // backgroundColor="pink"
    width="100%"
    padding="20px 20px 35px 20px"
    gap="18px"
    // borderRadius="25px"
    
    // display="flex"
    justifyContent="center"
    alignItems="center"
    direction="column"
    as="form"
    >
      
       
        
    <Text
    color="white"
    height="auto"
    fontWeight="bold"
    fontSize="20px"
    paddingTop="5px"
    // textDecoration="underline"
    // backgroundColor="pink"

    
    
    // backgroundColor="rgba(222,136,101,1)"
    >
        Create New Project
    </Text>

    <TextField
    className=''
    placeholder="Enter Project Name..."
    name="project name" 
    value={projectname}
    onChange={(e) => {
        let { value } = e.target;
        setprojectname(value);

    }}
    >
        
    </TextField>

    <Flex
    position="relative"
    paddingTop="5px">
    
    <Button
    variation='primary'
    className='Project'
    backgroundColor="rgba(255,255,255,.3)"
    
    
    isLoading={isLoading}
    isDisabled={isDisabled}
    onClick={() =>{
        setisLoading(true)
        createProject(projectname)
        
        // closeprojectmodal()
        // window.scrollTo({
        //     top: document.body.scrollHeight - 1000,
        //     left:"",
        //     behavior: "smooth"
        //   });
    }}
    >
        Create Project
    </Button>
   
    </Flex>

 


    </Grid>
  )

}

export default NewProject