const portal = async (customerid) => {

    const stripe = require('stripe')(process.env.REACT_APP_STRIPEKEY);

    // The price ID passed from the client
    //   const {priceId} = req.body;
    const priceId = process.env.REACT_APP_STRIPE_PRODUCT;
    
    const session = await stripe.checkout.sessions.create({
      mode: 'subscription',
      customer: customerid,
      allow_promotion_codes: true,
      subscription_data: {
        trial_period_days: 30,
      },
      line_items: [
        {
          price: priceId,
          // For metered billing, do not pass quantity
          quantity: 1,
        },
      ],
      // {CHECKOUT_SESSION_ID} is a string literal; do not change it!
      // the actual Session ID is returned in the query parameter when your customer
      // is redirected to the success page.
      success_url: 'https://www.gateleak.com/Home',
      cancel_url: 'https://www.gateleak.com/Home',
    });
    // console.log("Session?", session)
    return session.url
}    

export default portal