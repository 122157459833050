import { Auth } from "aws-amplify"


const getuser = async () => {

    try {
        const user = await Auth.currentAuthenticatedUser()
        // const usercred = await Auth.currentUserCredentials()
        // console.log(usercred)
        return user  
    } catch (error) {   
        // console.log(error)
        return error
    }
    

    }

export default getuser

