import { Storage } from "aws-amplify";


export default async function s3storageremove(key) {

    // console.log("Remove", key)

    const response = await Storage.remove(key,
         { level: 'private' }
         );
    
    alert(`Successfully removed document!`)
    return response
}
