


export default async function realmGetUser(sub) {

    const Realm = require("realm-web"); 
    const REALM_ID = process.env.REACT_APP_REALM_APP_ID;
    const REALM_KEY = process.env.REACT_APP_REALM_APP_KEY;
    const app = new Realm.App({id: REALM_ID});
    const {
      BSON: { ObjectId },
    } = Realm;
  
    // Authenticate the user
    try {
      const credentials = Realm.Credentials.apiKey(REALM_KEY)
      await app.logIn(credentials);
    //   console.log(user)
    } catch (error) {
      console.log("Error in Logging", error) 
    }

    const mongo = app.currentUser.mongoClient("dbhaiapp");
    const collection = mongo.db("dbhappusers").collection("users");
    
    let user = ""
    try {
    // if(event.data.object.object === checkout.session){}
      user = await collection.findOne(
        { cognito_sub : sub }
        );
        
      // console.log("User", user._id);
      return user
    } catch (error) {
      console.log("Error finding User", error)
      return error  
    } 

    // return user

}
