


const mongoadduser = async (username, email, sub, stripeid) => {

    
    const Realm = require("realm-web"); 
    const REALM_ID = process.env.REACT_APP_REALM_APP_ID;
    const REALM_KEY = process.env.REACT_APP_REALM_APP_KEY;
    const app = new Realm.App({id: REALM_ID});
    const {
      BSON: { ObjectId },
    } = Realm;

     // Authenticate the user
     try {
        const credentials = Realm.Credentials.apiKey(REALM_KEY)
        await app.logIn(credentials);
      //console.log(user)
      } catch (error) {
  
        console.log("Error in Logging", error);
  
      }
    //   const getauthuser = await Auth.currentAuthenticatedUser()
    // //   console.log("Auth user from Js", getauthuser)
    //   const cognitoid = getauthuser.username

      
      const mongo = app.currentUser.mongoClient("dbhaiapp");
      const collection = mongo.db("dbhappusers").collection("users");

      const result = await collection.insertOne({ 
        username: username, 
        email: email,
        cognito_sub: sub,
        all_agents : false,
        stripe_id : stripeid
    
     });
    //   console.log(result);
      return result

}

export default mongoadduser
    
 
    
   
    


