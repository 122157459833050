import React from 'react'
import dummyagent from '../langchain/dummyagent'
import getUserChat from '../realm-scripts/getUserChat'
import { Button, Collection, Flex, Text, View } from '@aws-amplify/ui-react'
import { Grid, TextField } from '@mui/material'
import ReactMarkdown from 'react-markdown';
import {motion} from "framer-motion"
import { PropagateLoader } from 'react-spinners'
import GetImageUrl from '../custom-components/GetImageUrl'
import { fadeIn, staggerContainer } from '../aws_utils/motion';


export default function ModalDummyAgent(props) {
    const [query, setQuery] = React.useState("")
    const [isloading, setisloading] = React.useState(false)

    const currentproject = props.projectname
    const closeQueryBox = props.closeQueryBox
    const [projectconversation, setprojectconversation] = React.useState([])
    const UserChat = async () => {
      const userschat = await getUserChat(currentproject)
      // console.log("User's Project Chat", userschat)

      const convo = userschat.filter(convo => convo.image !== true)
      setprojectconversation(convo)
      }
    React.useEffect(() => {
      // console.log("Current Project", currentproject)
      if(currentproject === "" || currentproject === null || currentproject === undefined){
        return
      }
      UserChat()
    }, [currentproject])

    const [newquery, setnewquery] = React.useState(false)
    React.useEffect(() => {
      // console.log("New query triggered", newquery)
      const refreshquerybox = async () => {
        UserChat()
        setnewquery(false)
        // setisloading(false)
      }
      refreshquerybox()
    }, [newquery])
    

    const QueryDummyAgent = async () => {
      setisloading(true)
      if(currentproject === "" || currentproject === null || currentproject === undefined){
        setisloading(false);
        window.scrollTo(0,0);
        alert("Select or Create Project!")
        // console.log("Select Project");
        return
        }
        if(query === ""){
            
            setisloading(false)
            alert("Enter query!")
            // console.log("Query")
            return
        }
        await dummyagent(query, currentproject)
        setnewquery(true)
        setisloading(false)
        setQuery(" ")
        window.scrollTo(0,document.body.scrollHeight);
        
    }

    const [isAnimated, setisAnimated] = React.useState(false)


  return (
    <View
    position="relative"
    //   paddingTop="5px"
      paddingBottom="0px"
      height="auto"
      >

    <motion.section
          variants={staggerContainer()}
          initial="hidden"
          whileInView="show"
          viewport={{once: true, amount: 0.9}}
          >
    
            
            <motion.div
              variants={fadeIn("right",)}
            //   style={{
            //     left : "0px"
            //   }}
            //   className="rounded-[20px] green-pink-gradient p-[1px]  shadow-card"
              >
                
    <Flex
    position="fixed"
    width="145px"
    height="40px"
    gap="2px"
    borderRadius="15px"
    backgroundColor="rgba(255,255,255,.6)"
    boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
    justifyContent="flex-end"
    alignItems="center"
    paddingRight="5px"
    top="105px"
    left="-10px"
    style={{
        zIndex: 999
    }}
    >
        <Text
        fontWeight="bold"
        fontSize="small"
        // backgroundColor="green"
        >
            Dummy Agent   
        </Text>

        <motion.button
       
        whileHover={{
          scale : 1.8,
        //   animationDuration: 1
        //   fontColor: "rgba(255,255,255,1)"
        //   paddingLeft : "25px"
        }}
        whileTap={{
          scale: 0.9,
 
        }}
        initial={{
            // color: "rgba(255,255,255,1)"
        }}
        animate={{
            x : isAnimated ? 600 : 0,
            rotate: isAnimated ? 360 : 0,
            fontSize: isAnimated ? "700px" : "18px",
            transition: {
                duration : 3,
            }
        }}
        >
        <Text
        position="relative"
        width="20px"
        textAlign="center"
        fontWeight="bold"
        color="black"
        paddingBottom="1px"
        // fontSize="small"
        onClick={() => {
            setisAnimated(!isAnimated)
            setTimeout(closeQueryBox ,1500)
            
        }}
        // backgroundColor="pink"
        >
            x
        </Text>
        </motion.button>
    </Flex>
    </motion.div>
    </motion.section>

    {/* Query texfield plus button */}
      <Flex
        // backgroundColor="green"
        width="100%"
        height="auto"
        justifyContent="center"
        alignItems="center"
        direction="column"
        paddingTop="20px"
        paddingBottom="20px"
        gap="10px"
        >
     
      <TextField
        position="relative"
        // className="border-red"
        // color="success"
        // boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
        // backgroundColor="blu  e"
        
        // height="500px"
       multiline
       fullWidth
       
       id="outlined-basic" 
       label="Enter Query ..." 
       variant="outlined" 
      //  color='white'
      //  color='white'
      // color="secondary"
      
      
        className=' text-[rgba(255,255,255,1)]'
        // top='-20px'
        value={query}
        onChange={(e) => {
            let { value } = e.target;
            setQuery(value);
          }}
        height="85px"
      />

      
<motion.div
        initial={{
            // width : "100%",
        }}
        whileHover={{
          scale : 1.1,
        //   paddingLeft : "25px"
        }}
        whileTap={{
          scale: 0.9,
          
        }}
        
        >
         <Button
         position="relative"
      height="30px"
      color="black"
      className='CollectionCard'
      variation='primary'
      isLoading={isloading}
      boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
    //   isDisabled={dataloaded ? false : true}
    onClick={()=> {
        QueryDummyAgent()
       
    }}
     
      >
        Query
         </Button>
         </motion.div>
      </Flex>
    {/* Query texfield plus button */}
       
     {/* The query box itself */}
  
     <motion.div
initial={{ opacity: 0, scale: 0.5 }}
animate={{ opacity: 1, scale: 1 }}
transition={{
  duration: 0.2,
  ease: [0, 0.50, 0.2, 1.01],
  scale: {
    type: "spring",
    damping: 5,
    stiffness: 100,
    restDelta: 0.001
  }
}}
>
      <Grid container
        
        // 
        // backgroundColor="var(--amplify-colors-white)"
       
        backgroundColor="rgba(255,255,255,.6)"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.5)"
        borderRadius="16px"
        // color="var(--amplify-colors-blue-60)"
        // height="552px"
        maxHeight="68vh"
        // minHeight="100%"
        width="auto"
        paddingLeft="20px"
        paddingRight="20px"
        paddingTop="20px"
        paddingBottom="20px"
        direction="column"
        // width="100%"
        display="block"
        sx={{
          overflowX:"hidden",
          overflowY: "scroll"
        }}
       
        
        // wrap="wrap"
        // style={{
        //   // overflowX:"hidden",
        //   // wra
        
        // }}
        
        

        
       
        // width="500px"
        // onClick={() => alert('🏔 What a beautiful <View>! 🔭')}
        >

      
        {isloading ? 
       <Grid
       container
       display="flex"
       position="relative"
       justifyContent="center"
       alignItems='center'

       width="100%"
       height="310px"
       // paddingRight="50px"
       // paddingTop="40px"
      //  backgroundColor="pink"
       >
         <Flex
         justifyContent="flex-start"
         alignItems="center"
         position="relative"
         paddingRight="50px"
         paddingBottom="50px"
         width="40px"
         height="40px"
         // backgroundColor="brown"
         >
         <PropagateLoader
       loading="true"
       // color='#de8865cc'
       color='white'
       size="50px"
       /> 
         </Flex>
       </Grid>
        : 
          <Collection
        items={projectconversation || []}
        type="list"
        direction="column-reverse"
        position="relative"
        searchNoResultsFound=" "
        // searchPlaceholder="Search Projects..."
        // width="100%"
        // columnGap="15px"
        // isSearchable
        // paddingLeft="20px"
        // paddingRight="20px"
        // paddingTop="10px"
        // paddingBottom="20px"
        // backgroundColor="pink"
        // overflow="scroll"
        // style={{
        //     zIndex: 8
        // }}
        // isPaginated
        // itemsPerPage={2}  
    >
      {(item, index) => (   
          <Flex
          position="relative"
          // backgroundColor="pink"
          direction="column"
          gap="10px"
          paddingTop="0px"
          key={index}
          >

<Grid container
          position="relative"
          width="100%"
          // backgroundColor="rgba(255,255,255,.8)"
          // backgroundColor="rgba(222,136,101,.8)"
          backgroundColor="grey"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
          padding="10px 10px 10px 10px"
          borderRadius="16px"
          direction="column"
          gap="5px"
          sx={{
            textOverflow:"ellipsis",
            wordWrap: "break-word",
            lineBreak:"anywhere"
          
        }}
          >
          <Text
          // fontSize="15px"
          // backgroundColor="pink"
          color="white"
          >
          Query: 
          </Text>

          <ReactMarkdown
          className={"text-white"}
          >
            
          
          {item.query}
          
          
        
         
          </ReactMarkdown>
          <GetImageUrl img={item.images || []}/>

          </Grid>

          

          <Flex
          position="relative"
          width="100%"
          backgroundColor="rgba(255,255,255,.6)"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
          padding="10px 10px 10px 10px"
          borderRadius="16px"
          direction="column"
          gap="5px"
          >
            <Text
            color="black"
          // backgroundColor="pink"
          >
          Response: 
          </Text>

          <ReactMarkdown
          components={{
           
            code({node, inline, className, children, ...props}) {
              const match = /language-(\w)/.exec(className || "")
              return !inline && match ? ( 
             
                <Grid container
              position="relative"
              paddingLeft="20px"
              paddingRight="20px"
              fontSize="15px"
              fontWeight="bold"
                  width="auto"
                  wrap="wrap"
                  whiteSpace="pre-wrap"
                  
                  sx={{
                    textOverflow:"ellipsis",
                    wordWrap: "break-word",
                    lineBreak:"anywhere"
                }}
                  
               
                  
                 {... props}>
                

                 
                  {children}
                  
                </Grid> 
            
                ) : <span
                className=' font-bold'
                // position="relative"
                >
                  {children}

                </span>
            }
          }}
          children={item.response}

        
          />
        
          
          </Flex>

          </Flex>
      )}
          </Collection>
          }
          
      </Grid>
      </motion.div>

    {/* The query box itself */}
 
      
   
     </View>
  )
}
