import { Button, Flex, PasswordField, Text, TextField, View } from '@aws-amplify/ui-react';
import { Grid } from '@mui/material';
import React from 'react'
import signup from '../aws_utils/awssignup';
import Confirmation from './Confirmation';

export default function SignUp(props) {

  const [username, setusername] = React.useState("")
  const [email, setemail] = React.useState("")
  const [password, setpassword] = React.useState("")

  const passValidationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{6,}$/;
  // const validationRegex = /^.+$/ 
  const errorMessage = `Requires uppercase, lowercase, and special character. Minimum of 6 characters`;
  const [passerrors, setpasserrors] = React.useState(false);
  const [isdisabled, setisdisabled] = React.useState(true)
  const [showconfirmation, setshowconfirmation] = React.useState(false)


  // const navigate = useNavigate()

 
  const setconfirm = async () => {
    setshowconfirmation(false)
  }
  
  React.useEffect(() => {
 
    if(email === "" || password === "" || username === ""){
        setisdisabled(true)
    } else {
        setisdisabled(false)
    }
  
  }, [email, password, username])

  

  const SignUp = async () => {
    try {
      const data = await signup(username, email, password)
      // console.log("Sign Up Data", data)

      if(data.tag === "error"){
      setshowconfirmation(false)
      
      } else {
        setshowconfirmation(true)
      }





    } catch (error) {
      setshowconfirmation(false)
      // alert(error)

    }
    
  }


  return (

    <Grid container 
    position="relative"
    width="100%"
    // display="block"
    // height="100%"
    // paddingBottom="10px"
    // padding=" 40px 40px 40px 40px"
    // display={props.showsignup ? "flex": "none"}
    // height="100%"
    // direction="row"
    // wrap='nowrap'
    // backgroundColor="red"
    // backgroundColor="red"
    justifyContent="center"
    alignItems="center"
    // paddingBottom="20px"
    >




    {/* // <motion.section
    //     variants={staggerContainer()}
    //     initial="show"
    //     whileInView='show'
    //     viewport={{ once: false, amount: 0.25 }}
        
    //     // className={`${styles.padding}
    //     // max-w-7xl mx-auto
    //     //  relative z-0`}
    //   >
    // <motion.div 
    // variants={slideIn('left','tween', 0.2, 1)}
    // // className='flex-[075] bg-black-100 p-8 rounded-[10px] xs:w-[100vw]'
    
    // > */}

    {showconfirmation ? 

    <Grid container
    position="relative"
    // width="auto"
    height="auto"
    // display={showconfirmation ? "flex" : "none"}
    display="block"
    // justifyContent="center"
    // alignItems="center"
    // backgroundColor='red'

  >
    {/* <motion.section
        variants={staggerContainer()}
        // initial="show"
        whileInView='show'
        viewport={{ once: false, amount: 0.25 }}
        
        // className={`${styles.padding}
        // max-w-7xl mx-auto
        //  relative z-0`}
      >
    <motion.div 
    variants={slideIn('left','tween', 0.2, 1)}
    
    > */}


    <Confirmation 
    email={email}
    username={username}
    confirm={setconfirm}
   
    />

    {/* </motion.div>
    </motion.section> */}


    </Grid> 
    : 
    <Grid container
// spacing={2}
  // minWidth="unset"
  //  minHeight="unset"
   maxWidth="620px"
   minWidth="300px"
  //  height=""
  //  maxHeight="200vh"
  // display={(props.showsignup ? "flex" : "none")}
  flexWrap="nowrap"
  // overflow="scroll"
  // gap="unset"
  // alignItems="center"
  // justifyItems="center"
  justifyContent="center"
  // paddingLeft="20px"
  // paddingRight="20px"
  paddingTop="45px"
  paddingBottom="60px"
  position="relative"
  boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
      borderRadius="16px"
      
      backgroundColor="rgba(255,255,255,.4)"

  
  
  // backgroundColor="green"

>







  <Grid item container
    // maxWidth="524px"
    // minWidth="400px"
    height="auto"
    display="flex"
    gap="unset"
    direction="column"
    alignItems="unset"
    justifyContent="unset"
    position="relative"
    // top="10px"
    // marginLeft="20px"
    // marginRight="20px"
    // paddingLeft="100px"
    // paddingLeft="20px"
    // paddingRight="20px"
    // left="48px"
    // paddingTop="20px"
    paddingLeft="52px"
    paddingRight="52px"
    // padding="0px 0px 0px 0px"
    // backgroundColor="grey"
   
  >
   

{/* Sign Up Text  */}
<Grid container
position="relative"
width="100%"
height="60px"
// paddingBottom="20px"
// padding
// backgroundColor="brown"
>
    <View
      padding="0px 0px 0px 0px"
      width="100%"
      height="47px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      // paddingBottom="20px"
      // backgroundColor="pink"
      // top="46px"
      // left="52px"
      
     
    >
      <Text
        fontFamily="Nunito"
        fontSize="40px"
        fontWeight="700"
        color="white"
        lineHeight="48px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="-0.85px"
        width="100%"
        height="47px"
        gap="unset"
        alignItems="unset"
        position="relative"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Sign Up"
       
      ></Text>
    </View>
    </Grid>
{/* Sign Up Text  */}


    <Grid container
      as="form"
      gap="20px"
      direction="column"
      width="unset"
      // height="100%"
      display="flex"
      // justifyContent="flex-start"
      // alignItems="flex-start"
      position="relative"
      // top="117px"
      left="0px"
      // padding="0px 0px 0px 0px"
      paddingTop="15px"
      // backgroundColor="yellow"
 
    >

{/* Enter Username, Email, Password, Forgot password. Reset New Group */}
     
     

{/* Enter Username TextField              */}
      <TextField
            // width="unset"
            width="unset"
            // maxWidth="524px"
            // height="48px"
            display="block"
            
            // variation="quiet"
            // size="default"
            // gap="unset"
            // alignItems="unset"
            // justifyContent="unset"
            // shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            // border="1.5px SOLID rgba(131,168,184,1)"
            // borderRadius="8px"   
            borderRadius="8px"   
            backgroundColor="rgba(255,255,255,1)"
            placeholder="Enter Username ..."
            className="Sign-Up"

            value={username}
    onChange={(e) => {
      let { value } = e.target;
      setusername(value);
    }}
    // onBlur={() => runValidationTasks("username", username)}
    // errorMessage={errors.username?.errorMessage}
    // hasError={errors.username?.hasError}
           
          >  
      </TextField>

{/* Enter Email TextField */}
      <TextField
            width="unset"
            // height="48px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            // border="0px SOLID "
            borderRadius="8px"
            placeholder="Enter Email ..."
            backgroundColor="rgba(255,255,255,1)"
           
            className="Sign-Up"
            value={email}
            onChange={(e) => {
            let { value } = e.target;
            setemail(value);
             }}
             >
          
                {/* <Text
                  fontFamily="Nunito"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(107,107,107,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Enter Email ..."
                  {...getOverrideProps(overrides, "Placeholder2144")}
                ></Text> */}
            
         
      </TextField>

{/* Enter Password TextField */}
      <PasswordField
            width="unset"
            // height="48px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            borderRadius="8px"
            placeholder="Enter Password ..."
            // className="custom-passwordfield"
            backgroundColor="rgba(243,244,246,1)"
            name="password"
            autoComplete="new-password"
            
            // isRequired={true}
            value={password}
           hasError={passerrors}
           errorMessage={errorMessage}
            onChange={(e) => {
            let { value } = e.target;
            if (passValidationRegex.test(value)){
            setpassword(value);
            setpasserrors(false)
            setisdisabled(false)
            } else {
              setpassword(value);
              setpasserrors(true)
              setisdisabled(true)
            }
             }}
          >
        
            
      </PasswordField>


      <Button
        variation="primary"
        width="unset"
        height="48px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        // top="12px"
        position="relative"
        borderRadius="24px"
        padding="0px 0px 0px 0px"
        isDisabled={isdisabled}
        onClick={() => {
          SignUp()
        }}
        backgroundColor="rgba(255,255,255,.4)"
        // opacity="0.5"
       
      >

        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          // backgroundColor="red"
          top="calc(50% - 12px - 0px)"
          left="calc(50% - 33px - 0px)"
          padding="0px 0px 0px 0px"
          // opacity="0.6499999761581421"
         
        >
          <Text
            fontFamily="Nunito"
            fontSize="19px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Sign up"
          
          ></Text>

        </Flex>
      </Button>


      {/* <Text
        fontFamily="Nunito"
        fontSize="14px"
        fontWeight="600"
        color="black"
        // backgroundColor="red"
        lineHeight="26px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Questions? Email us at support@daybreak.health"
      ></Text> */}


    </Grid>

  </Grid>


  
{/* </Grid> */}


    {/* </Grid> */}


    {/* </motion.div>
    </motion.section>  */}

    

  



    </Grid>

    }

    </Grid>


  )
}





