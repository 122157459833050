import getuser from "../aws_utils/getAuthuser";

export default async function deleteUserProjectChat(projectname) {
    const Realm = require("realm-web"); 
    const REALM_ID = process.env.REACT_APP_REALM_APP_ID;
    const REALM_KEY = process.env.REACT_APP_REALM_APP_KEY;
    const app = new Realm.App({id: REALM_ID});
    const {
        BSON: { ObjectId },
    } = Realm;

  // Authenticate the user
    try {
        const credentials = Realm.Credentials.apiKey(REALM_KEY);
        await app.logIn(credentials);
        
    } catch (error) {
        console.log("Error in Logging", error);
    }
   
    let userid = ""
    try {
        const currentuser = await getuser();
        userid = currentuser.username;
    } catch (error) {
        console.log(error);
    }

  
    const mongo = app.currentUser.mongoClient("dbhaiapp");
    const collectionProject = mongo.db("dbhprojects").collection("usersproject");
    const collectionChat = mongo.db("dbhprojects").collection("userschat");

    try {
        await collectionProject.deleteOne({ 
            "userid": userid,
            "projectname": projectname,

        });
        // console.log(userid)
        console.log("Project deleted")
        // return projects
    } catch (error) {
        console.log(error)
        return error
    }

    try {
        await collectionChat.deleteMany({ 
            "userId": userid,
            "project": projectname,
        });
        console.log("Chats deleted")
    
    } catch (error) {
        console.log(error)
        return error
    }

    return



}