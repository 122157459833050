import { Button, Flex, Image, PasswordField, Text, TextField, View} from "@aws-amplify/ui-react";
import { Grid } from "@mui/material";
import Modal from 'react-modal'

import "../App.css"





import React from 'react';

import { useNavigate } from "react-router-dom";
import signIn from "../aws_utils/awssignin";
import SignUp from "./SignUp";
import { Tilt } from "react-tilt";
import getuser from "../aws_utils/getAuthuser";
import ResetPassword from "./ResetPassword";

function SignIn() {
    const navigate = useNavigate()
    const [userdata, setuserdata] = React.useState({})

    const [resetmodal, setresetmodal] = React.useState(false)
    const PasswordResetModal = () => {
      setresetmodal(false)
    }

    React.useEffect(() => {
       const GetUser = async () => {
       const user = await getuser()
      //  console.log(user)
        
        if(user === "The user is not authenticated"){
            // console.log("The user is not authenticated")
            return
        
        // if(isuser === true){
        //   navigate("Home")

        } else {
          navigate("/Home")
            
        }
      }  
        
       GetUser()
    }, [])


    const [email, setemail] = React.useState("")
    const [password, setpassword] = React.useState("")
    const [isdisabled, setisdisabled] = React.useState(true)
    const [showsignup, setshowsignup] = React.useState(false)
    // const [shownav, setshownav] = React.useState(true)
    const [isloading, setisloading] = React.useState(false)

    // const setnav = async () => {
    //   setshownav(false)
    // }

    const go = async () => {
    setTimeout(() => {
      // console.log('This will be logged after 3 seconds');
      navigate("/Home")
    }, 3000);
  }

    const login = async () => {
        try {
            const user = await signIn(email, password)
            // console.log("Successful SignIn",)

            if(user === undefined){
              return
            }
            alert("Succesfully Logged In")
            setuserdata(user)
            setisloading(true)
            go()
            // const delay =  setTimeout(() => {
            //     console.log('This will be logged after 3 seconds');
            //     navigate("Home")
            //     setisloading(false)

            // })
            
            // const delay =  setTimeout(() => {
            //   console.log('This will be logged after 3 seconds');
            //   navigate("Home")
            // }, 3000);
            // console.log("User Signed In")
        } catch (error) {
            
            console.log(error)
        }
    }

    React.useEffect(() => {
       
        if(email === "" || password === "" ){
            setisdisabled(true)
        } else {
            setisdisabled(false)
        }
      
    }, [email, password])
    


    return (

    <Grid container
    position="relative"
    width="100%"
    // height="100%"
    // padding=" 0px 0px 40px 0px"
    paddingLeft="40px"
    paddingRight="40px"
    display="flex"
    height="100vh"
    direction="column"
    wrap="nowrap"
    overflow="hidden"
    // backgroundColor="red"
    // backgroundColor="rgba(222,136,101,.7)"
    justifyContent="center"
    alignItems="center"
    >
      <Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    // src="/home.gif"
    src={'/gateleakv2.gif'}
    // srcSet='/home.svg 300w'
    alt=" "
    width="100%"
    height="100%"
    objectFit="cover"
    // objectPosition="top"
    position="absolute"
    // overflow="scroll"
    />

   

{ showsignup ?
     
    <Tilt>
    <Button
    position="relative"
    width="100%"
    // width="200px"
    height="50px"
    variation="primary"
    // display={showsignup ?  "block" : "none"}
    
    // backgroundColor="white"
    // justifyContent="center"
    // alignItems="center"
    className="SignInUp"
    color="black"
    // paddingBottom={}
    marginBottom="20px"
    boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
      borderRadius="16px"
      textAlign="center"
      onClick={()=>{
        setshowsignup(false)
       
        // navigate("/")
      }}
    >
      <Text
      color="white">
      Sign In
      </Text>
    </Button>
    </Tilt> 
    :
    <Tilt>
    <Button
    position="relative"
    width="100%"
    height="50px"
    variation="primary"
    // display={showsignup ? "none" : "block"}
    
    // backgroundColor="white"
    justifyContent="center"
    alignItems="center"
    className="SignInUp"
    // color="black"
    // paddingBottom={}
    marginBottom="20px"
    boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
      borderRadius="16px"
      textAlign="center"
      onClick={()=>{
        setshowsignup(true)
        
      }}
    >
      <Text
      color="white">
      Sign Up
      </Text>
    </Button>
    </Tilt> 
}


    {showsignup ? <SignUp showsignup={showsignup} /> :
      <Grid container 
    // spacing={2}
      // minWidth="unset"
       minHeight="unset"
       maxWidth="620px"
       minWidth="300px"
      //  height="480px"
      //  maxHeight="200vh"
      display={showsignup ?  "none ": "block"}
      wrap="nowrap"
      // overflow="scroll"
      // gap="unset"
      // alignItems="center"
      // justifyItems="center"
      justifyContent="center"
      // paddingLeft="20px"
      // paddingRight="20px"
      position="relative"
      boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
      borderRadius="16px"
      paddingBottom="60px"
      
      backgroundColor="rgba(255,255,255,.4)"
      // padding="0px 0px 0px 0px"
    //   borderRadius="16px"
    //   backgroundColor="rgba(26,26,26,1)"
      >

       <View
        as="form"
                >

      <Grid container item
        // maxWidth="524px"
        // minWidth="400px"
        // height="438px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="relative"
        paddingTop="50px"
        // marginLeft="20px"
        // marginRight="20px"
        // paddingLeft="100px"
        // paddingLeft="20px"
        // paddingRight="20px"
        // left="48px"
        paddingLeft="52px"
        paddingRight="52px"
        // padding="0px 0px 0px 0px"
        // backgroundColor="grey"
      >

        <Flex
          gap="24px"
          direction="column"
          // width="unset"
          height="unset"
          width="unset"
          // justifyContent="flex-start"
          // alignItems="flex-start"
          // shrink="10px"
          position="relative"
          padding="0px 0px 0px 0px"
          // backgroundColor="red"
        >

        <Text
            fontFamily="Nunito"
            fontSize="40px"
            fontWeight="700"
            color="white"
            lineHeight="48px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="-0.85px"
            width="unset"
            height="47px"
            gap="unset"
            alignItems="unset"
            position="relative"
            top="0px"
            left="0px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Sign In"
            // backgroundColor="pink"
          
          ></Text>


             
               
                

             <TextField
                    fontFamily="Nunito"
                    fontSize="16px"
                    fontWeight="400"
                    color="black"
                    lineHeight="24px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    placeholder="Enter email ..."
                    backgroundColor="white"
                    border="0px SOLID black"
                    borderRadius="8px"
                    // name="username"
                    // name="name"
                    // label="username"
                    name="username" 
                    autoComplete="username"

                    // className="Sign-In"
                    value={email}
                    onChange={(e) => {
                    let { value } = e.target;
                    setemail(value);
                     }}
                   
            ></TextField>
                 
          {/* Password Field Input   */}
                         
               
              <PasswordField
                  borderRadius="8px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="white"
                    fontFamily="Nunito"
                    fontSize="16px"
                    fontWeight="400"
                    color="black"
                    lineHeight="24px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    
                    shrink="0"


                    position="relative"
                   
                    // padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    placeholder="Enter password ..."
                    name="password"
                    autoComplete="new-password"
                    // className="custom-passwordfield"
                    value={password}
                    onChange={(e) => {
                    let { value } = e.target;
                    setpassword(value);
                     }}>
                      </PasswordField> 
                 
                  
               
              
          {/* Password Field Input   */}
          
           

        <Flex
              // gap="1px"
              direction="row"
              width="unset"
              height="unset"
              // justifyContent="flex-start"
              // alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"

            >
            <Text
                fontFamily="Nunito"
                fontSize="14px"
                fontWeight="400"
                color="white"
                lineHeight="20px"
                textAlign="left"
                display="block"
                
                direction="column"
                justifyContent="unset"
                width="100px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Forget password?"
              
            ></Text>

            <Flex
                gap="0"
                direction="row"
                width="78px"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
              
              >
                <Button
                  gap="0.5px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  variation="link"
                  onClick={() => {
                    setresetmodal(true)
                    // console.log("Clicked?")
                  }}
                  
                >
                  <Text
                    fontFamily="Nunito"
                    fontSize="14px"
                    fontWeight="700"
                    color="white"
                    lineHeight="20px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.1px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Reset Password"
                   
                  ></Text>


                  <View
                    width="unset"
                    height="1.5px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    backgroundColor="black"
                    opacity="0.30000001192092896"
                
                  ></View>
                </Button>
            </Flex>
        </Flex>
         

        <Button
            width="unset"
            height="48px"
            display="flex"
            gap="unset"
            alignItems="unset"
            justifyContent="center"
            shrink="0"
            position="relative"
            borderRadius="24px"
            padding="0px 0px 0px 0px"
            // type="submit"
            backgroundColor="rgba(255,255,255,.4)"
            isDisabled={isdisabled}
            isLoading={isloading}
            // opacity="0.5"
            // className="Horizontal-NavButton"
            onClick={()=>{
              login()
            }}
           
          >
            <Flex
              gap="8px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="calc(50% - 12px - 0px)"
              left="calc(50% - 30px - 0px)"
              padding="0px 0px 0px 0px"
              // opacity="0.6499999761581421"
              
            >
              <Text
                fontFamily="Nunito"
                fontSize="19px"
                fontWeight="600"
                color="white"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Sign in"
              
              ></Text>
            </Flex>
        </Button>


        {/* <Text
            fontFamily="Nunito"
            fontSize="14px"
            fontWeight="600"
            color="rgba(107,107,107,1)"
            lineHeight="26px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Questions? Email us at support@daybreak.health"
           
        ></Text> */}

        </Flex>

        
      </Grid>

      </View>



      </Grid>}
      <Modal
            isOpen={resetmodal}
            onRequestClose={PasswordResetModal}
            style={{
                overlay: {
                position: 'fixed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                zIndex: 999,
                padding: "20px"
                },
                content: {
                position: 'relative',
        //   display
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                border: '1px solid #ccc',
                background: "transparent",
        //   width: "302px",
        //   overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '15px',
                outline: 'none',
                padding: '0px'
                }
            }}
            >
            <ResetPassword/>
            {/* </ReactMarkdown> */}
            {/* <SignIn /> */}

      </Modal>
   

    </Grid>


  );
};

export default SignIn



