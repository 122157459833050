

export default async function portalsession(customerid) {

    const stripe = require('stripe')(process.env.REACT_APP_STRIPEKEY);

    try {
        const session = await stripe.billingPortal.sessions.create({
            customer: customerid,
            return_url: 'https://www.gateleak.com/Home',
          });
      
          // console.log("Session?", session.url)
          return session.url
      
    } catch (error) {
      // console.log(error)
        return error
    }
    

}
