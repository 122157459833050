import { Storage } from "aws-amplify";


const StorageGet = async (file) => {

    try {
        const data = await Storage.get(file, { level: 'private' });
        return data
        
    } catch (error) {
        return error
    }



}

export default StorageGet