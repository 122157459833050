import './App.css';
import React from "react";
import { Route, Routes } from "react-router-dom";
import DbhaiHome from "./custom-components/DbhaiHome";
import SignIn from './auth-components/SignIn';
import UnAuthHome from './custom-components/UnAuthHome';

function App() {

   
  return (

    <Routes>
      {/* <Route path="/" element={<SignIn/>}/> */}
      <Route path="/Home" element={<DbhaiHome/>}>
        {/* <Route path="CaseCollection" element={<CaseCollection/>}/> */}
      </Route>
      <Route path="/" element={<UnAuthHome/>}/>
      
      <Route path="*" element={<SignIn/>}/>
      <Route path="/GetStarted" element={<SignIn/>}/>

      {/* <Route path="/GetStarted" element={<DownPage/>}/>
      <Route path="*" element={<DownPage/>}/> */}
      
    </Routes>

  );
}

export default App;
