

export default async function createstripecustomer(email) {
    const stripe = require('stripe')(process.env.REACT_APP_STRIPEKEY);

    const customer = await stripe.customers.create({
        email: email
    });

    // console.log(customer)
    return customer

}
