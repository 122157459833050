import { InvokeCommand, LambdaClient } from '@aws-sdk/client-lambda';
import getuser from '../aws_utils/getAuthuser';


export default async function invokeimageagent(query, imgData, projectname) {

const Realm = require("realm-web"); 
const REALM_ID = process.env.REACT_APP_REALM_APP_ID;
const REALM_KEY = process.env.REACT_APP_REALM_APP_KEY;
const app = new Realm.App({id: REALM_ID});
const {
  BSON: { ObjectId },
} = Realm;

// Authenticate the user
try {
  const credentials = Realm.Credentials.apiKey(REALM_KEY)
  await app.logIn(credentials);
//console.log(user)
} catch (error) {

  console.log("Error in Logging", error)

}

let userid = ""
try {
    const user = await getuser()
    userid = user.username
    
} catch (error) {
    console.log(error)
}
// console.log("User Id", userid)

let collection = ""
try {
    const mongo = app.currentUser.mongoClient("dbhaiapp");
    collection = mongo.db("dbhprojects").collection("userschat");
} catch (error) {
    console.log("Get userchat collection Error", error)
}


    // console.log("Query", query)
    // console.log("imgData", imgData )

    let images = [{}];
    images = imgData.documents_selected
    // console.log("Images", images)

    // const getResps = async (img) => {
    //     try {
    //         const config = {
    //             region: 'us-east-1',
    //             credentials: {
    //                 accessKeyId: process.env.REACT_APP_REALM_AWSID,
    //                 secretAccessKey: process.env.REACT_APP_REALM_AWSKEY
    //         }}
    //         const client = new LambdaClient(config);
    //         // console.log("Client", client)
    
    
    //         const input = { // InvocationRequest
    //           FunctionName: "arn:aws:lambda:us-east-1:457968394077:function:imageagent", // required
    //         //   InvocationType: "Event",
    //           LogType: "Tail",
    //         //   ClientContext: "STRING_VALUE",
    //           Payload: JSON.stringify({
    //                     "query" : query,
    //                     "image_url" : img,
    //                 }),
    //         //   Qualifier: "STRING_VALUE",
    //         };
    
    
    //         const command = new InvokeCommand(input);

    //         const response = await client.send(command);
    //         // console.log(response)
    //         const payloadString = String.fromCharCode.apply(null, response.Payload);
    //         console.log("Lambda Response", payloadString)
    //         const respob = JSON.parse(payloadString)
    //         const content = respob.body[0].message.content
    //         // console.log("Lambda Response", content)
    //         return content
    //     } catch (error) {
    //         console.log("Error Add Document", error)
    //     }
    //     }

    // if(images.length >= 2){
    //     console.log("More than 1 image")
    //     console.log("Images", images)

    //     // let responses = [{}]
      
    //     //     images.map(img => {
    //     //     // console.log(img.data)
    //     //     const Start = async () => {
    //     //     const resp = await getResps(img.data)
    //     //     console.log("Looped resp", resp)
    //     //     const output = {
    //     //         "name": img.key,
    //     //         "resp": resp
    //     //     }
    //     //     responses.push(output)
    //     //     }
    //     //     Start()
    //     // })
    //     // console.log("All Responses", responses)
    //     // return "More than 1 image"
    // }

    // console.log(images[0].key)

    // let =
    // images.map(img => { 
    //     console.log(img) 
    // })

    try {
        if(images.length >= 2){
        // console.log("More than 1 image")
        // console.log("Images", images)
        }
        const config = {
            region: 'us-east-1',
            credentials: {
                accessKeyId: process.env.REACT_APP_REALM_AWSID,
                secretAccessKey: process.env.REACT_APP_REALM_AWSKEY
        }}
        const client = new LambdaClient(config);
        // console.log("Client", client)


        const input = { // InvocationRequest
          FunctionName: "arn:aws:lambda:us-east-1:457968394077:function:imageagent", // required
        //   InvocationType: "Event",
          LogType: "Tail",
        //   ClientContext: "STRING_VALUE",
          Payload: JSON.stringify({
                    "query" : query,
                    "images" : images,
                }),
        //   Qualifier: "STRING_VALUE",
        };

        // console.log("Input", input)


        const command = new InvokeCommand(input);
        // console.log("COmmand", command)


        const response = await client.send(command);
        // console.log(response)
        const payloadString = String.fromCharCode.apply(null, response.Payload);
        // console.log("Lambda Response", payloadString)
        const respob = JSON.parse(payloadString)

        const content = respob.body[0].message.content
        // console.log("Lambda Response", content)
        // return content

        let result = ""
try {
    result = await collection.insertOne({
        "project": projectname,
        "userId": userid,
        "query": query,
        "response" : content,    
        "images": images,
        "agent" : "vision agent"
        });

    // console.log("Document added sucessfully", result);
    
} catch (error) {
    console.log("Error Add Document", error)
}

const output = {
    "result": result,
    "response": content
}
// console.log("output", output)

return output
        
        
    } catch (error) {
        console.log("Error in Lam", error)
        return error    
    }

  

    
}
