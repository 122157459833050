import { Grid } from '@mui/material'
import React from 'react'
import TopResults from './TopResults.tsx'
import { Collection, Flex, Text } from '@aws-amplify/ui-react';




interface SearchResult {
  organicResults: Array<any>;
  relatedQuestion: Array<any>;
}

interface PageProps { 
  googleFrame: SearchResult;
}

const GoogleSearchModal: React.FC<PageProps> = ({googleFrame}) => {
  const orgnaicResults = googleFrame.organicResults
  const relatedQuestions = googleFrame.relatedQuestion
  // console.log("Organic Results", orgnaicResults)
  // console.log("Realted Question", relatedQuestions)
  return (
    
    <Grid container
    display="flex"
    justifyContent="flex-start"
    sx={{
      // background: "pink",
      width: "auto",
      height:"100%"
    }}
    position="relative"
    // paddingTop="30px"
    // className="flex w-full h-full relative pt-10"
    >

{/* <iframe src={googleFrame} title="Google Search"
/> */}


        <Flex
        // backgroundColor="red"
        justifyContent="flex-start"
        display="flex"
        width="100%"
        height="auto"
        paddingLeft="10px"
        paddingRight="10px"
        paddingTop="10px"
        direction="column"
        >
          <Flex
          position="relative"
          width="100%"
          justifyContent="center"
          fontSize="25px"
          fontWeight="bold"
          color="white"
          textAlign="center">

            Related Links

          </Flex>
        <Collection
        items={orgnaicResults || []}
        type="list"
        isSearchable
        // templateColumns="1fr 1fr"
        // templateRows="12rem 12rem"
        direction="column"
       
        
        display="flex"
        justifyContent="flex-start"
       
        gap="8px"

        // className='Collection'
        alignItems="center"
        // wrap="wrap" 
        // searchPlaceholder={` `}
        searchNoResultsFound={
            <Flex justifyContent="center"
            paddingBottom="20px"
            >
              <Text fontSize="1rem"
              color="white"
              textAlign="center"
              >
                {`Enter Search Query ...`}
              </Text>
            </Flex>
          }
        // width="auto"
        // backgroundColor="pink"
        // height="100px"
        // columnGap="15px"
        // isSearchable
        // paddingLeft="20px"
        // textAlign="center"
        // paddingRight="20px"
        // // wrap="nowrap"
        // paddingTop="20px"
        // paddingBottom="20px"
        // // backgroundColor="pink"
        // overflow="scroll"
        
        isPaginated 
        itemsPerPage={3}  
    >

      {(item, index) => (  

      <TopResults
      key={index}
       data={item}
       />
      
        
        )}
        </Collection>
        </Flex>

    </Grid>

  )
}

export default GoogleSearchModal
