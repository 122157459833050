
import { Grid } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
// import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf.js';
import WebViewer from '@pdftron/webviewer';
// import PdfParse from 'pdf-parse';


// pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/4.0.189 /pdf.worker.js';

function PDFViewer(props) {
  const fileUrl = props.file  
  // console.log("File Url", fileUrl)
 
    const PDFPageExtractor = ({fileUrl}) => {

      
    console.log("Pdfviewver triggered", fileUrl)
      const viewerDiv = useRef(null);
    
      useEffect(() => {
        WebViewer(
          {
            path: 'lib', // path to the PDFTron 'lib' folder
            initialDoc: fileUrl ,
          },
          viewerDiv.current).then(instance => {

          })
      }, [fileUrl]);
      return <div ref={viewerDiv} style={{ height: '100vh' }} />;
    }
    
   
    // console.log("FileUrl?",fileUrl)
  return (
    
    <Grid container
    position="relative"
    width="100%"
    height="100%"

    >

      {/* <PDFPageExtractor fileUrl={fileUrl}/> */}
    <iframe
     title="PDF View"
     src={fileUrl}
     height="430"
    //  frameborder="0"
     
    >
    </iframe>

  

  </Grid>
   
    // <Document file={fileUrl}>
    //   <Page pageNumber={1} />
    // </Document>
  );
}

export default PDFViewer;