import { StorageManager } from "@aws-amplify/ui-react-storage";
import React from 'react'
// import s3putupload from "../aws_utils/s3putupload";
import getuser from "../aws_utils/getAuthuser";
import realmGetUser from "../realm-scripts/realmGetUser";

export default function CustomUpload(props) {

  const projectname = props.projectname
  const [subscribed, setsubscribed] = React.useState(true)
  React.useEffect(() => {
    const currentUser = async () => {
    const user = await getuser()
    const sub = user.attributes.sub

    try {
      const realmuser = await realmGetUser(sub)
    // console.log("Realm User", realmuser)
      const allagents = realmuser.all_agents
    // console.log(realmuser)
      setsubscribed(allagents)
    } catch (error) {
      console.log(error)
    }
    

    }

    currentUser()
  }, [])
  

  const ProcessFile = async ({ file, key }) => {
    // console.log("Processing File", file)

    // const user = await getuser()
    // const keyname = `${user.username}${key}`
    // setfiledata(file)

    return {
      file,
      key: `${projectname} ${key}`,
    };
  };

  
  // const PutTextract = async ({key}) => {
  //   const user = await getuser()
  //   const keyname = `${user.username}${key}`
  //   const puts3 = await s3putupload(keyname, filedata)
  //   console.log("Put Response", puts3)

  // }



  return (
    <StorageManager
      acceptedFileTypes={[
      // '.csv',
      // '.doc',
      // '.json',
      ".pdf",
      // or MIME types:
      `${subscribed ? 'image/png' : ""}`,
      // 'video/*',
    ]}
      onUploadSuccess={() => {
        alert("Successfully uploaded document, go to 'My Documents'!")
      }}
      accessLevel="private"
      processFile={ProcessFile}
      // onUploadSuccess={PutTextract}
      maxFileCount={5}
      // isResumable
    />
  )
}


