import { Divider, Flex, Icon, Menu, MenuButton, MenuItem, Text, View } from '@aws-amplify/ui-react';
import { Grid, useMediaQuery } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import getuser from '../aws_utils/getAuthuser';
import { motion } from 'framer-motion'

export default function UnAuthNavBar(props) {

    const [isuser, setisuser] = React.useState(false)
    React.useEffect(() => {
        const GetUser = async () => {
        const user = await getuser()
       //  console.log(user)
         
         if(user === "The user is not authenticated"){
            //  console.log("The user is not authenticated")
             setisuser(false)
             return
         
         // if(isuser === true){
         //   navigate("Home")
 
         } else {
           setisuser(true)
             
         }
       }  
         
        GetUser()
     }, [])
 
    const matches = useMediaQuery('(min-width:360px)');
    const navigate = useNavigate()
  
    const signUp = async () => {
    //   await Auth.signOut()
      navigate('/GetStarted')
    }
  
    
    return (
  
  
      <Grid container
        width="100%"
        height="75px"
        display="flex"
        gap="unset"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
        // padding="0px 0px 0px 0px"
        zIndex="9"
        paddingLeft="30px"
        paddingRight="6px"
        // backgroundColor="rgba(222,136,101,.5)"
        backgroundColor="rgba(225,255,255,.3)"
        wrap="nowrap"
        // backgroundColor="rgba(255,255,255,1)"
     
      >
      
{/*   
            <Flex
            padding="0px 0px 0px 0px"
            width="100%"
            height="auto"
            // display="block"
            // gap="10px"
            // backgroundColor="red"
            alignItems="center"
            justifyContent="flex-start"
            position="relative"
            onClick={()=> {
            //   navigate("/")
            }}
            className="hover:cursor-pointer"
            
            
            // backgroundColor="red"
            // top="32.22%"
            // bottom="32.22%"
            // left="3.33%"
            // right="77.78%"
        
          >
           
              <View
              // backgroundColor="red"
                width="30px"
                height="30px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="relative"
                
                
                // top="1px"
                left="0px"
                padding="0px 0px 0px 0px"
            
              >
                <Icon
                  width="30px"
                  height="30px"
                  viewBox={{ minX: 0, minY: 0, width: 30, height: 30 }}
                  paths={[
                    {
                      d: "M16.6225 20.4006L16.5818 19.1512L16.5592 19.152L16.5365 19.1535L16.6225 20.4006ZM17.7492 19.9283L18.6115 20.8334L18.6176 20.8275L17.7492 19.9283ZM28.75 15C28.75 22.5939 22.5939 28.75 15 28.75L15 31.25C23.9746 31.25 31.25 23.9746 31.25 15L28.75 15ZM15 28.75C7.40608 28.75 1.25 22.5939 1.25 15L-1.25 15C-1.25 23.9746 6.02537 31.25 15 31.25L15 28.75ZM1.25 15C1.25 7.40608 7.40608 1.25 15 1.25L15 -1.25C6.02537 -1.25 -1.25 6.02537 -1.25 15L1.25 15ZM15 1.25C22.5939 1.25 28.75 7.40608 28.75 15L31.25 15C31.25 6.02537 23.9746 -1.25 15 -1.25L15 1.25ZM18.3678 -0.227337L0.0837139 19.5521L1.91951 21.2491L20.2036 1.46967L18.3678 -0.227337ZM4.7471 3.85842L20.9257 28.017L23.0029 26.6259L6.82433 2.46734L4.7471 3.85842ZM1.80027 22.6756L16.7085 21.6476L16.5365 19.1535L1.6283 20.1815L1.80027 22.6756ZM18.6176 20.8275L29.7118 10.1134L27.9752 8.31513L16.8809 19.0292L18.6176 20.8275ZM16.6632 21.6499C17.3909 21.6262 18.0844 21.3356 18.6115 20.8333L16.887 19.0234C16.8044 19.102 16.6958 19.1475 16.5818 19.1512L16.6632 21.6499Z",
                      // stroke: "rgba(222,136,101,1)",
                      // fillRule: "nonzero",
                      // strokeWidth: 0,
                    },
                  ]}
                  // backgroundColor="rgba(222,136,101,1)"
                  display="block"
                  color="white"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0%"
                  bottom="0%"
                  left="0%"
                  right="0%"
                 
                ></Icon>
              </View>
  
            
  
            </Flex>
   */}
            
  
            <Grid container item
              gap="10px"
              direction="row"
              width="100%"
              height="100%"
              justifyContent="flex-end"
              alignItems="center"
              // shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
            //   backgroundColor="green"
              wrap="nowrap"
             
            >

             <Grid container
             position="relative"
             width="100%"
            //  height="40px"
             

             display={matches ? "flex" : "none" }
             justifyContent="flex-start"
            //  alignItems="center"
             gap="15px"
            //  backgroundColor="red" 
             wrap="nowrap"
             >

              <motion.button
        whileHover={{scale : 1.1}}
        whileTap={{scale: 0.9}}
        
        >
            <Text
                fontFamily="Nunito"
                fontSize="16px"
                fontWeight="700"
                color="white"
                lineHeight="21.823999404907227px"
                textAlign="left"
                // display={matches ? "block" : "none"}
                direction="column"
                justifyContent="unset"
                width="80px"
                
                height="unset"
                gap="unset"
                alignItems="unset"
                // shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                // whiteSpace="pre-wrap"
                // backgroundColor="pink"
                children={isuser ?  "Get Started" : "Sign In"}
                className="hover:cursor-pointer"
                onClick={() => {
                  navigate('/GetStarted')
                }}
              
              ></Text>

              </motion.button>


                <Grid container
                position="relative"
                width="100%"
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap="15px"
                top="-1px"
                // height="50px"
                wrap='nowrap'
                // backgroundColor="red"
                >

             <a href="#About">
             <motion.button
        whileHover={{scale : 1.1}}
        whileTap={{scale: 0.9}}
        
        >
              <Text
                fontFamily="Nunito"
                variation="link"
                fontSize="16px"
                fontWeight="700"
                color="white"
                lineHeight="21.823999404907227px"
                textAlign="left"
                // display={matches ? "block" : "none"}
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                // backgroundColor="red"
                children="About"
                
                className="hover:cursor-pointer"
              
              ></Text>
              </motion.button>

              </a>
            
             
                <a href="#Features">

                <motion.button
        whileHover={{scale : 1.1}}
        whileTap={{scale: 0.9}}
        
        >
                  
               <Text
                fontFamily="Nunito"
                fontSize="16px"
                fontWeight="700"
                color="white"
                lineHeight="21.823999404907227px"
                textAlign="left"
                // display={matches ? "block" : "none"}
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                // backgroundColor="red"
                children="Features"
                className="hover:cursor-pointer"
               
              
              ></Text>

              </motion.button>
              </a>


            
              <a href="#Contact">

              <motion.button
        whileHover={{scale : 1.1}}
        whileTap={{scale: 0.9}}
        
        >
              <Text
                fontFamily="Nunito"
                fontSize="16px"
                fontWeight="700"
                color="white"
                lineHeight="21.823999404907227px"
                textAlign="left"
                // display={matches ? "block" : "none"}
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                // backgroundColor="red"
                children="Contact"
                className="hover:cursor-pointer"
              
              ></Text>

              </motion.button>
              </a>

              </Grid>

             
              </Grid>

    
  
               <Menu
                  triggerClassName="my-menu-trigger"
                  className="my-menu-content"
                  menuAlign="end"
                  // width="auto"
                  height="auto"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  marginTop="10px"
                //   marginLeft="20px"
                  trigger={
  
                  <MenuButton
                  className="my-menu-trigger"
                  
                  borderRadius='5px'
                  
                   >
                      <Icon
                    width="13.5px"
                    height="6.66px"
                    viewBox={{
                      minX: 0,
                      minY: 0,
                      width: 13.5,
                      height: 6.662109375,
                    }}
                    paths={[
                      {
                        d: "M0.21913 0.220211C0.511724 -0.0729808 0.986598 -0.0734649 1.27979 0.21913L5.01935 3.95108C5.4238 4.3547 5.69564 4.62517 5.92172 4.81672C6.14109 5.00259 6.26763 5.06987 6.36421 5.10121C6.61497 5.18257 6.88503 5.18257 7.13579 5.10121C7.23237 5.06987 7.35891 5.00259 7.57828 4.81672C7.80436 4.62517 8.0762 4.3547 8.48064 3.95108L12.2202 0.21913C12.5134 -0.0734649 12.9883 -0.0729808 13.2809 0.220211C13.5735 0.513402 13.573 0.988276 13.2798 1.28087L9.54022 5.01282L9.51868 5.03432C9.14132 5.41093 8.82631 5.72531 8.54795 5.96116C8.25761 6.20717 7.95825 6.41133 7.59873 6.52798C7.04707 6.70698 6.45293 6.70698 5.90127 6.52798C5.54175 6.41133 5.24239 6.20717 4.95204 5.96116C4.67368 5.7253 4.35866 5.41091 3.98129 5.03429L3.95978 5.01282L0.220211 1.28087C-0.0729808 0.988276 -0.0734649 0.513402 0.21913 0.220211Z",
                        fill: "white",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                  >
                    
                      </Icon>   
                  </MenuButton>}
                  
                  /// Arrow next  to user.name
                >
                  
                  
                  <Flex
                  height='50px'
                  // backgroundColor="red"
                  >
                  
                  <Text
                  marginTop="15px"
                  marginLeft="18px"
                  color="black"
                  
                  > 
                  {isuser ?  "Welcome!" : "Welcome Guest!"}
                  </Text>
                  </Flex>
  
                  <Divider />
    
                 
                
  
  
    <MenuItem
    width='100%'
    height='50px'
     onClick={()=>{
      signUp()
    
     }}>
      {isuser ?  "Get Started" : "Sign Up"}
    </MenuItem>
                 
            
                </Menu>
              
            </Grid>
  
          
  
      </Grid>
      
    );
}
