import { InvokeCommand, LambdaClient } from '@aws-sdk/client-lambda';
import getuser from '../aws_utils/getAuthuser';
import StorageGet from '../aws_utils/s3storageget';




export default async function invokegimageagent(query, projectname, cogid) {

    let query_key = ""
    query_key = query

    const qk = query_key.split(' ').slice(0, 6).join(' ')

    let resp = ""
    try {
        const config = {
            region: 'us-east-1',
            credentials: {
                accessKeyId: process.env.REACT_APP_REALM_AWSID,
                secretAccessKey: process.env.REACT_APP_REALM_AWSKEY
        }}
        const client = new LambdaClient(config);
        // console.log("Client", client)


        const input = { // InvocationRequest
          FunctionName: "arn:aws:lambda:us-east-1:457968394077:function:gimageagent", // required
        //   InvocationType: "Event",
          LogType: "Tail",
        //   ClientContext: "STRING_VALUE",
          Payload: JSON.stringify({
                    "query" : query,
                    "cogid" : cogid,
                    "key" : qk,
                    "projectname" : projectname
                    // "memory" : memory,
                }),
        //   Qualifier: "STRING_VALUE",
        };

        // console.log("Input", input)


        const command = new InvokeCommand(input);
        // console.log("COmmand", command)


        const response = await client.send(command);
        // console.log(response)
        const payloadString = String.fromCharCode.apply(null, response.Payload);
        resp = payloadString
        // console.log("Lambda Response", payloadString)
        // return payloadString
        
    } catch (error) {
 
        console.log(error)
        return `error`
        
    }

const Realm = require("realm-web"); 
const REALM_ID = process.env.REACT_APP_REALM_APP_ID;
const REALM_KEY = process.env.REACT_APP_REALM_APP_KEY;
const app = new Realm.App({id: REALM_ID});
const {
  BSON: { ObjectId },
} = Realm;

// Authenticate the user
try {
  const credentials = Realm.Credentials.apiKey(REALM_KEY)
  await app.logIn(credentials);
//console.log(user)
} catch (error) {

  console.log("Error in Logging", error)

}

let userid = ""
try {
    const user = await getuser()
    userid = user.username
    
} catch (error) {
    console.log(error)
}
// console.log("User Id", userid)

let collection = ""
try {
    const mongo = app.currentUser.mongoClient("dbhaiapp");
    collection = mongo.db("dbhprojects").collection("userschat");
} catch (error) {
    console.log("Get userchat collection Error", error)
}

    // console.log("resp intial", resp)
    const data = JSON.parse(resp)
    // console.log("Data", data)
    // console.log("Data Body", data.body)
    const image_body = data.body
    

    // const body = data.body.image.valueOf()
    // console.log("body?", JSON.stringify(body))
    // const blob = new Blob([body], { type: 'application/octet-stream' });
    // console.log("blob?",blob)

    

    // console.log("data from body", image_body.data)
    // console.log("key from body", image_body.key)

    const image_key = image_body.key
    const new_prompt = image_body.data[0].revised_prompt

  
        // const url = await StorageGet(image_key)
        // // console.log("url", url)
  

    let result = ""
    try {
        result = await collection.insertOne({
            "project": projectname,
            "userId": userid,
            "query": query,
            "response" : new_prompt,  
            "image_url" : image_key,  
            "image": true,
            "agent" : "image agent"
            });
    
        // console.log("Document added sucessfully", result);
        
    } catch (error) {
        console.log("Error Add Document", error)
    }
    
    const output = {
        "result": result,
        // "response": resp
    }
    // console.log("output", output)
    
    return output

}
