import getuser from '../aws_utils/getAuthuser';
import { MemoryVectorStore } from "langchain/vectorstores/memory";
import { OpenAIEmbeddings } from "langchain/embeddings/openai";
// import { ChatOpenAI } from 'langchain/chat_models/openai';
import { RecursiveCharacterTextSplitter } from 'langchain/text_splitter';
// import { ConversationChain, ConversationalRetrievalQAChain, loadQAMapReduceChain} from 'langchain/chains';
import getUserChat from '../realm-scripts/getUserChat';
import { BufferWindowMemory } from 'langchain/memory';
// import { LLMChain } from "langchain/chains";
// import { PromptTemplate } from 'langchain/prompts';
// import { OpenAI } from 'langchain/llms/openai';
import invokedocumentagent from '../lambda/invokedocumentagent';

export default async function jsonsummaryv3(docs, query, projectname) {
 

if(projectname === "" || projectname === undefined){
    alert("Select or Start New Project!")
    return
}

// Authenticating to Mongo for Pushing User Chat
const Realm = require("realm-web"); 
const REALM_ID = process.env.REACT_APP_REALM_APP_ID;
const REALM_KEY = process.env.REACT_APP_REALM_APP_KEY;
const app = new Realm.App({id: REALM_ID});
const {
  BSON: { ObjectId },
} = Realm;

// Authenticate the user
try {
  const credentials = Realm.Credentials.apiKey(REALM_KEY)
  await app.logIn(credentials);
//console.log(user)
} catch (error) {

  console.log("Error in Logging", error)

}

let userid = ""
try {
    const user = await getuser()
    userid = user.username
} catch (error) {
    console.log(error)
}

let collection = ""
try {
    const mongo = app.currentUser.mongoClient("dbhaiapp");
    collection = mongo.db("dbhprojects").collection("userschat");
} catch (error) {
    console.log("Get userchat collection Error", error)
}

// Attempts to add memery
const userchat = await getUserChat(projectname)
// console.log("Old User Chat", userchat)
// const history = new ChatMessageHistory();

// The way but may be costly because displays all conversations
// const memory = new BufferMemory({
//     memoryKey: "history",
    
//     // input_documents: newdocs,
//     // question: query,
//     // inputKey: "question", // The key for the input to the chain
//     // outputKey: "text", // The key for the final conversational output of the chain
//     // returnMessages: true
// });

// The next best but may be costly because displays all conversations
// const memory = new ConversationSummaryMemory({
//     // memoryKey: "chat_history",
//     llm: model3,
// 
//     // inputKey: "question",
//   }
//   );

// Buffer Window Memory
const memory = new BufferWindowMemory({ k: 3 });

// Vector Memory
// const memoryvectorStore = new MemoryVectorStore(new OpenAIEmbeddings({
//     openAIApiKey: process.env.REACT_APP_REALM_OPENAI,
// }));

// const memory = new VectorStoreRetrieverMemory({
//     // 1 is how many documents to return, you might want to return more, eg. 4
//     vectorStoreRetriever: memoryvectorStore.asRetriever(5),
//     memoryKey: "history",
//     inputKey: "question",
//     // outputKey: "output",
//     // returnDocs: true
    
//   });

// const memory = new ConversationSummaryBufferMemory({
//     llm: new ChatOpenAI({ 
//         temperature: 0,
//         modelName: "gpt-3.5-turbo",
//         openAIApiKey: process.env.REACT_APP_REALM_OPENAI,
//     }),
//     maxTokenLimit: 200,
//     returnMessages: true,
//   });

// const memory = new ChatMessageHistory(); 


if(userchat.length === 0){
    // console.log("No User Chats!")
    } else {
    
        
    // console.log("User Chats Exist!")
    userchat.forEach(async item => {
        // console.log("Query", item.query)
        // console.log("Response", item.response)
        
        if(item.query === null){
            // console.log("1")
        await memory.saveContext(
            {input: "..."},
            {output: item.response}
        )
        return
        }

        if(item.response === null){
            // console.log("2")
        await memory.saveContext(
            {input: item.query},
            {output: "..."},
            )
            return
        }

        // console.log("3")
        await memory.saveContext(
            {input: item.query},
            {output: item.response},
        )

        });
    }

    // const memvector = await memory.loadMemoryVariables()
    // console.log("Conversation Memory?", memory)
    const history = await memory.loadMemoryVariables({});
    // console.log("history", history.history)

    const messages = await memory.chatHistory.getMessages();

    // console.log("history", history.history)
    // console.log("Messages", messages)
   

const serializeChatHistory = async (chatHistory) => {
    let aiCount = 0;
    let humanCount = 0;
    const serializedMessages = chatHistory.map((chatMessage) => {
        if (chatMessage._getType() === "human" && humanCount < 3) {
            humanCount++;
            return `Human: ${chatMessage.content}`;
        } else if (chatMessage._getType() === "ai" && aiCount < 3) {
            aiCount++;
            return `Assistant: ${chatMessage.content}`;
        } else {
            return null;
        }
    }).filter(message => message !== null).join("\n");
    return serializedMessages;
}


const newmem = await serializeChatHistory(messages)
const sumupmemory = {
    
    "RECENT CHAT HISTORY": history,
    "OLDEST CHAT HISTORY": newmem,

}
const memorysummary = JSON.stringify(sumupmemory)

// const newdocs = await serializeDocs(similardocuments)
// console.log("Serializedd docs?", newdocs)

// const previous_summary = "";
// const predictSummary = await memory.predictNewSummary(
//     messages,
//     previous_summary
//     );
// const newsummary = JSON.stringify(predictSummary);

// console.log("New Summary", newsummary)
// console.log("Newmem", newmem);

// const sumupmemory = {
//     "memory summary": newsummary,
//     // "messages": newmem
// }

// const memorysummary = JSON.stringify(sumupmemory)
// const memorysummary = ""





// Split big document into smaller chucnks
// console.log("Summary Start On These Docs:", docs)
const text = JSON.stringify(docs)
// console.log("Stringy docs", text)
const textSplitter = new RecursiveCharacterTextSplitter({ 
    chunkSize: 1000,
    chunkOverlap: 1,
    
});
const documents = await textSplitter.createDocuments([text]);
// console.log("Documents", documents)

// Get Vector of Documents
const vectorStore = await MemoryVectorStore.fromDocuments(
    documents,
    new OpenAIEmbeddings({
        openAIApiKey: process.env.REACT_APP_REALM_OPENAI,
    })
  );

// console.log("Vector Store of Documents?", vectorStore)
const similardocuments = await vectorStore.similaritySearch(query, 10);
// console.log("Vector Similarity Search on Documents", similardocuments)

const retriever = vectorStore.asRetriever()
const releventdocuments = await retriever.getRelevantDocuments(query)
// console.log("Vector Relevent Search on Documents", releventdocuments)

const serializeDocs = async (docs) => {
  const d = docs.map((doc) => doc.pageContent).join("\n\n")
//   console.log(d)
    return d
  }
const similardocs = await serializeDocs(similardocuments)
// console.log("similar Docs", similardocs)
// const relevantdocs = await serializeDocs(releventdocuments)

const response = await invokedocumentagent(query, memorysummary, similardocs)
const parseresponse = JSON.parse(response)

let result = ""
try {
    result = await collection.insertOne({
        "project": projectname,
        "userId": userid,
        "query": query,
        "response" : parseresponse.body,  
        "agent" : "document agent"  
        });

    // console.log("Document added sucessfully", result);
    
} catch (error) {
    // console.log("Error Add Document", error)
}

const output = {
    "result": result,
    "response": response.body,
}

return output





}