import getuser from "../aws_utils/getAuthuser";

export default async function getUserProjects() {
    const Realm = require("realm-web"); 
    const REALM_ID = process.env.REACT_APP_REALM_APP_ID;
    const REALM_KEY = process.env.REACT_APP_REALM_APP_KEY;
    const app = new Realm.App({id: REALM_ID});
    const {
        BSON: { ObjectId },
    } = Realm;

  // Authenticate the user
    try {
        const credentials = Realm.Credentials.apiKey(REALM_KEY);
        await app.logIn(credentials);
        
    } catch (error) {
        console.log("Error in Logging", error);
    }
   
    let userid = ""
    try {
        const currentuser = await getuser();
        userid = currentuser.username;
    } catch (error) {
        console.log(error);
    }

  
    const mongo = app.currentUser.mongoClient("dbhaiapp");
    const collection = mongo.db("dbhprojects").collection("usersproject");

    try {
        const projects = await collection.find({ "userid": userid });
        return projects
    } catch (error) {
        console.log(error)
        return error
        
    }



}
