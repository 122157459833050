import { Button, Collection,  Flex,  Image,  SwitchField, Text, View } from "@aws-amplify/ui-react";
import { Tilt } from "react-tilt";
import React from 'react'
import { Grid, useMediaQuery } from "@mui/material";
import GetAllS3 from "../aws_utils/s3storagegetall";
import StorageGet from "../aws_utils/s3storageget";
import PDFViewer from "../apputils/PdfRender";
import Modal from 'react-modal'

import '../App.css'

import s3storageremove from "../aws_utils/s3storageremove";

import invokelangchainPDFloader from "../lambda/invokelangchainPDFloader";


Modal.setAppElement('#root')

export default function MyDocumentsDisplay(props) {

    const matches = useMediaQuery('(max-width:750px)'); 
    const [items, setitems] = React.useState([])
    const [documentdata, setdocumentdata] = React.useState()
    const [showdata, setshowdata] = React.useState(false)
    
    const [isChecked, setisChecked] = React.useState(false)
    const documentselected = props.documentselected
    const documenttoggle = props.documenttoggle
    const projectname = props.projectname
    

    const closeModal = async () => {
        setshowdata(false)
    }

    const [documentlistupdated, setdocumentlistupdated] = React.useState(false)
    React.useEffect(() => {
        const GetAllFiles = async () => {
        let data = []
        data = await GetAllS3()
        const projectfilter = data.filter(document => document.key.startsWith(projectname));
        setitems(projectfilter)
        setdocumentlistupdated(false)
        setisChecked(false)
        setdocumentsArray([])
        documenttoggle(false)
        }
        GetAllFiles()
        },[projectname, documentlistupdated])

    const [isPdf, setisPdf] = React.useState(false) 
    const [isImg, setisImg] = React.useState(false)

    const GetFile = async (key) => {
            let k = ""
            k = key

            if(k.endsWith(".pdf")){
                setisPdf(true)
                setisImg(false)
                try {
                  const data = await StorageGet(key)
                  setdocumentdata(data)
                  setshowdata(true)
                  return
              } catch (error) {
                  console.log(error)
                  return
              }}
              
              if(k.endsWith(".jpeg") || k.endsWith(".png")){
                // console.log("Img doc")
                setisImg(true)
                setisPdf(false)
                try {
                  const data = await StorageGet(key)
                  setdocumentdata(data)
                  setshowdata(true)
                  return
              } catch (error) {
                  console.log(error)
                  return
              }} 

            setisPdf(false)
            setisImg(false)
    }

    // const GetFileBlob = async (key) => {
    //     // console.log(key)
    //     try {
    //         const data = await s3getblob(key)
    //         console.log("Blob Data", data)
    //     } catch (error) {
    //         console.log(error)
    //     }
        
    // }

    // FOr blob lambda langchain when fixed
    // const GetAndSelect = async (key) => {
    //     try {
    //         const data = await s3getblob(key)
    //         documentselected({
    //             "key" : key,
    //             "blob" : data,
    //             "type" : "blob"
    //         })
    //     } catch (error) {
    //         console.log(error)
    //     }  
    // }

    // Remove s3 Document
    const StorageRemove = async (key) => {
      await s3storageremove(key)
      // console.log("Remove Resp", resp)
      setdocumentlistupdated(true)
    }

       
   
    // Handle mutiple selected documents
    const [documentsArray, setdocumentsArray] = React.useState([])
    const [shouldPush, setshouldPush] = React.useState(false)

    const pushDocument = async (key) => {
      let k = ""
      k = key


      if(k.endsWith(".pdf")){
        const resp = await invokelangchainPDFloader(key)
          let data = [{}]
          data = JSON.parse(resp)
          const content = data.body
          let rawtext = "";
          content.forEach(obj => {
            const pgcontent = obj.pageContent
            rawtext += pgcontent
          })



        const newdocument = {
            "key" : key,
            "data" : rawtext,
            "type" : "pdf"
        }
        // console.log("New Document Selected (Pdf)", newdocument)
        documentsArray.push(newdocument)
        setshouldPush(true)
        return
      }
        
      if(k.endsWith(".jpeg") || k.endsWith(".png")){
        // console.log("Img doc")
        setisImg(true)
        setisPdf(false)
        try {
          const data = await StorageGet(key)
          // const data = await s3getblob(key)
          // console.log("S3 Document data (Image)", data)
         

        const newdocument = {
            "key" : key,
            "data" : data,
            "type" : "img"
        }
        // console.log("New Document Selected (Image)", newdocument)
        documentsArray.push(newdocument)
        setshouldPush(true)
          return
      } catch (error) {
          console.log(error)
          return
      }} 
        
    }

    const popDocument = async (key) => {
      // console.log("Original Doc before pop", documentsArray)
        const newdocumentsArray = documentsArray.filter(document => document.key !== key)
        // console.log("New Popped Array", newdocumentsArray)
        setdocumentsArray(newdocumentsArray)
        setshouldPush(true)
    }

    const processDocuments = async () => {
            // console.log("Process Selected Documents", documentsArray)

            const imgexist = documentsArray.some(doc => doc.type === "img")
            // console.log("Includes Img", imgexist)

            const pdfexist = documentsArray.some(doc => doc.type === "pdf")
            // console.log("Includes pdf", pdfexist)

            if(documentsArray.length === 0){
              documentselected(documentsArray)
              return
            }

            if(imgexist === true && pdfexist === true){
              // console.log("Mixed")
              documentselected({
                "key" : "Documents Selected",
                "data" : documentsArray,
                "type" : "mixed"
            })
            return
          }

            if(imgexist === true){
              documentselected({
                "key" : "Documents Selected",
                "data" : documentsArray,
                "type" : "img"
            })
            return
          }

            if(pdfexist === true){
              documentselected({
                "key" : "Documents Selected",
                "data" : documentsArray,
                "type" : "pdf"
            })
            return
          }
          // console.log("No Documents to Handle")

        }
 
        React.useEffect(() => {
            if(shouldPush === true){
            processDocuments()
            // console.log("Documents Array", documentsArray)
            setshouldPush(false)
            }
        }, [shouldPush])
        
        // const [extractedData, setextractedData] = React.useState({})
        // const [showextracteddata, setshowextracteddata] = React.useState(false)
        // const ExtractedDocument = async (key) => {
        //   const data = await sdks3get(key)

        //   console.log("SDK output RawText", data)
        //   const extracteddocumentdata = await extractheaders(key, data.RawExtract) 
        
        //   setextractedData([extracteddocumentdata])
        //   // console.log("Document", extractedData)

        // }
    
        // React.useEffect(() => {
        //   const ShowData = async () => {
        //     if(extractedData.length === 0 ){
        //       // console.log("No Data")
        //       setshowextracteddata(false)
        //       return
        //     }
        //     if(extractedData.length >= 1 ){
        //       // console.log(extractedData)
        //       // console.log("Extracted Data Set")
        //       setshowextracteddata(true)
        //       return
        //     }
        //     setextractedData([])
        //   }
        //   ShowData()
        // }, [extractedData])
        
      const convertkey = (key) => {
        let start = ""
        start = key
        const end = start.replace(projectname, "")
        // console.log("New key", end)
        return end
        
      }


  return (

    <View
    position="relative"
    // width="50%"
    // display="block"
    // backgroundColor="pink"
    >

    <Grid container 
    position="relative"
    display="block"
    direction="column"

    // width="400px"
   
    // wrap="nowrap"
    // justifyContent="center"
    paddingTop="25px"
    paddingBottom="10px"
    // gap="20px"
    // backgroundColor="brown"
    // height="1000px"
    // overflow="hidden"
    >

    <Collection
    items={items || []}
    // type="list"
    overflow="hidden"
    direction="row"
    color="white"
    display="flex"
    templateColumns={`1fr 1fr ${""}`}
    templateRows="130px 120px"
    
    width="auto"
    position="relative"
    // justifyContent="flex-start"
    searchPlaceholder="Search Documents..."
    searchNoResultsFound={
      <Flex justifyContent="center"
      // paddingBottom="20px"
      // backgroundColor="red"
      >
        <Text fontSize="2rem"
        color="white"
        textAlign="center"
        >
          {`No Documents Uploaded`}
        </Text>
      </Flex>
      }
    // display="block"
    // justifyContent="center"
    // alignItems="center"
    // padding=" 50px, 5px, 5px, 5px"
    // backgroundColor="red"  
    // width="auto"
    columnGap="15px"
    // height="120px"
    // justifyContent="center"
    isSearchable
    isPaginated
    itemsPerPage={matches ? 2 : 5}
    paddingLeft="20px"
    paddingRight="20px"
    paddingTop="20px"
    paddingBottom="20px"
    // overflow="scroll"

    style={{
        // zIndex: 999
        overflowX: "hidden",
        overflowY: "hidden"


    }}
    // zIndex="9999"
    // borderRadius="16px"
    // wrap="wrap"
    

    
    >
      {(item, index) => (   
       

        <Tilt
        key={index}
        >
            <Flex
            display="flex"
            variation="primary"
            height="auto"
            justifyContent="center"
            alignItems="center"
            // width="200px"
            
            position="relative"
            boxShadow="0px 5px 15px rgba(0, 0, 0, .4"
            borderRadius="16px"
            backgroundColor="rgba(255,255,255,.8)"
            direction="column"

            paddingBottom="10px"
            paddingTop="0px"
            className="CollectionCard"
            // onClick={()=>{
            //     GetFile(item.key)
            // }}
            >

            <Flex
            position="relative"
            // backgroundColor="green"  
            width="100%"
            justifyContent="flex-end"
            paddingRight="10px"
            >
              <Button
              borderRadius='5px'
              textAlign="center"
              display="flex"
              variation="primary"
              className="Project"
              color="white"
              // backgroundColor="rgba(222,136,101,1)"
              backgroundColor="grey"
              // justifyContent="center"
              // alignItems="center"
              height="10px"
              width="5px"
              marginTop="-8px"
              paddingTop="5px"
              onClick={() => {
                StorageRemove(item.key)
              }}
              >
                x
              </Button>

              {/* <Menu
                triggerClassName="my-documentmenu-trigger"
                className="my-documentmenu-content"
                menuAlign="end"
                // width="100px"
                // height="auto"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                // marginTop="10px"
                trigger={

                <MenuButton
                className="my-documentmenu-trigger"   
                borderRadius='5px'
                textAlign="center"
                display="flex"
                // justifyContent="center"
                // alignItems="center"
                height="10px"
                width="5px"
                marginTop="-8px"
                paddingTop="5px"
                // width="30px"
                // backgroundColor="red"
                 >
                  x
                </MenuButton>}
                
                /// Arrow next  to user.name
              >
                
                
                <Flex
                height='50px'
                display="block"
                // backgroundColor="red"
                >
                
                <Text
                marginTop="15px"
                marginLeft="18px"
                color="black"
                width="180px"
                isTruncated

                
                > 
                {item.key}
                </Text>
                </Flex>

                <Divider />
  
               
          <MenuItem
            width='100%'
            height='50px'
            onClick={()=>{
    // signOut()
  
            }}>
              Remove Document
            </MenuItem>
               
          
              </Menu> */}

            </Flex>

            <Flex
            // gap="10px"
            // direction="column"
            // variation="primary"
            // display="flex"
              width="auto"
            // backgroundColor="yellow"
            // maxWidth="100%"
            // maxWidth="100px"
      
    //   height="100px"
      justifyContent="center"
      alignItems="center"
      top="0px"
      paddingLeft="10px"
      paddingRight="10px"
      position="relative"
      borderRadius="10px"
      paddingTop="5px"
    //   boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
    //   borderRadius="16px"
      
      // backgroundColor="rgba(255,255,255,1)"
    //   padding="16px 16px 16px 16px"
      // backgroundColor="pink"
      className="CollectionCard"
      onClick={()=>{
        GetFile(item.key)
        // ExtractedDocument(item.key)
        
        // GetFileBlob(item.key)
        // RetrieveAndSetData("cases")
        // navigate('CaseCollection')
      }}
    >
     
   {/* <NavLink to="CaseCollection"> */}
      <Text
      textDecoration="none"
      variation="primary"
      fontSize="12px"
      display="flex"
      width="100%"
      justifyContent="center"
      alignItems="center"
      fontWeight="bold"
      textAlign="center"
      
      // color="rgba(222,136,101,.9)"
      color="black"
      // backgroundColor="red"
      // onClick={()=>{
      //   return (
      //     <NavLink to="CaseCollection" 
      //     />
      //   )
      // }}
      >
      {convertkey(item.key)} 
      </Text>
      {/* </NavLink> */}
     
            </Flex>

            <SwitchField
            // trackCheckedColor="rgba(222,136,101,1)"
            trackCheckedColor="black"
            className="Switch"
                // zIndex="0"
                // paddingTop="0px"
                // isDisabled={isChecked}
                isLabelHidden={true}
                labelPosition="start"
                // value={isChecked}
                // isChecked={isChecked}
                // defaultChecked="false"
                // defaultValue="false"
                onChange={(e) => {
                    
                 
                  
                    // documenttoggle(e.target.checked)
                    // console.log("is Checked?", isChecked)
                    if(e.target.checked === true){

                      documenttoggle(e.target.checked)
                      // setisChecked(e.target.checked)
                      
                    // GetAndSelect(item.key)
                        // getRawText(item.key)
                        const result = pushDocument(item.key)
                        // e.target.value = result
                    } 
                    if(e.target.checked === false){
                      documenttoggle(e.target.checked)
                        popDocument(item.key)
                        }
                        
                    }}/>
            </Flex>

        </Tilt>
    
      )}
    </Collection>

    <Modal
    isOpen={showdata}
    onRequestClose={closeModal}
    style={{
        overlay: {
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          zIndex: 999,
        },
        content: {
          position: 'relative',
        //   display
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          border: '1px solid #ccc',
          height: "auto",
        //   background: 'blue',
          width: "302px",
        //   overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '0px'
        }
      }}
    
    >
        <View
        display="block"
        position="relative"
        // backgroundColor="pink"
        // width="300px"
        // height="30px"
        // justifyContent="center"
    // backgroundColor="yellow"
        // paddingTop="30px"
    >

        {isPdf ? <PDFViewer file={documentdata}/> : <div></div>
        }

        {isImg ? <Image
         src={documentdata}
         position="relative"
         objectFit="cover"
        //  backgroundColor="red"
         width="100%"
         height="100%"
         /> : 
         <div></div>
        }


        </View> 

    </Modal>

   
    
  

    </Grid>

    {/* {showextracteddata ? <BasicCollectionDisplay collection={extractedData}/> : "" } */}

    </View>

  )
}


    