import React from 'react'
import getUserChat from '../realm-scripts/getUserChat'
import { Button, Collection, Flex, Image, Text, View } from '@aws-amplify/ui-react'
import { Grid, TextField } from '@mui/material'
import ReactMarkdown from 'react-markdown';
import { PropagateLoader } from 'react-spinners'
import invokegimageagent from '../lambda/invokegimageagent'
import getAuthusercreds from '../aws_utils/getAuthusercreds';
import Modal from 'react-modal'
import StorageGet from '../aws_utils/s3storageget';
import {motion} from "framer-motion"
import { fadeIn, staggerContainer } from '../aws_utils/motion';


export default function ModalImageAgent(props) {

    const [query, setQuery] = React.useState("")
    const [isloading, setisloading] = React.useState(false)
    const currentproject = props.projectname
    const closeQueryBox = props.closeQueryBox
    const [projectconversation, setprojectconversation] = React.useState([])


    const UserChat = async () => {
        const userschat = await getUserChat(currentproject)
    //   console.log("User's Project Chat", userschat)
        const imgconversations = userschat.filter(img => img.image === true)
    // console.log("imgconvo", imgconversations)
        setprojectconversation(imgconversations)
      }

    const [cogid, setcogid] = React.useState('')
    React.useEffect(() => {
      const GetCogId = async () => {
      try {
          const id = await getAuthusercreds()
          const identityId = id.identityId
        //   console.log("id", id.identityId)
          setcogid(identityId)
      } catch (error) {
          console.log(error)
      }
      }
      GetCogId()
    }, [])
    
    
    React.useEffect(() => {
      // console.log("Current Project", currentproject)
      if(currentproject === "" || currentproject === null || currentproject === undefined){
        return
      }
      UserChat()
    }, [currentproject])

    const [newquery, setnewquery] = React.useState(false)
    React.useEffect(() => {
      // console.log("New query triggered", newquery)
      const refreshquerybox = async () => {
        UserChat()
        setnewquery(false)
        // setisloading(false)
      }
      refreshquerybox()
    }, [newquery])

   
    

    const QueryImageAgent = async () => {
      setisloading(true)
      if(currentproject === "" || currentproject === null || currentproject === undefined){
        setisloading(false);
        window.scrollTo(0,0);
        alert("Select or Create Project!")
        // console.log("Select Project");
        return
        }
        if(query === ""){
            
            setisloading(false)
            alert("Enter query!")
            // console.log("Query")
            return
        }

        await invokegimageagent(query, currentproject, cogid)

        setnewquery(true)
        setisloading(false)
        setQuery(" ")
        window.scrollTo(0,document.body.scrollHeight);
        
    }

    const [showdata, setshowdata] = React.useState(false)
    const [currentkey, setcurrentkey] = React.useState("")
    const closeModal = async () => {
        setshowdata(false)
    }

    
    const DisplayImage = (props) => {

        const [url, seturl] = React.useState("")
        React.useEffect(() => {
        // console.log("Image key", props.image_key)
        
        const getUrl = async () => {
        const get_url = await StorageGet(props.image_key)
        seturl(get_url)
        // console.log("Url", get_url)
        }
        getUrl()
          
        }, [props.image_key])


        return (

        <Image
             src={url} 
             position="relative"
         objectFit="cover"
        //  backgroundColor="red"
         width="100%"
         height="100%"
         onClick={() => {
            setcurrentkey(url)
            setshowdata(true)
         }}
             />)
    }

    const [isAnimated, setisAnimated] = React.useState(false)


  return (

    <View
      position="relative"
    //   paddingTop="5px"
      paddingBottom="0px"
      height="auto"
    //   backgroundColor="red"
      >

    <motion.section
          variants={staggerContainer()}
          initial="hidden"
          whileInView="show"
          viewport={{once: true, amount: 0.9}}
          >
    
            
            <motion.div
              variants={fadeIn("right",)}
            //   style={{
            //     left : "0px"
            //   }}
            //   className="rounded-[20px] green-pink-gradient p-[1px]  shadow-card"
              >
                
    <Flex
    position="fixed"
    width="150px"
    height="40px"
    gap="2px"
    borderRadius="15px"
    backgroundColor="rgba(255,255,255,.8)"
    boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
    justifyContent="flex-end"
    alignItems="center"
    paddingRight="5px"
    top="105px"
    left="-10px"
    style={{
        zIndex: 999
    }}
    // zIndex="999px"
    >
        <Text
        fontWeight="bold"
        // backgroundColor="green"
        >
            Image Agent   
        </Text>

        <motion.button
       
        whileHover={{
          scale : 1.8,
          
        //   fontColor: "rgba(255,255,255,1)"
        //   paddingLeft : "25px"
        }}
        whileTap={{
          scale: 0.9,
 
        }}
        initial={{
            // color: "rgba(255,255,255,1)"
        }}
        animate={{
            x : isAnimated ? 600 : 0,
            rotate: isAnimated ? 360 : 0,
            fontSize: isAnimated ? "900px" : "18px",
            transition: {
                duration : 3,
            }
        }}
        >

        <Text
        position="relative"
        width="20px"
        textAlign="center"
        fontWeight="bold"
        color="black"
        // fontSize="800px"
        paddingBottom="1px"
        onClick={() => {
            setisAnimated(!isAnimated)

            setTimeout(closeQueryBox ,1500)
            
          
        }}
        // backgroundColor="pink"
        >
            x
        </Text>

        </motion.button>

    </Flex>
    </motion.div>
    </motion.section>

    {/* Query texfield plus button */}
      <Flex
        // backgroundColor="green"
        width="100%"
        height="auto"
        justifyContent="center"
        alignItems="center"
        direction="column"
        paddingTop="20px"
        paddingBottom="20px"
        gap="10px"
        >
     
      <TextField
        position="relative"
        // className="border-red"
        // color="success"
        // boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
        // backgroundColor="blu  e"
        
        // height="500px"
       multiline
       fullWidth
       
       id="outlined-basic" 
       label="Enter Query ..." 
       variant="outlined" 
      //  color='white'
      //  color='white'
      // color="secondary"
      
      
        className=' text-[rgba(255,255,255,1)]'
        // top='-20px'
        value={query}
        onChange={(e) => {
            let { value } = e.target;
            setQuery(value);
          }}
        height="85px"
      />

<motion.div
        initial={{
            // width : "100%",
        }}
        whileHover={{
          scale : 1.1,
        //   paddingLeft : "25px"
        }}
        whileTap={{
          scale: 0.9,
          
        }}
        
        >

         <Button
         position="relative"
      height="30px"
      color="black"
      className='CollectionCard'
      variation='primary'
      isLoading={isloading}
      boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
    //   onMouseEnter={() => {
    //     QueryImageAgent()    
    //   }}
    //   isDisabled={dataloaded ? false : true}
    onClick={()=> {
        QueryImageAgent()    
    }
}
     
      >
        Query
         </Button>

         </motion.div>
    
      </Flex>


    {/* Query texfield plus button */}
       
     {/* The query box itself */}
     
     <motion.div
initial={{ opacity: 0, scale: 0.5 }}
animate={{ opacity: 1, scale: 1 }}
transition={{
  duration: 0.2,
  ease: [0, 0.50, 0.2, 1.01],
  scale: {
    type: "spring",
    damping: 5,
    stiffness: 100,
    restDelta: 0.001
  }
}}
>
      <Grid container
        
        // 
        // backgroundColor="var(--amplify-colors-white)"
       
        backgroundColor="rgba(255,255,255,.6)"
        // backgroundColor="pink"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.5)"
        borderRadius="16px"
        position="relative"
        // color="var(--amplify-colors-blue-60)"
        // maxHeight="100%"
        // maxHeight="552px"
        maxHeight="68vh"
        // maxHeight="100%"
        width="auto"
        paddingLeft="20px"
        paddingRight="20px"
        paddingTop="20px"
        paddingBottom="20px"
        direction="column"
        // width="100%"
        display="block"
        sx={{
          overflowX:"hidden",
          overflowY: "scroll"
        }}
      
        
        >

      
{isloading ? 
  <Grid
        container
        display="flex"
        position="relative"
        justifyContent="center"
        alignItems='center'

        width="100%"
        height="310px"
        // paddingRight="50px"
        // paddingTop="40px"
        // backgroundColor="pink"
        >
          <Flex
          justifyContent="flex-start"
          alignItems="center"
          position="relative"
          paddingRight="50px"
          paddingBottom="50px"
          width="40px"
          height="40px"
          // backgroundColor="brown"
          >
          <PropagateLoader
        loading="true"
        // color='#de8865cc'
        color='white'
        size="50px"
        /> 
          </Flex>
        </Grid>
        : 
       
          <Collection
        items={projectconversation || []}
        type="list"
        direction="column-reverse"
        position="relative"
        searchNoResultsFound=" "
        // searchPlaceholder="Search Projects..."
        // width="100%"
        // columnGap="15px"
        // isSearchable
        // paddingLeft="20px"
        // paddingRight="20px"
        // paddingTop="10px"
        // paddingBottom="20px"
        // backgroundColor="pink"
        // overflow="scroll"
        // style={{
        //     zIndex: 8
        // }}
        // isPaginated
        // itemsPerPage={2}  
    >
      {(item, index) => (   
          <Flex
          position="relative"
          // backgroundColor="pink"
          direction="column"
          gap="10px"
          paddingTop="0px"
          key={index}
          >

<Grid container
          position="relative"
          width="100%"
          // backgroundColor="rgba(255,255,255,.8)"
          // backgroundColor="rgba(222,136,101,.8)"
          backgroundColor="grey"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
          padding="10px 10px 10px 10px"
          borderRadius="16px"
          direction="column"
          gap="5px"
          sx={{
            textOverflow:"ellipsis",
            wordWrap: "break-word",
            lineBreak:"anywhere"
          
        }}
          >
          <Text
          // fontSize="15px"
          // backgroundColor="pink"
          color="white"
          >
          Query: 
          </Text>

          <ReactMarkdown
          className={"text-white"}
          >
            
          
          {item.query}
          
          
        
         
          </ReactMarkdown>
          {/* <GetImageUrl img={item.image}/> */}

          </Grid>

          

          <Flex
          position="relative"
          width="100%"
          backgroundColor="rgba(255,255,255,.6)"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
          padding="10px 10px 10px 10px"
          borderRadius="16px"
          direction="column"
          gap="5px"
          >
            <Text
            color="black"
          // backgroundColor="pink"
          >
          Response: 
          </Text>


          <ReactMarkdown
          className={"text-black"}
          >
            
          
          {item.response}
          
          
          
        
         
          </ReactMarkdown>

          {<DisplayImage image_key={item.image_url} />}

            {/* <Image
             src={item.image_url} 
             position="relative"
         objectFit="cover"
        //  backgroundColor="red"
         width="100%"
         height="100%"
         onClick={() => {
            setcurrentkey(item.image_url)
            setshowdata(true)
         }}

             
             /> */}
          
          </Flex>

          </Flex>
      )}
          </Collection>
         
          }
        

      </Grid>
     
    </motion.div>

    {/* The query box itself */}
       <Modal
    isOpen={showdata}
    onRequestClose={closeModal}
    style={{
        overlay: {
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          zIndex: 999,
        },
        content: {
          position: 'relative',
        //   display
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          border: '1px solid #ccc',
          height: "auto",
        //   background: 'blue',
          width: "302px",
        //   overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '0px'
        }
      }}
    
    >
        <View
        display="block"
        position="relative"
        // backgroundColor="pink"
        // width="300px"
        // height="30px"
        // justifyContent="center"
    // backgroundColor="yellow"
        // paddingTop="30px"
    >

       

        <Image
         src={currentkey}
         position="relative"
         objectFit="cover"
        //  backgroundColor="red"
         width="100%"
         height="100%"
        />
        


        </View> 

       </Modal>
       
      

      
   
     </View>
  )
}