import { Button, Collection, Flex, Image, Text, View } from '@aws-amplify/ui-react';
import { Grid, TextField } from '@mui/material';
import React from 'react'
import ReactMarkdown from 'react-markdown';
import getUserChat from '../realm-scripts/getUserChat';
import jsonsummaryv3 from '../langchain/jsonsummaryv3';
import invokeimageagent from '../lambda/invokeimageagent';
import GetImageUrl from './GetImageUrl';
import { PropagateLoader } from 'react-spinners';
import Modal from 'react-modal'
import {motion} from 'framer-motion'


export default function QueryboxDocumentAgent(props) {
    const currentproject = props.projectname
    const documentdata = props.documentdata
    const documenttoggle = props.documenttoggle
    const [dataloaded, setDataloaded] = React.useState(false)
    const [isloading, setisloading] = React.useState(false)

    const [queryPDF, setqueryPDF] = React.useState(false)
    const [pdfData, setpdfData] = React.useState({})

    const [queryIMG, setqueryIMG] = React.useState(false)
    const [imgData, setimgData] = React.useState({})

    const [queryMixed, setqueryMixed] = React.useState(false)
    const [mixedData, setmixedData] = React.useState({})

    // console.log("query Pdf", queryPDF)
    // console.log("query Img", queryIMG)
    // console.log("query mixed", queryMixed)
   
    const [projectconversation, setprojectconversation] = React.useState([])
    const UserChat = async () => {
      const userschat = await getUserChat(currentproject)
      // console.log("Project", currentproject)
      const convo = userschat.filter(convo => convo.image !== true)
      setprojectconversation(convo)
      }

    React.useEffect(() => {
        // console.log("Current Project", currentproject)
        if(currentproject === "" || currentproject === null || currentproject === undefined){
          // openselectprojectmodal()
          // console.log("Current Project Null")
          return
        }
        UserChat()
        
      }, [currentproject])
    
    // QueryBox handle s3 Document
    // console.log("Docu", documentdata)
    React.useEffect(() => {
        // console.log("Handle this Data", documentdata)
        setqueryPDF(false)
        setqueryIMG(false)
        setqueryMixed(false)
        setDataloaded(false)
        
        if(documentdata.length === 0 || documentdata.data.length === 0){
          // console.log("Document Data Null?", documentdata)
          // console.log("default - handle no data")
          setqueryPDF(false)
          setqueryIMG(false)
          setqueryMixed(false)
          setDataloaded(false)
          return
        }
    
        else {
  
          let k = ""
          let t = ""
          k = documentdata.key
          t = documentdata.type
  
          if(k === undefined){
            // console.log("Document Data Undefined", k)
            setDataloaded(false)
            // setpdfData(false)
            setqueryPDF(false)
            setqueryIMG(false)
            return
          }
  
          if(t === "pdf"){
            const rawtext = documentdata.data
            // console.log("Handling Documents Selected (PDF)", rawtext)
            const payload = {
              "documents selected" : rawtext
            }
            setpdfData(payload)
            setDataloaded(true)
            setqueryPDF(true)
            return
          }

          if(t === "img"){
            const data = documentdata.data
            // console.log("Handling Documents Selected (IMG) ", data)


            const payload = {
              "documents_selected" : data
            }

            setimgData(payload)
            setDataloaded(true)
            setqueryIMG(true)
            return
          }

          if(t === "mixed"){
            const data = documentdata.data
            // console.log("Handling Documents Selected (MIXED)", data)


            const payload = {
              "documents_selected" : data
            }
            setmixedData(payload)
            setqueryMixed(true)
            setDataloaded(true)
            alert('Selected a different Document Type! \nSelect Multiple PDFs or Multiple Images!')

            // setimgData(payload)
            // setDataloaded(true)
            // setqueryPDF(true)
            return
          }
          
      }
      }, [documentdata])
  



      const [query, setQuery] = React.useState("")
      const [newquery, setnewquery] = React.useState(false)
    React.useEffect(() => {
      // console.log("New query triggered", newquery)
      const refreshquerybox = async () => {
        UserChat()
        setnewquery(false)
      }
      refreshquerybox()
    }, [newquery])
    

      const QAJson = async () => {
        // console.log("Json Data", pdfData)
        setisloading(true)
        if(currentproject === "" || currentproject === null || currentproject === undefined){
          setisloading(false);
          window.scrollTo(0,0);
          alert("Select or Create Project!")
          // console.log("Select Project");
          return
          }
        
        if(query === "" || query === null){   
          alert('Enter Query!')
          setisloading(false)
          return
        }
  
        if(queryPDF === true){
          console.log("Querying pdf Data...")
          try {
            // console.log("pdfData", pdfData, query, currentproject)
            await jsonsummaryv3(pdfData, query, currentproject)
            setnewquery(true)
            setisloading(false)
            setQuery("")
            window.scrollTo(0,document.body.scrollHeight);
            return
          } catch (error) {
              console.log(error)
              setisloading(false)
              return
          }  
        }

        if(queryIMG === true){
          // console.log("Querying img Data...", imgData)

          const response = await invokeimageagent(query, imgData, currentproject)
          // console.log("Agent Response", response)

          window.scrollTo(0,document.body.scrollHeight);
          setnewquery(true)
          setisloading(false)
          setQuery("")
          return
          // try {
          //   // await jsonsummaryv3(pdfData, query, currentproject)
          //   setnewquery(true)
          //   setisloading(false)
          //   setQuery("")
          //   window.scrollTo(0,document.body.scrollHeight);
          //   return
          // } catch (error) {
          //     console.log(error)
          //     setisloading(false)
          //     return
          // }
        }

        if(queryMixed === true){
          // console.log("Mixed data")
          setnewquery(true)
          setisloading(false)
          setQuery("")
          alert('Selected a different Document Type! \nSelect Multiple PDFs or Multiple Images!')
          return
        }

        // console.log("Default Query... no documents slected")
        try {
          const nodata = [{
            "no documents selected": "answer user question"
          }]
          await jsonsummaryv3(nodata, query, currentproject)
          setnewquery(true)
          setisloading(false)
          setQuery("")
          window.scrollTo(0,document.body.scrollHeight);
          return
        } catch (error) {
            console.log(error)
            setisloading(false)
            return
        }
        
      }

      React.useEffect(() => {
        // console.log("Mongo Toggle", mongotoggle)
      //  if(mongotoggle === true){
      //    setDataloaded(true)
      //    setmongoData(true)
      //    setQuery("")
      //    setResponse("")
      //    setcollectname(mongocollectionname)
      //   //  console.log("Collection Name", collectname)
      //  }
  
      //  if(mongotoggle === false){
      //    // setDataloaded(false)
      //    setQuery("")
      //    setResponse("")
      //    setmongoData(false)
      //    setcollectname("")
      //   //  console.log("Collection Name", collectname)
      //  }
  
    //    console.log("Document Toggle", documenttoggle)
       if(documenttoggle === true){
         setDataloaded(true)
         // setpdfData(true)
         // setmongoData(false)
        //  setQuery("")
        
       }
  
       if(documenttoggle === false){
         // setDataloaded(false)
        //  setQuery("")
        //  setDataloaded(false)
        
        //  setqueryJson(false)
         
         // setmongoData(false)
         // setcollectname("")
       }
     }, [documenttoggle])

     
      
    //  React.useEffect(() => {
      // const GetImageUrl = async (props) => {
      //   console.log("key?", props.img)
      //   const key = props.img
      //   if(key === undefined){
      //     return (
      //       <Grid
      //       position="relative"
      //       >
      //         No Image
      //       </Grid>
      //     )
      //   } 
      //   else {
      //     try {
      //       const urlImage = await StorageGet(key)
      //   console.log("Img url", urlImage)  
      //   return (
      //     <Image 
      //   src={urlImage}
      //   />
      //   )
            
      //     } catch (error) {
      //       console.log(error)
      //       return null
      //     }
      // }

      // }
    //    getImageUrl()
    //  }, [])
     

    const [showQueryBox, setShowQueryBox] = React.useState(false)
    const closeQueryBox = async () => {
        setShowQueryBox(false)
    }
      



  return (
    <View
 
        className="relative flex flex-col  pt-[76px] pb-[15px] w-full h-[100vh] "
    
    // backgroundColor="pink"

    
    >
      <View id={`Da`} className="relative flex flex-col bg-[rgba(255,255,255,.3)] h-full pl-5 pr-5 rounded-3xl">
     {/* Query texfield plus button */}
    <Flex
      // backgroundColor="green"
      width="100%"
      height="auto"
      justifyContent="center"
      alignItems="center"
      direction="column"
      paddingTop="20px"
      paddingBottom="20px"
      gap="10px"
      >
   
   
    <TextField
      position="relative"
      // boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
      // backgroundColor="blue"
      
      // height="500px"
     multiline 
     fullWidth
    //  color='rbga[255,255,255,1]'
    className='text-white'
    id="outlined-basic" 
     label="Enter Query ..." 
     variant="outlined" 
      // top='-20px'
      value={query}
      onChange={(e) => {
          let { value } = e.target;
          setQuery(value);
        }}
      // height="45px"
    />

      <motion.div
        initial={{
            // width : "100%",
        }}
        whileHover={{
          scale : 1.1,
        //   paddingLeft : "25px"
        }}
        whileTap={{
          scale: 0.9,
          
        }}
        
        >
       <Button
       position="relative"
    height="30px"
    color="black"
    className='CollectionCard'
    variation='primary'
    isLoading={isloading}
    boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
    // isDisabled={dataloaded ? false : true}
    onClick={()=> {
      // QueryData(querydata, query)
      // console.log(dataloaded)
      // if(dataloaded === false){
      //   alert("Choose Dataset!")
      // }

      // if(mongoData === true){
      // AtlasQuery()
      // setisloading(true)
      // // setpdfData(false)
      // setqueryJson(false)
      // return
      // } 

      // if(queryPDF === true){
      //   // QAPdf()
        QAJson()
        // return
      // } 
      
    }}
   
    >
      Query
       </Button>
       </motion.div>
  
    </Flex>
   {/* Query texfield plus button */}
  {/* The query box itself */}
    <Grid container
      

      backgroundColor="rgba(255,255,255,.6)"
      boxShadow="0px 5px 15px rgba(0, 0, 0, .5)"
      borderRadius="16px"
    
      width="auto"
      paddingLeft="20px"
      paddingRight="20px"
      paddingTop="5px"
      paddingBottom="20px"
      direction="column"
      display="block"
      // width="100%"
    
      sx={{
        overflowX:"hidden",
        overflowY: "scroll"
      }}
      
    
      
    
      >

        <Grid container item
 
        display="block"
        height="40px"
        position="absolute"
        width="auto"
        paddingTop="8px"
        style={{
          zIndex: 1,

        }}
        >

        <Text
        position="relative"
        display="flex"
        paddingTop="0px"
        width="auto"
 
        borderRadius="10px"

        // backgroundColor="rgba(222,136,101,.8)"
        backgroundColor="grey"
        color="white"
        >
        {dataloaded ? "Document(s) Selected" : "No Document(s) Selected" }
        </Text>

        </Grid>

        {isloading ? 
        <Grid
        container
        display="flex"
        position="relative"
        justifyContent="center"
        alignItems='center'

        width="100%"
        height="350px"
        // paddingRight="50px"
        // paddingTop="40px"
        // backgroundColor="pink"
        >
          <Flex
          justifyContent="flex-start"
          alignItems="center"
          position="relative"
          paddingRight="50px"
          width="40px"
          height="40px"
          // backgroundColor="brown"
          >
          <PropagateLoader
        loading="true"
        // color='#de8865cc'
        color='white'
        size="50px"
        /> 
          </Flex>
        </Grid>
        : 
          <Grid
        position="relative"
        width="100%"
        height="auto"
        paddingTop="40px"
        // backgroundColor="red"
        >
          <Collection
        items={projectconversation || []}
        type="list"
        direction="column-reverse"
        position="relative"
        searchNoResultsFound=" "
        // searchPlaceholder="Search Projects..."
        // width="100%"
        // columnGap="15px"
        // isSearchable
        // paddingLeft="20px"
        // paddingRight="20px"
        // paddingTop="10px"
        // paddingBottom="20px"
        // // backgroundColor="pink"
        // overflow="scroll"
        // style={{
        //     zIndex: 8
        // }}
        // isPaginated
        // itemsPerPage={2}  
    >
      
      {(item, index) => (  
       
          <Flex
          position="relative"
          // backgroundColor="pink"
          direction="column"
          gap="10px"
          paddingTop="0px"
          key={index}
          >
  
  <Grid container
          position="relative"
          width="100%"
          // backgroundColor="rgba(255,255,255,.8)"
          // backgroundColor="rgba(222,136,101,.8)"
          backgroundColor="grey"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
          padding="10px 10px 10px 10px"
          borderRadius="16px"
          direction="column"
          gap="5px"
          sx={{
            textOverflow:"ellipsis",
            wordWrap: "break-word",
            lineBreak:"anywhere"
          
        }}
          >
          <Text
          // fontSize="15px"
          // backgroundColor="pink"
          color="white"
          >
          Query: 
          </Text>
  
          <ReactMarkdown
          className={"text-white"}
          >
            
          
          {item.query}
          
          
        
         
          </ReactMarkdown>
  
          <GetImageUrl img={item.images || []}/>
  
          </Grid>
  
          <Grid container
          position="relative"
          width="100%"
          // display="flex"
          // justifyContent="flex-start"
          // backgroundColor="rgba(222,136,101,.9)"
          backgroundColor="rgba(255,255,255,.6)"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
          padding="10px 10px 10px 10px"
          borderRadius="16px"
          overflow="scroll"
          direction="column"
          sx={{
            textOverflow:"ellipsis",
            wordWrap: "break-word"
        }}
          gap="5px"
          >
            <Text
            color="black"
          // backgroundColor="pink"
          >
          Response: 
          </Text>
  
          
          <ReactMarkdown
          
        
          components={{
           
            code({node, inline, className, children, ...props}) {
              const match = /language-(\w)/.exec(className || "")
              return !inline && match ? ( 
             
                <Grid container
              position="relative"
              paddingLeft="20px"
              paddingRight="20px"
              fontSize="15px"
              fontWeight="bold"
                  width="auto"
                  wrap="wrap"
                  whiteSpace="pre-wrap"
                  
                  sx={{
                    textOverflow:"ellipsis",
                    wordWrap: "break-word",
                    lineBreak:"anywhere"
                }}
                  
               
                  
                 {... props}>
                

                 
                  {children}
                  
                </Grid> 
            
                ) : <span
                className=' font-bold'
                // position="relative"
                >
                  {children}

                </span>
            }
          }}
          children={item.response}

        
          />
          
          </Grid>
  
          </Flex>
         
      )}
       
          </Collection>
  
          </Grid>
        }
        
        
    </Grid>
  {/* The query box itself */}
</View>
  <Modal
    isOpen={showQueryBox}
    onRequestClose={closeQueryBox}
    style={{
        overlay: {
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255,255,255,.8)',
          zIndex: 999,
          paddingLeft: "20px",
          paddingRight: "20px"
        },
        content: {
          position: 'relative',
        //   display
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          border: '3px solid rgba(222,136,101,.9)',
          background: 'rgba(222,136,101,.95)',
          width: "100%",
          WebkitOverflowScrolling: 'touch',
          borderRadius: '15px',
          outline: 'none',
          paddingTop: "5px",
          paddingBottom: "10px",
          paddingLeft: "20px",
          paddingRight: "20px"
        }
      }}
    >

        {/* <Text
        width="100%"
        textAlign="center"
        backgroundColor="red"
        >
            Image Agent
        </Text> */}
       {/* <ModalDummyAgent projectname={currentproject}/> */}

    </Modal>
    
    
 
      </View>

  )
}
