import { Flex, Image, Link, Text } from '@aws-amplify/ui-react'
import { Grid } from '@mui/material'
import React from 'react'

interface customResponse {
    [key : string]: any;
}

interface PageProps {
    data: customResponse
}

const TopResults: React.FC<PageProps> = ({data}) => {
    

    const siteLinks = data.sitelinks?.inline
    // console.log("google search data",data, siteLinks)

  return (
    <Grid container
    position="relative"
    display="block"
    // justifyContent="flex-start"
    wrap="wrap"
    direction="column"
    
    // color="red"
    sx={{
        // background: "green",
        textOverflow:"ellipsis",
        wordWrap: "break-word"

    }}
    width='333px'
    height="auto"
    // paddingTop="10px"
    overflow="scroll"
    
    >


    <Flex
        position='relative'
        display="column"     
        height="auto"  
        width="100%"  
        wrap="wrap"
        gap="0px"
        backgroundColor="rgba(255,255,255,.7)"
        padding={"10px 10px 10px 10px"}
        borderRadius="5px"
        >
        
       <Flex
       width="100%"
       height="auto"
       direction="row"

       gap="0px"
    //    backgroundColor="white"  
       >

         <Image
        alt="Search Result Image"
        width="40px"
        height="40px"
        // title="Search Result Image"
        src={data.favicon}>
        </Image>

        <Flex
        // alignItems="end"
        position="relative"
        height="100%"
        width="100%"
        paddingLeft="5px"
        paddingTop="8px"
        fontWeight="bold"
        // backgroundColor="brown"
        >
        {/* <Text
        position="relative"
        fontSize="16px"
        fontWeight="bold"
        height="25px"
        width="auto"
        // backgroundColor="pink"
        
        
        > */}
         
            {data.source}
            
        {/* </Text> */}
        </Flex>


        
       
       
        
        
        </Flex>

        <Flex
        position="relative"
        fontSize="12px"
        width="100%"
        // backgroundColor="blue"
        // overflow="scroll"

        >
            <Link
            color="black"
            fontSize="12px"
            paddingTop="5px"
            // backgroundColor="red"
            // overflow="scroll"
            
            textDecoration="underline"
            href={data.link}
            isExternal={true}
            >
             View Source
             </Link>
        </Flex>


    </Flex>
       

      
       
        

    </Grid>
  )
}

export default TopResults
