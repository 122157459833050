import { Button, Collection, Flex, Text, View } from '@aws-amplify/ui-react'
import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import { Tilt } from 'react-tilt'
import Modal from 'react-modal'
import NewProject from './NewProject'
import getUserProjects from '../realm-scripts/getUserProjects'
import "../App.css"
import { PropagateLoader } from 'react-spinners'
import deleteUserProjectChat from '../realm-scripts/deleteUserProjectChats'
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom'



export default function ProjectsDisplay(props) {

  const matches = useMediaQuery('(max-width:450px)');
  
  
    const [items, setitems] = React.useState([])
   
    const currentproject = props.currentproject


    const [deleteProjectModal, setdeleteProjectModal] = React.useState(false)
    const [projectToBeDeleted, setprojectToBeDeleted] = React.useState("")
    const closeDeleteModal = () => {
      setdeleteProjectModal(false)
    }
    const [newproject, setnewproject] = React.useState(false)
    const closeModal = () => {
        setnewproject(false)
    
    }

    const [clickedProject, setclickedProject] = React.useState(false)
    const closeProject = () => {
        setclickedProject(false) 
        
    }

    const [loadingItems, setloadingItems] = React.useState(true)
    React.useEffect(() => {
        const GetProjects = async () => {
            const projects = await getUserProjects()
            // console.log("Projects", projects) 
            setitems(projects)
            setloadingItems(false)
        }
        GetProjects()
    }, [])

    const GetProjects = async () => {
      setloadingItems(true)
      const projects = await getUserProjects()
      // console.log("Projects", projects)
      setitems(projects)
      setloadingItems(false)
  }

  const deleteProjectAndChats = async (projectName) => {
    await deleteUserProjectChat(projectName)
    await GetProjects()
   
 }
  

    const selectedProject = async (projectname) => {
        setprojectToBeDeleted(projectname)
        currentproject(projectname)
        await GetProjects()
    }

    

  return (
  
    <View 
      className="relative flex flex-col pt-[10px] pl-[20px] pr-[20px]   w-[100vw] "
      //  backgroundColor="rgba(225,255,255,.2)"
      
      // padding="0px 10px 10px 10px"
    // position="relative"
    // width="100vw"
    // // height="200px"
    // display="flex"
    // direction="column"
    // gap="0px"
    // // backgroundColor="rgba(222,136,101,1)"
    // justifyContent="center"
    // alignItems="center"
    // paddingTop="20px"
    // paddingBottom="5px"

    >
      <View boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)" className="bg-[rgba(225,255,255,.2)] p-[10px] rounded-3xl "> 
        {/* <CollectionCard /> */}
        <View
        className="relative flex justify-between gap-[10px] pr-[10px] pb-[10px]"
        
        //  container item
        // justifyContent="space-between"
        // position="relative"
        // // width="100%"
        // paddingBottom="20px"
        // wrap="nowrap"
        // gap="10px"
        
        // backgroundColor="pink"
        >
        <Text
        textAlign="center"
        color="white"
        fontSize={matches ? "25px" : "35px"}
        height="100%"
        paddingLeft="10px"
        paddingTop="0px"
        wrap="nowrap"
        // backgroundColor="pink"
        >
          All Projects 
        </Text>
        <Button
        // display="flex"
        // justifyContent="center"
        // alignItems="center"
        width={matches ? "180px" : "200px"}
        textAlign="center"
        alignSelf="center"
        position="relative"
        className='Project'
        
        backgroundColor="rgba(225,255,255,.3)"
        variation='primary'
        // paddingBottom={}
        children="Create New Project"
        onClick={()=>{
            setnewproject(true)
        }}
        >
           
        </Button>
        </View>

       
        {loadingItems ? 
        <Grid
        container
        display="flex"
        position="relative"
        justifyContent="center"
        alignItems='center'

        width="100%"
        height="310px"
        paddingBottom="95px"
        // paddingRight="50px"
        // paddingTop="40px"
        // backgroundColor="pink"
        >
          <Flex
          justifyContent="flex-start"
          alignItems="center"
          position="relative"
          paddingRight="50px"
          width="40px"
          height="40px"
          // backgroundColor="brown"
          >
          <PropagateLoader
        loading="true"
        // color='#de8865cc'
        color='white'
        size="50px"
        /> 
          </Flex>
        </Grid>  : 
        <Collection
        items={items || []}
        type="list"
        // templateColumns="1fr 1fr"
        // templateRows="12rem 12rem"
        direction="row"
        // display="block"
        justifyContent="flex-start"
        // justifyContent="center"
        

        className='Collection'
        alignItems="center"
        // wrap="wrap" 
        searchPlaceholder={` `}
        searchNoResultsFound={
            <Flex justifyContent="center"
            paddingBottom="20px"
            >
              <Text fontSize="2rem"
              color="white"
              textAlign="center"
              >
                {`No Projects Created`}
              </Text>
            </Flex>
          }
        width="100%"
        // height="100px"
        columnGap="15px"
        isSearchable
        paddingLeft="20px"
        textAlign="center"
        paddingRight="20px"
        // wrap="nowrap"
        paddingTop="15px"
        paddingBottom="20px"
        // color="white"
        // backgroundColor="pink"
        overflow="scroll"
        
        isPaginated 
        itemsPerPage={7}  
    >

      {(item, index) => (   

        
        <Tilt
        key={index}
        >
          <a href='#Agents'>
          
            <Button
            display="flex"
            variation="primary"
            height="auto"
            justifyContent="center"
            alignItems="center"
            position="relative"
            boxShadow="0px 5px 15px rgba(0, 0, 0, .4"
            borderRadius="16px"
            // backgroundColor="rgba(222,136,101,.8)"
            // backgroundColor="rgba(225,255,255,.3)"
            backgroundColor="rgba(255,255,255,.5)"
            direction="column"

            paddingBottom="20px"
            paddingTop="20px"
            className="CollectionCard"
            onClick={()=>{
                selectedProject(item.projectname)
                // setclickedProject(true) 
            }}
            >
              
              <Flex
            position="relative"
            backgroundColor="green"  
            width="100%"
            justifyContent="flex-end"
            // paddingRight="10px"
            >

              <Button
              borderRadius='5px'
              textAlign="center"
              display="flex"
              variation="primary"
              className="Project"
              color="white"
              backgroundColor="gray"
              // backgroundColor="rgba(225,255,255,.3)"
              // justifyContent="center"
              // alignItems="center"
              height="18px"
              width="5px"
              marginTop="-28px"
              // marginLeft="80px"
              paddingTop="5px"
              onClick={() => {
                setdeleteProjectModal(true)

                
                
              }}
              >
                x
              </Button>

            

            </Flex>

            <Text
            textDecoration="none"
            variation="primary"
            position="relative"
            fontSize="15px"
            display="flex"
            fontWeight="bold"
            width="100px"
            height="120px"
            justifyContent="center"
            alignItems="center"
            color="white"
            
            >
                Project: {item.projectname} 
            </Text>
    
        
            </Button>

          </a>

        </Tilt>
        
        
      )}

             

        </Collection>   
        
        }
       </View>

        
        <Modal
            isOpen={clickedProject}
            onRequestClose={closeProject}
            style={{
                overlay: {
                position: 'fixed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                zIndex: 999,
                padding: '20px'
                },
                content: {
                position: 'relative',
        //   display
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                border: '1px solid #ccc',
                
        //   background: 'blue',
        //   width: "302px",
        //   overflow: 'auto',
                backgroundColor:"rgba(222,136,101,.9",
                WebkitOverflowScrolling: 'touch',
                borderRadius: '20px',
                outline: 'none',
                padding: '30px'
                
                }
            }}
            >
              
              
              <Flex
              position="relative"
              justifyContent="center"
              alignItems="center"
              direction="column"
              textAlign="center"
              // backgroundColor="rgba(222,136,101,1"
              >
            {/* <ReactMarkdown> */}
            <Text
            position="relative"
            color="white"
            fontSize="20px"
            fontWeight="extrabold"
            // lineHeight="30px"
            // padding=" 20px 20px 20px 20px"
            >
              
                Select an Agent!
                

            </Text>
            
            
              </Flex>
              
              
        
            {/* <NewProject
            closeprojectmodal={closeModal}
            currentproject={currentproject}
            /> */}
            {/* <SignIn /> */}

        </Modal>
       

        <Modal
            isOpen={newproject}
            onRequestClose={closeModal}
            style={{
                overlay: {
                position: 'fixed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                zIndex: 999,
                },
                content: {
                position: 'relative',
        //   display
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                border: '0px solid #ccc',
                
          background: "gray",
        //   width: "302px",
        //   overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '20px',
                outline: 'none',
                padding: '0px'
                }
            }}
            >
        
            <NewProject
            closeprojectmodal={closeModal}
            // currentproject={currentproject}
            selectproject={selectedProject}
            />
            {/* <SignIn /> */}

        </Modal>

        <Modal
         isOpen={deleteProjectModal}
         onRequestClose={closeDeleteModal}
         style={{
             overlay: {
             position: 'fixed',
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             top: 0,
             left: 0,
             right: 0,
             bottom: 0,
             backgroundColor: 'rgba(255, 255, 255, 0.75)',
             zIndex: 999,
             },
             content: {
             position: 'relative',
     //   display
             top: '0px',
             left: '0px',
             right: '0px',
             bottom: '0px',
             border: '0px solid #ccc',
             
       background: "gray",
     //   width: "302px",
     //   overflow: 'auto',
             WebkitOverflowScrolling: 'touch',
             borderRadius: '20px',
             outline: 'none',
             padding: '0px'
             }
         }}
         >

<Flex
           position="relative"
           justifyContent="center"
           alignItems="center"
           direction="column"
           textAlign="center"
           width="300px"
           height="200px"
          
           >
        
         <Text
         position="relative"
         color="white"
         fontSize="20px"
         fontWeight="extrabold"
         
         >
           
             Are you sure you want to delete this Project?
             {/* {console.log(projectToBeDeleted)} */}
             
             

         </Text>
         <Flex
         width="100%"
         // backgroundColor="pink"
         position="relative"
         wrap="nowrap"
         gap="50px"
         height="auto"
         justifyContent="center"
         paddingTop="5px"
         >
            <motion.button
     whileHover={{scale : 1.2}}
     whileTap={{scale: 0.9}}
     onClick={() => {
     setloadingItems(true)
     deleteProjectAndChats(projectToBeDeleted)
     alert("Project successfully deleted!")
     currentproject("")
     closeDeleteModal()
     window.scrollTo(0,0)
    
     
     
     
    
     
    }}
     
     >
           <Text
           
           color="white"
           backgroundColor="rgba(255,255,255,.5)"  
           display="flex"           
           height="35px"
           width="45px"
           justifyContent="center"
           alignItems="center"
          //  textAlign="center"
           borderRadius="5px"
           fontWeight="bold"
           className='relative cursor-pointer'
           
           >
             Yes
           </Text>
           </motion.button>
           <motion.button
     whileHover={{scale : 1.2}}
     whileTap={{scale: 0.9}}
     onClick={closeDeleteModal}
     >
           <Text
           color="white"
           backgroundColor="rgba(255,255,255,.5)"
           display="flex"           
           height="35px"
           width="45px"
           justifyContent="center"
           alignItems="center"
          //  textAlign="center"
           borderRadius="5px"
           fontWeight="bold"
           className='cursor-pointer'
           >
             No
           </Text>
           </motion.button>
         </Flex>
         
         
           </Flex>
           
         
        </Modal>  

       


    </View>
  )
}
