import { Storage } from "aws-amplify"

const GetAllS3 = async (props) => {

    // Needs project propto get all objects related to current project
    try {
        const data = await Storage.list(
            '', 
            {level: 'private'},
            { pageSize: 800 },
            )
            // console.log("Get all Data", data)
            // console.log("Get All results", data.results)
            return data.results
        
    } catch (error) {
        return error
    }


}

export default GetAllS3
