import { BufferWindowMemory } from "langchain/memory";
import getuser from "../aws_utils/getAuthuser";
import getUserChat from "../realm-scripts/getUserChat";
import invokedummyagent from "../lambda/invokedummyagent";


const dummyagent = async (query, projectname) => {

    if(projectname === "" || projectname === undefined){
        alert("Select or Start New Project!")
        return
    }
    

    // Authenticating to Mongo for Pushing User Chat
const Realm = require("realm-web"); 
const REALM_ID = process.env.REACT_APP_REALM_APP_ID;
const REALM_KEY = process.env.REACT_APP_REALM_APP_KEY;
const app = new Realm.App({id: REALM_ID});
const {
  BSON: { ObjectId },
} = Realm;

// Authenticate the user
try {
  const credentials = Realm.Credentials.apiKey(REALM_KEY)
  await app.logIn(credentials);
//console.log(user)
} catch (error) {

  console.log("Error in Logging", error)

}

let userid = ""
try {
    const user = await getuser()
    userid = user.username
} catch (error) {
    console.log(error)
}

let collection = ""
try {
    const mongo = app.currentUser.mongoClient("dbhaiapp");
    collection = mongo.db("dbhprojects").collection("userschat");
} catch (error) {
    console.log("Get userchat collection Error", error)
}

 // Buffer Window Memory
 const memory = new BufferWindowMemory({ k: 5 });

 // Attempts to add memery
  const userchat = await getUserChat(projectname)
  if(userchat.length === 0){
    //   console.log("No User Chats!")
      } else {
      
    //   console.log("User Chats Exist!")
      userchat.forEach(async item => {
          // console.log("Query", item.query)
          // console.log("Response", item.response)
          
          if(item.query === null){
          await memory.saveContext(
              {input: "..."},
              {output: item.response}
          )
          return
          }
  
          if(item.response === null){
              // console.log("2")
          await memory.saveContext(
              {input: item.query},
              {output: "..."},
              )
              return
          }
  
          await memory.saveContext(
              {input: item.query},
              {output: item.response},
          )
  
          });
      }
  
//   console.log("Conversation Memory", memory)

  const history = await memory.loadMemoryVariables({});
  const messages = await memory.chatHistory.getMessages();

//   console.log("history", history.history)
//   console.log("Messages", messages)
 

  const serializeChatHistory = async (chatHistory) => {
      let aiCount = 0;
      let humanCount = 0;
      const serializedMessages = chatHistory.map((chatMessage) => {
          if (chatMessage._getType() === "human" && humanCount < 5) {
              humanCount++;
              return `Human: ${chatMessage.content}`;
          } else if (chatMessage._getType() === "ai" && aiCount < 5) {
              aiCount++;
              return `Assistant: ${chatMessage.content}`;
          } else {
              return null;
          }
      }).filter(message => message !== null).join("\n");
      return serializedMessages;
  }
  

  const newmem = await serializeChatHistory(messages)
  // console.log("Newmem", newmem)
  const sumupmemory = {
  
      "RECENT CHAT HISTORY": history,
      "OLDEST CHAT HISTORY": newmem,
  
  }
  const memorysummary = JSON.stringify(sumupmemory)
//   console.log(memorysummary)

    const response = await invokedummyagent(query, "");
    // const parseresponse = JSON.parse(response)
    // console.log(response, parseresponse.body)
    let finalresp

    // console.log("general agent", response)
    if(response === `error`){

        finalresp = `Request Timed Out... Try query again`
        
    } else {
       const parseresponse = JSON.parse(response)
    // console.log(parseresponse.body);
    if(parseresponse.body === "" || parseresponse.body === null || parseresponse.body === undefined){
        finalresp = `Try rephrasing or simiplifying your query!`
    } if(parseresponse.body === `Agent stopped due to max iterations.`){
        finalresp = `Try rephrasing or simiplifying your query!`
    } else {
        finalresp = parseresponse.body
    }
    }

    
    let result = ""
try {
    result = await collection.insertOne({
        "project": projectname,
        "userId": userid,
        "query": query,
        "response" : finalresp,   
        "agent" : "dummy agent" 
        });

    // console.log("Document added sucessfully", result);
    
} catch (error) {
    console.log("Error Add Document", error)
    return 'error'
}

const output = {
    "result": result,
    "response": finalresp,
}

return output

}

export default dummyagent

