import { Auth } from 'aws-amplify';

async function signIn(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    // console.log(user)
    return user 
  } catch (error) {
    alert("Incorrect Password!")
    console.log('error signing in', error);
  }
}

export default signIn