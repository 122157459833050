import { Auth } from "aws-amplify";


export default async function awsresetpassword(username, code, newPassword) {
 
    try {

        const data = await Auth.forgotPasswordSubmit(username, code, newPassword);       
        return data
      } catch(err) {
      
      
        return {"err": err}
      }
}
