import { Collection, Flex, Grid, Image, Text } from '@aws-amplify/ui-react'
import StorageGet from '../aws_utils/s3storageget'
import React from 'react'

export default function GetImageUrl(props) {
    // console.log("key?", props.img)
    const key = props.img
    // console.log("Key", key)
    const [urlImage, seturlImage] = React.useState(null)
    const [isImage, setisImage] = React.useState(false)
    const [image_names, setimage_names] = React.useState("")
    
    React.useEffect(() => {
        const fetchImageUrl = async () => {
        if(key === undefined || key === null || key === ""){
            // seturlImage(null)
            setisImage(false)
            // console.log("Image?", key)
            return
        } 

        if(typeof key !== 'object'){
            // console.log("Default")
            setisImage(true)
            seturlImage(key)
            return
        }

        if(key.length >= 1){
            let objkey = [{}]
            objkey = key
       
            const imgs = objkey.map(img => {
                // console.log(img)
                return img.key

            })


            // console.log("Mapped img data", imgs)

            let names = ""
            imgs.map(img => {
                names += `${img} \n`
                
            })
          
            setisImage(true)
            seturlImage(imgs)
            setimage_names(names)
            return
            
        }
        
        // if(key.length >= 2){
        //     let objkey = [{}]
        //     objkey = key
       
        //     const imgs = objkey.map(img => {
        //         return img.key
        //     })
        //     console.log("Mapped img data", imgs)
        //     let names = ""
        //     imgs.map(img => {
        //         names += `${img} \n`
                
        //     })
        //     // const imgnames = JSON.stringify(imgs)
        //     setisImage(true)
        //     seturlImage(names)
        //     return
        //     // const keystring = JSON.stringify(key)
        // }
        
        
    }
    fetchImageUrl()
    }, [key])



        const [url, seturl] = React.useState([])
        // console.log("url", url)

        React.useEffect(() => {
            
            // console.log("url_image", urlImage)

            if(urlImage === null){
                // console.log("null of image url")
                // seturl([])
                return
            }

            

            if(urlImage.length >= 1){
                // console.log(urlImage)
                urlImage.map(img => {
         

                const GetUrl = async () => {
                    
                const image_url = await StorageGet(img)

                // url = image_url
                // console.log("img", img)
                // console.log("img_url", image_url)

                const data = {
                    "img" : img,
                    "img_url" : image_url
                }

                if(url.find(img => img.img === data.img)){
                    // console.log("img in question", img)
                    // console.log("FOund match")
                    return
                } else {

                url.push(data)
                }
                return 

                // console.log("url?", image_url)
                // seturl(image_url)
                }

            
                return GetUrl()
                
            })
    
        }
            
               

        }, [urlImage, url])
        
        

    // }
    
   
    return (
        <Grid
        position="relative"
        >

      {isImage ?
        <Flex
        direction="column"
        >

        {/* <Text
        color="white"
        fontWeight="bolder"
        >
            {image_names}
        </Text> */}

        <Collection 
        items={url || []}
        >
            {(item, index) => (   
       

       
           <Flex
           key={index}
           justifyContent="center">

            {/* {item.img} */}

             <Image 
                    
                    src={item.img_url} 
                    position="relative"
                    objectFit="cover"
                    //  backgroundColor="red"
                    width="auto"
                    height="400px"
                     />

            </Flex>
       

      
   
     )}


        </Collection>
        
        {/* <DisplayImage img_names={urlImage}/> */}

        </Flex>
        
         : 
        <Text>
            {/* No Image */}
        </Text>
        }

        </Grid>
    )


}
