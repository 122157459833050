import { Grid } from '@mui/material'
import React from 'react'
import { Text } from '@aws-amplify/ui-react'
import { motion } from 'framer-motion'
import { fadeIn, staggerContainer, textVariant } from '../aws_utils/motion';
import { styles } from '../aws_utils/styles';
import { Tilt } from 'react-tilt';

export default function Features() {

    const services = [
        {
          title: "Document Agent",
          description: "Allows you to question your PDF documents and get answers with ease. It's designed to make the process of QA for PDF documents simpler and more efficient. This means less time searching and reading through a document and more time for other important tasks. CAN NOW QUERY IMAGES AS WELL!",
        },
        {
          title: "Search Agent",
          description: "Has the ability to pull Google search results to provide answers. It's designed to make the process of finding information easier and more efficient. With this agent, you can easily find the information you need, ensuring that you have the most accurate and up-to-date information.",
        },
        {
          title: "Dummy Agent",
          description: "A powerful chatbot for quality responses and answers. It's designed to improve communication and provide assistance. With this agent, you can easily get the answers you need. This means less time spent on searching for answers and more time for other important tasks",
        },
        {
          title: "Image Agent",
          description: "Unlock Visual Insights for Your Research with Image agent built to generate images using DALL·E 3",
        },
       
      ];

    const ServiceCard = ({index, title, description}) => {
        return (
          // <p>
          //   {title}
          // </p>
          // Adding amination to a component upon view
    
          <motion.section
          variants={staggerContainer()}
          initial="hidden"
          whileInView="show"
          viewport={{once: false, amount: 0.5}}
          >
     
    
       
          <Tilt
        //   className="xs:w-[250px] w-full"
          >
            
            <motion.div
              variants={fadeIn("right","spring", 0.5, index, 0.75)}
            //   className="rounded-[20px] green-pink-gradient p-[1px]  shadow-card"
              >
                
                {/* <div
                // options={{
    
                //   max: 450,
                //   scale: 100,
                //   speed: 1 
                // }}
                className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[260px] flex justify-evenly items-center flex-col"
                > */}
                  
                  {/* <img src={icon} alt={title}
                  className="w-16 h-16 object-contain"
                  /> */}


                  <Grid container 
                  position="relative"
                  display="flex"
                  // backgroundColor="rgba(222,136,101,.8)"
                  backgroundColor="rgba(225,255,255,.3)"
                  direction="column"
                  justifyContent="center"
                  alignItems='center'
                  wrap="nowrap"
                  width="220px"
                  borderRadius="15px"
                  padding="20px 20px 20px 20px"
                  boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
                //   className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[260px] flex justify-evenly items-center flex-col"

                //   classname="flex  justify-center font-bold text-center text-white text-[20px]"
                  >
                    <Text
                    color="white">
                    {title}
                    </Text>
                    <Text
                    fontSize="10px"
                    paddingTop="10px"
                    color="white"
                    >
                    {description}
                    </Text>
                  </Grid>
    
                {/* </div> */}
              
              </motion.div>
    
          </Tilt>
          </motion.section>
        )
      }


  return (

    <Grid container
    position="relative"
    width="100%"
    height="100%"
    paddingLeft="25px"
    paddingRight="25px"
    paddingBottom="200px" 
    direction="column"
    display="flex"
    justifySelf="flex-start"
    alignSelf="flex-start"
    // justifyContent="flex-start"
    // alignItems="start"
    // alignItems="center"
    // marginTop="80px"
    // padding
    // backgroundColor="rgba(222,136,101,.4)"
    wrap='nowrap'
    >
        <span className='hash-span' id={`Features`}>
        <Grid container item
        width="100%"
        height="80px"
        // paddingBottom=
        // backgroundColor="red"
        >

        </Grid>

        
        
        <motion.section
          variants={staggerContainer()}
          initial="hidden"
          whileInView="show"
          viewport={{once: false, amount: 0.5}}
          >
     
    <motion.div variants={textVariant()}
    // className=" bg-slate-600"
    >
     
      <h2 className={`${styles.sectionHeadText}`}
      >

        Features
      </h2>

    </motion.div>
    </motion.section>

      <Grid container
      position="relative"
      height="auto"
    //   height="400px"
    //   marginTop="60px"
    paddingTop="20px"

      display="flex"
    //   direction="row"
      // backgroundColor="grey"
      justifyContent="center"
      gap="20px"


      // className="mt-20 flex flex-wrap gap=20"
      >
        {services.map((service, index) => (
          <ServiceCard key={service.title} index={index} {... service}/>
        ))}
      </Grid>
        </span>
      </Grid>

  )
}
