import { Button, Flex, Image, Text } from '@aws-amplify/ui-react'
import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import UnAuthNavBar from './UnAuthNavBar'
import { motion } from 'framer-motion'
// import { fadeIn, staggerContainer, textVariant } from '../aws_utils/motion';
// import { styles } from '../aws_utils/styles';
import About from './About'
import Features from './Features'
// import img from '/gateleak.png'

export default function UnAuthHome() {

  const media = useMediaQuery('(min-width:450px)')

  // const icon = {
  //   hidden: {
  //     pathLength: 0,
  //     fill: "rgba(255, 255, 255, 0)"
  //   },
  //   visible: {
  //     pathLength: 1,
  //     fill: "rgba(255, 255, 255, 1)"
  //   }
  // }

  return (

    <Grid container
    backgroundColor="grey"
    position="relative"
    display="block"
    width="100%"
    height="100%"
    direction="column"
    wrap="nowrap"

    
    // height="auto"
    >
    <Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    // src="/home.gif"
    src={'/gateleakv2.gif'}
    // srcSet='/home.svg 300w'
    alt=" "
    width="100%"
    height="100%"
    objectFit="cover"
    // objectPosition="top"
    position="fixed"
    // overflow="scroll"
    />

    <UnAuthNavBar />

    {/* <Flex
   
width="100%"
position="relative"
height="100vh"
// backgroundColor="pink"
>
<Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    src={`${media ? "/GateleakMini.gif" : "/GateleakPhone.gif"} `}
    // srcSet='/home.svg 300w'
    alt=" "
    width="100%"
    height="100%"
    objectFit="cover"
    // objectPosition="top"
    position="absolute"
    // overflow="scroll"
    />
    <Flex
    width="100%"
    position="relative"
    height="100vh"
    // backgroundColor="pink"
    paddingLeft="40px"
    paddingRight="40px"
    
    alignItems="end"

    // bottom="20px"
    >
      <Button
      width="100%"
      position="relative"
      height="50px"
      bottom="250px"
      backgroundColor="transparent"
      variation="primary"
      >
        Sign Up
      </Button>
    </Flex>

{/* <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="1640.000000pt" height="924.000000pt" viewBox="0 0 1640.000000 924.000000"
 preserveAspectRatio="xMidYMid meet">


</svg> */}

    {/* <motion.path
      // d="M0 100V0l50 50 50-50v100L75 75l-25 25-25-25z"
      variants={icon}
      initial="hidden"
      animate="visible"
    >
      
    <Image
    position="relative"
    src='/gateleak.png'
    width="100%"
    height="auto"
    // display="flex"
    // justifyContent="flex-end"
    // alignSelf="flex-end"
    // bottom="small"
    
    // backgroundColor="red"
    />
    
    
    </motion.path> */}

   
    {/* </Flex> */} 

    <About/>
    <Features/>

    <span className='hash-span' id={`Contact`}>
      <Grid container 
      position="relative"
      display="block"
      // backgroundColor="rgba(222,136,101,.4)"
      backgroundColor="rgba(225,255,255,.3)"
      direction="column"
      width="100%"
      height="100%"
    //   gap="10px"
      paddingLeft="15px"
      paddingRight="15px"
      paddingBottom="10px"
      paddingTop="20px"
      >

        <Flex>
          <Text
          color="white"
          paddingBottom="5px"
          >
            BLESS.WARE LLC. All rights reserved.
          </Text>
        </Flex>

        <Flex
        // backgroundColor="red"
        direction="column"
        gap="0px"
        paddingBottom="5px"
        >
        <Text
        color="white"
        fontWeight="bold"
        height="auto"
        paddingBottom="5px"
        // backgroundColor="pink"
        >
          Contact Us
        </Text>

        <Flex
        // backgroundColor="red"
        gap="5px"
        height="auto"
        // backgroundColor="green"
        >
        <Text
        color="white"
        // paddingTop="10px"
        display="flex"
        // gap="5px"
        fontWeight="bold"
        
        >
          {`Email:`}
        </Text>
        <Text
        color="white"
        // paddingTop="10px"
        display="flex"
        // gap="5px"
        
        >
          {`ohbless.ware@gmail.com`}
        </Text>
        </Flex>
        <Flex
        // backgroundColor="red"
        gap="5px"
        height="auto"
        // backgroundColor="green"
        >
        <Text
        color="white"
        // paddingTop="10px"
        display="flex"
        // gap="5px"
        fontWeight="bold"
        
        >
          {`Instagram:`}
        </Text>
        <Text
        color="white"
        // paddingTop="10px"
        display="flex"
        // gap="5px"
        
        >
          {`@Gateleak`}
        </Text>
        </Flex>

        
        </Flex>

        {/* <Flex>
          <Text
          color="white"
          fontWeight="extrabold"
          // fontFamily="monospace"
          fontSize="xx-large"
          width="100%"
          // backgroundColor="pink"
          textAlign="center">
            Your Answers Knocking!
          </Text>
        </Flex> */}

      </Grid>

    </span>
    




    </Grid>

  )
}
