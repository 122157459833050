import { Auth } from 'aws-amplify';


async function confirmSignUp(email, code) {
  try {
    await Auth.confirmSignUp(email, code);
    // console.log("Sign Up Response", response)
    // return response
  } catch (error) {
    // console.log('error confirming sign up', error);
    return error
  }

  

}

export default confirmSignUp