import { Auth } from "aws-amplify";


export default async function awsresetcode(username) {
    try {
        const data = await Auth.forgotPassword(username);

        
        return data
      } catch(err) {
      
        return {"err": err}
      }
      
}
