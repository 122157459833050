import React from 'react'
import { Flex, Text } from '@aws-amplify/ui-react'
import { Grid } from '@mui/material'
import { motion } from 'framer-motion'
import { fadeIn, staggerContainer, textVariant } from '../aws_utils/motion';
import { styles } from '../aws_utils/styles';

export default function About() {
  return (
    <motion.section
      variants={staggerContainer()}
      initial="hidden"
      whileInView="show"
      viewport={{once: false, amount: 0.25}}
      >
        {/* <a href="/unAuth/about"
          className='z-10'
          > */}
        <span className='hash-span' id={`About`}>
        
        </span>
          

        <Grid container
//   marginTop="75px"
  paddingLeft="30px"
  paddingRight="30px"
  display="block"
  justifyContent="center"
  alignItems="center"
  paddingBottom="50px"
  paddingTop="100px"
  position="relative"
  width="100%"
  height="110vh"
//   direction="column"
  overflow="auto"
  wrap="nowrap"
//   zIndex="2"
// backgroundColor="brown"



//   backgroundColor="rgba(222,136,101,.8)"
  >
        
      <Grid container item
    position="relative"
    // backgroundColor="rgba(222,136,101,.6)"
    backgroundColor="rgba(225,255,255,.3)"
    
    width="auto"
    // maxWidth="600px"
    // minWidth="100px"
    display="block"
    height="auto"
    borderRadius="15px"
    boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
    paddingLeft="20px"
    paddingRight="20px"
    paddingBottom="30px"
    // paddingTop="10px"
    overflow="scroll"

    >

    <Grid container item
    position="relative"
    width="100%"
    height="auto"
    // backgroundColor="red"
    paddingTop="10px"
    >
    <motion.div variants={textVariant()}
    // className=" bg-slate-600"
    >
      <p 
      className={`${styles.sectionHeadText}`}
      >
        Introduction
      </p>
      <h2 className={styles.heroSubText}
      >
        What is this?
      </h2>
      

    </motion.div>
    </Grid>

   
   
      <Grid container
    position="relative"
    width="100%"
    height="auto"
    // backgroundColor="red"
    >
    
       <motion.p
    //  Fuor paramters for FadeIn, direction, type, delay, duration of animation
      variants={fadeIn("","",0.1,1)}
      className="text-white text-[14px] mt-[10px] leading-[30px] "
      >
          
       {`Our AI-empowered web application is crafted to modernize your research and growth exeperience approach. It enhances the Question and Answer procedure, making it more user-friendly and efficient. Utilize an Agent to upload a document and then pose questions, or simply use an Agent to address a pressing query. It provides prompt search results, ensuring that you pinpoint exactly what you're searching for in your documents or web. This means less time dedicated to searching and more time for other critical tasks.`} 
      
       </motion.p>
      

          <a href="#Features">
        <motion.button
        whileHover={{scale : 1.1,
        paddingLeft: "15px"}}
        whileTap={{scale: 0.9}}
        >
        <Text
        position="relative"
        paddingTop="15px"
        // paddingLeft="10px"
        fontSize="20px"
        color="white"
        className=' hover:cursor-pointer'
        fontWeight="bolder"
        // alignSelf="end"
        // backgroundColor="red"
        height="100%"
        
        >
            
       {`Learn More About Our Agents!`}
     
        </Text>
        </motion.button>
        </a>
       

        {/* <Text
        position="relative"
        paddingTop="10px">
       {`Moreover, our advanced agents is designed to improve communication. It's not just a tool, it's a communication partner that's always ready to assist. It's powered by the latest AI technologies, ensuring quality responses and answers. With our innovative tools, you can save time, increase efficiency, and take your business operations to the next level.`}
        </Text> */}
      
      </Grid>
     
     

      
      </Grid>

      {/* <Grid container
      position="relative"
    //   marginTop="60px"
    // bottom="10px"
    paddingBottom="40px"
    paddingTop="15px"
      display="flex"
    //   backgroundColor="grey"   
      justifyContent="center"
      alignItems="end"
    //   gap="20px"
      height="100%"
      color="white"
    //   width="100vh"

      // className="mt-20 flex flex-wrap gap=20"
      >
        Learn More About Our Agents!
      </Grid> */}


        </Grid>
        {/* </a> */}


    </motion.section>

  )
}
