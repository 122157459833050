import { Flex, TabItem, Tabs, Text, View} from '@aws-amplify/ui-react'
import { Grid } from '@mui/material'
import React from 'react'
import '../App.css';
import Modal from 'react-modal'
import atlaschain from '../realm-scripts/atlassemantic';
import getUserChat from '../realm-scripts/getUserChat';
import QueryboxGeneralAgent from './QueryboxGeneralAgent';
import QueryboxDummyAgent from './QueryboxDummyAgent';
import CustomUpload from './CustomUpload';
import MyDocumentsDisplay from './MyDocumentsDisplay';
import QueryboxDocumentAgent from './QueryboxDocumentAgent';
import realmGetUser from '../realm-scripts/realmGetUser';
import getuser from '../aws_utils/getAuthuser';
import QueryboxImageAgent from './QueryboxImageAgent';




export default function Querybox(props) {

// RESIZE ERROR COMING FROM QUERT INPUT
    const currentproject = props.projectname
    const documentslected = props.documentselected
    const documentstoggle = props.documentstoggle
    const openselectprojectmodal = props.openselectprojectmodal
    const [projectconversation, setprojectconversation] = React.useState([])
    const UserChat = async () => {
      const userschat = await getUserChat(currentproject)
      // console.log("Project", currentproject)
      setprojectconversation(userschat)
      }
      

    React.useEffect(() => {
      // console.log("Current Project", currentproject)
      if(currentproject === "" || currentproject === null || currentproject === undefined){
        // openselectprojectmodal()
        // console.log("Current Project Null")
        return
      }
      UserChat()
      
    }, [currentproject])

  const [subscribed, setsubscribed] = React.useState(true)
  React.useEffect(() => {
    const currentUser = async () => {
    const user = await getuser()
    const sub = user.attributes.sub

    try {
      const realmuser = await realmGetUser(sub)
    // console.log("Realm User", realmuser)
      const allagents = realmuser.all_agents
    // console.log(realmuser)
      setsubscribed(allagents)
    } catch (error) {
      console.log(error)
    }
    

    }

    currentUser()
  }, [])

    

    const [clickedProject, setclickedProject] = React.useState(false)
    const closeProject = async () => {
        setclickedProject(false) 
        // navigate("/Home#Agents")
        
    }
    const [clickedSA, setclickedSA] = React.useState(false)
    const closeSAModal = async () => {
      // console.log("close?")
        // navigate("/GetStarted");
        setclickedSA(false) 
        
    }

    const [clickedDocA, setclickedDocA] = React.useState(false)
    const closeDocAModal = async () => {
        // navigate("/GetStarted");
        setclickedDocA(false)
    }

    const [clickedDumA, setclickedDumA] = React.useState(false)
    const closeDumAModal = async () => {
        setclickedDumA(false)
    }

    const [clickedImageA, setclickedImageA] = React.useState(false)
    const closeImageAModal = async () => {
        setclickedImageA(false)
    }
    
 
    let mongocollectionname = props.mongocollectionname
    const mongotoggle = props.mongotoggle
    const documentdata = props.documentdata
    const documenttoggle = props.doucmenttoggle
    
    const [query, setQuery] = React.useState("")
    const [response, setResponse] = React.useState("")

    const [dataloaded, setDataloaded] = React.useState(false)
    const [isloading, setisloading] = React.useState(false)

    const [mongoData, setmongoData] = React.useState(false)

    // const [pdfData, setpdfData] = React.useState(false)

    const [queryJson, setqueryJson] = React.useState(false)
    // const [jsonData, setjsonData] = React.useState({})
   

    // Function to blank out query and response
    // const [resetQueryResponse, setresetQueryResponse] = React.useState(props.resetQueryResponse)
    const resetQueryResponse = props.resetQueryResponse
    const restQRState = props.restQRState
    React.useEffect(() => {
      // console.log("Props", resetQueryResponse)
      // console.log("Reset Query Box", resetQueryResponse)
      if(resetQueryResponse === true){
      // console.log("Query Box resetted")
      setQuery("")
      setResponse("")
      setDataloaded(false)
      setmongoData(false)
      setqueryJson(false)
      setcollectname("")
      restQRState()
      setisloading(false)
      
      // setresetQueryResponse(false)
      }
    }, [resetQueryResponse])
    
      
   


    // Query Mongo Document
    const AtlasQuery = async () => {
      if(query === "" || mongocollectionname === ""){
        alert('Must Enter Query!')
          setisloading(false);
          return
        } else {

        try {
          const response = await atlaschain({
            question: query, 
            collection: mongocollectionname
          })

          // const res = JSON.parse(response)
          // console.log("Query box at res", res.body)

          setResponse(response)
          setisloading(false)
        } catch (error) {
          console.log(error)
          setResponse("error")
          setisloading(false)
        }}

    }

    // No Project modal
    const [newquery, setnewquery] = React.useState(false)
    React.useEffect(() => {
      // console.log("New query triggered", newquery)
      const refreshquerybox = async () => {
        UserChat()
        setnewquery(false)
      }
      refreshquerybox()
    }, [newquery])
    
  
 
    // QueryBox handle Mongo Document
    const [collectname, setcollectname] = React.useState(mongocollectionname)
    React.useEffect(() => {
        // console.log("Collection Name", mongocollectionname)
        if(mongocollectionname === null){
            // console.log("Mongo Collection Name NUll", "")
            setDataloaded(false)
            setmongoData(false)
            setisloading(false)
            // setcollectname("")
            return
        } 
    }, [collectname, mongocollectionname])


    const title = `Document Agent ${subscribed ? "⭐" : ""}`

    const [agentSelected, setagentSelected] = React.useState(false)
// ${matches ? "pr-[30px]" : "0px"} ${matches ? "pl-[30px]" : "0px"} 
  return (
     <View
      className={`flex relative w-full
        
         justify-start  ${agentSelected ? "justify-start" : "justify-center"} pl-5 pr-5 pt-5 `}
          
          >
    <View
    className={`relative flex flex-col ${agentSelected ? "w-full" : ""} `}
   
    
    >

      <Tabs
      justifyContent="space-evenly"
    
     
      backgroundColor="rgba(255,255,255,.3)"
     
      boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
      borderRadius="15px"
      position="relative"
      wrap="wrap"
      defaultIndex={5}
      
      direction={agentSelected ? "row" : "column" }
    
      >
        

      <TabItem
      title={title} // Document Agent ⭐
      className='TabsItem'
      display={subscribed ? "block" : "none"}
      // width="50px"
      onClick={()=>{
        setnewquery(true)
        setagentSelected(true)
        if(currentproject === ""){
          openselectprojectmodal()
        } else {
        setclickedDocA(true) 
        }
      }}
     
      // paddingTop="20px"
      >
        <Modal
            isOpen={clickedProject}
            onRequestClose={closeProject}
            style={{
                overlay: {
                position: 'fixed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                zIndex: 999,
                padding: '20px'
                },
                content: {
                position: 'relative',
        //   display
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                border: '1px solid #ccc',
                
        //   background: 'blue',
        //   width: "302px",
        //   overflow: 'auto',
                backgroundColor:"rgba(222,136,101,.9",
                WebkitOverflowScrolling: 'touch',
                borderRadius: '20px',
                outline: 'none',
                padding: '30px'
                
                }
            }}
            >
              
              
              <Flex
              position="relative"
              justifyContent="center"
              alignItems="center"
              direction="column"
              textAlign="center"
              // backgroundColor="pink"
              >
            {/* <ReactMarkdown> */}
            <Text
            position="relative"
            color="white"
            fontSize="20px"
            fontWeight="extrabold"
            // backgroundColor="pink"
            // lineHeight="30px"
            // padding=" 20px 20px 20px 20px"
            >
              
                Select an Agent!
                

            </Text>
            
            
              </Flex>
              
              
        
            {/* <NewProject
            closeprojectmodal={closeModal}
            currentproject={currentproject}
            /> */}
            {/* <SignIn /> */}

        </Modal>

         <Tabs
    className="Tabs"
    width="auto"
    // backgroundColor="pink"
    position="relative"
    spacing="relative"
    // display={subscribed ? "flex" : "none"}
    //  color="red"
    wrap="wrap"
    >

      <TabItem title="My Documents"
      // display={showtabs ? "block" : "none"}
      // display={subscribed ? "block" : "none"}
      className="TabsItem"
      // onClick={() => {
      //   const reset = async () => {
      //   resetQueryResponse(true) 
      //   mongotoggle(false) 
      //   documenttoggle(false)
      //   }
      //   reset()
      // }}
      >
        <Grid
        position="relative"
        // width="50%"
        // backgroundColor="pink"
        // display={subscribed ? "none" : "block"}
        >
        <MyDocumentsDisplay 
        
        
        documentselected={documentslected} 
        documenttoggle={documentstoggle}
        projectname={currentproject}
        />
        </Grid>
      
      </TabItem>

      <TabItem title="Upload Documents"
      className="TabsItem"
      // display={subscribed ? "block" : "none"}
      // display={showtabs ? "block" : "none"}
      >
        <CustomUpload 
        projectname={currentproject}
        /> 
      </TabItem>

        </Tabs> 

      
      <QueryboxDocumentAgent 
      projectname={currentproject}
      documentdata={documentdata}
      documenttoggle={documenttoggle}
      
      />


      </TabItem>

      <TabItem
      title="Search Agent ⭐"
      className='TabsItem'
      display={subscribed ? "block" : "none"}
      // width="100%"
      onClick={() => {
        setagentSelected(true)
        if(currentproject === ""){
          openselectprojectmodal()
        } else {
        setclickedSA(true)
        }
      }}
      >
        <QueryboxGeneralAgent projectname={currentproject}/>
        
      </TabItem>

      <TabItem
      title="Dummy Agent"
      // maxWidth="300px"
      // width="100%"
     
      className='TabsItem'
      onClick={() => {
        setagentSelected(true)
        if(currentproject === ""){
          openselectprojectmodal()
        } else {
        setclickedDumA(true)
        }
      }}
      >
        <QueryboxDummyAgent projectname={currentproject}/>
      
      </TabItem>

      
      <TabItem
      title="Image Agent"
      // maxWidth="300px"
      // width="100%"
     
      className='TabsItem'
      onClick={() => {
        setagentSelected(true)
        if(currentproject === ""){
          openselectprojectmodal()
        } else {
        setclickedImageA(true)
        }
      }}
      >
        <QueryboxImageAgent projectname={currentproject}/>
        {/* <QueryboxDummyAgent projectname={currentproject}/> */}
      
      </TabItem>
    


     </Tabs>
       
        <Modal
            isOpen={clickedSA}
            onRequestClose={closeSAModal}
            style={{
                overlay: {
                position: 'fixed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                zIndex: 999,
                padding: '20px'
                },
                content: {
                position: 'relative',
        //   display
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                border: '0px solid #ccc',
                
        //   background: 'blue',
          width: "400px",
        //   overflow: 'auto',
                backgroundColor:"grey",
                WebkitOverflowScrolling: 'touch',
                borderRadius: '20px',
                outline: 'none',
                padding: '30px'
                
                }
            }}
            >

              <Flex
              position="relative"
              justifyContent="center"
              alignItems="center"
              direction="column"
              textAlign="center"
              // backgroundColor="rgba(222,136,101,1"
              >
            {/* <ReactMarkdown> */}
            <Text
            position="relative"
            color="white"
            fontSize="20px"
            fontWeight="extrabold"
            // lineHeight="30px"
            // padding=" 20px 20px 20px 20px"
            >
              
                Welcome to your {currentproject} Search Agent!
                

            </Text>
            <Text
             position="relative"
             color="white"
             paddingTop="10px"
             >
            Query your Search Agent about anything and get responses that are up to date with current events!
            Keep query to one question for a more accurate response.
            Enter Query in "Enter Query" box then click "Query".
            </Text>
            <Text
             position="relative"
             color="blue"
             fontSize="14px"
             paddingTop="5px"
             fontWeight="bold"
             >
            Note: Search Agent can reference responses from Document Agent and Dummy Agent queries
            </Text>
            
              </Flex>
              
        
            {/* <NewProject
            closeprojectmodal={closeModal}
            currentproject={currentproject}
            /> */}
            {/* <SignIn /> */}

        </Modal>

        <Modal
            isOpen={clickedDocA}
            onRequestClose={closeDocAModal}
            style={{
                overlay: {
                position: 'fixed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                zIndex: 999,
                padding: '20px'
                },
                content: {
                position: 'relative',
        //   display
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                border: '0px solid #ccc',
                
        //   background: 'blue',
          width: "400px",
        //   overflow: 'auto',
                // backgroundColor:"rgba(222,136,101,.9",
                backgroundColor:"grey",
                WebkitOverflowScrolling: 'touch',
                borderRadius: '20px',
                outline: 'none',
                padding: '30px'
                
                }
            }}
            >

            <Flex
              position="relative"
              justifyContent="center"
              alignItems="center"
              direction="column"
              textAlign="center"
              // backgroundColor="rgba(222,136,101,1"
              >
            {/* <ReactMarkdown> */}
            <Text
            position="relative"
            color="white"
            fontSize="20px"
            fontWeight="extrabold"
            // lineHeight="30px"
            // padding=" 20px 20px 20px 20px"
            >
              
                Welcome to your {currentproject} Document Agent!
                

            </Text>
            <Text
             position="relative"
             color="white"
             fontSize="20px"
             paddingTop="5px"
             >
              {`Upload your document(s) then query away!
            `}
            </Text>
           <Text
             position="relative"
             color="blue"
             fontSize="14px"
             paddingTop="5px"
             fontWeight="bold"
             >
            {`Note: Upload your document(s) then preview them in "My Documents"! \n Can now upload and query image file types`}
            </Text>
            
              </Flex>
              
        
            {/* <NewProject
            closeprojectmodal={closeModal}
            currentproject={currentproject}
            /> */}
            {/* <SignIn /> */}

        </Modal>

    
        <Modal
            isOpen={clickedDumA}
            onRequestClose={closeDumAModal}
            style={{
                overlay: {
                position: 'fixed',
                display: 'flex',
                justifyContent: 'flex-end',
                // alignItems: 'center',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                zIndex: 999,
                padding: '20px',
                paddingTop: "95px"
                },
                content: {
                position: 'relative',
        //   display
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                border: '0px solid #ccc',
                
        //   background: 'blue',
        //   width: "302px",
        //   overflow: 'auto',
                backgroundColor:"transparent",
                WebkitOverflowScrolling: 'touch',
                borderRadius: '20px',
                outline: 'none',
                padding: '0px'
                
                }
            }}
            >
                  {/* <motion.div
initial={{ opacity: 0, scale: 0.5 }}
animate={{ opacity: 1, scale: 1 }}
transition={{
  duration: 0.2,
  ease: [0, 0.50, 0.2, 1.01],
  scale: {
    type: "spring",
    damping: 5,
    stiffness: 100,
    restDelta: 0.001
  }
}}
> */}
{/* <motion.div
    animate={{
      scale: [1, 2, 2, 1, 1],
      rotate: [0, 0, 270, 270, 0],
      borderRadius: ["20%", "20%", "50%", "50%", "20%"],
    }}
  > */}
          <Flex
          // backgroundColor="rgba(222,136,101,.9)"
          backgroundColor="grey"
          borderRadius= '20px'
          padding='30px'
          width="300px"

          >
          
          
            <Flex
              position="relative"
              justifyContent="center"
              alignItems="center"
              direction="column"
              textAlign="center"
              // backgroundColor="rgba(222,136,101,1"
              >
            {/* <ReactMarkdown> */}
            <Text
            position="relative"
            color="white"
            fontSize="20px"
            fontWeight="extrabold"
            // lineHeight="30px"
            // padding=" 20px 20px 20px 20px"
            >
              
                Welcome to your {currentproject} Dummy Agent!
                

            </Text>
          
            {/* <Text
             position="relative"
             color="white"
             fontSize="20px"
             paddingTop="5px"
             >
              Pass in text and tell the agent what you want
            </Text>
            <Text
             position="relative"
             color="white"
             fontWeight="extrabold"
             fontSize="20px"
             paddingTop="5px"
             >
              OR
            </Text> */}
            <Text
             position="relative"
             color="white"
             fontSize="20px"
             paddingTop="5px"
             >
               Enter your question in "Enter Query" box. Then click "Query". Ask whatever you want.
            </Text>
            <Text
             position="relative"
             color="blue"
             fontSize="14px"
             paddingTop="5px"
             fontWeight="bold"
             >
            Note: Dummy Agent DOES NOT SAVE ANY DATA, has no reference to past chat history. Used for quick answers.
            </Text>
            
            </Flex>
            
            </Flex>
{/* </motion.div> */}
            {/* </motion.div> */}
              
        
            {/* <NewProject
            closeprojectmodal={closeModal}
            currentproject={currentproject}
            /> */}
            {/* <SignIn /> */}

        </Modal>
        

        <Modal
            isOpen={clickedImageA}
            onRequestClose={closeImageAModal}
            style={{
                overlay: {
                position: 'fixed',
                display: 'flex',
                justifyContent: 'flex-end',
                // alignItems: 'center',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                zIndex: 999,
                padding: '20px',
                paddingTop:"95px"
                },
                content: {
                position: 'relative',
        //   display
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                border: '0px solid #ccc',
                //  backgroundColor:"transparent",
                
        //   background: 'blue',
        //   width: "302px",
        //   overflow: 'auto',
                overflow: "hidden",
                // backgroundColor:"rgba(222,136,101,.9",
                height:"340px",
                width: "auto",
                backgroundColor:"grey",
                // WebkitOverflowScrolling: 'touch',
                borderRadius: '20px',
                outline: 'none',
                padding: '30px'
                
                }
            }}
            >

            <Flex
              position="relative"
              justifyContent="center"
              alignItems="center"
              direction="column"
              textAlign="center"
              width="300px"
              // height="200px"
              // backgroundColor="pink"
              // backgroundColor="rgba(222,136,101,1"
              >
            {/* <ReactMarkdown> */}
            <Text
            position="relative"
            color="white"
            fontSize="20px"
            fontWeight="extrabold"
            // lineHeight="30px"
            // padding=" 20px 20px 20px 20px"
            >
              
            Welcome to your {currentproject} Image Agent!
                

            </Text>
            <Text
             position="relative"
             color="white"
             fontSize="20px"
             paddingTop="5px"
             >
              Enter a description of the image you want to generate in the "Enter Query" box then click "Query"!
            </Text>
           <Text
             position="relative"
             color="blue"
             fontSize="14px"
             paddingTop="5px"
             fontWeight="bold"
             >
            Note: Image Agent cannot reference responses from other agents. However image agent does have chat history of it's own
            </Text>
            
              </Flex>
              
        
            {/* <NewProject
            closeprojectmodal={closeModal}
            currentproject={currentproject}
            /> */}
            {/* <SignIn /> */}

        </Modal>


    </View>
    </View>
  )
}
