import getuser from "../aws_utils/getAuthuser";


export default async function getUserChat(projectname) {
    const Realm = require("realm-web"); 
    const REALM_ID = process.env.REACT_APP_REALM_APP_ID;
    const REALM_KEY = process.env.REACT_APP_REALM_APP_KEY;
    const app = new Realm.App({id: REALM_ID});
    const {
      BSON: { ObjectId },
    } = Realm;
  
    // Authenticate the user
    try {
      const credentials = Realm.Credentials.apiKey(REALM_KEY)
      await app.logIn(credentials);
    
    } catch (error) {
      console.log("Error in Logging", error) 
    }
    
    
    try {
        
        const currentuser = await getuser();
        const userid = currentuser.username;
        const mongo = app.currentUser.mongoClient("dbhaiapp");
        // console.log("User", userid)
        // console.log("Projectname", projectname)
        const collection = mongo.db("dbhprojects").collection("userschat");
        const results = await collection.find({
        "userId": userid,
        "project": projectname
      });
      return results
        // console.log("Sub id", currentuser.username)
    } catch (error) {
        console.log("Error from pulling Colletcion", error)  
    }

  
}
