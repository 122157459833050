import { InvokeCommand, LambdaClient } from "@aws-sdk/client-lambda";
import getAuthusercreds from "../aws_utils/getAuthusercreds";


export default async function invokelangchainPDFloader(key) {
    // console.log("Invoking PDF Loader Lambda ...")
   
    const getcreds = await getAuthusercreds()
  const id = getcreds.identityId
    

    try {
        const config = {
            region: 'us-east-1',
            credentials: {
                accessKeyId: process.env.REACT_APP_REALM_AWSID,
                secretAccessKey: process.env.REACT_APP_REALM_AWSKEY
        }}
        const client = new LambdaClient(config);
        // console.log("Client", client)


  

const input = { // InvocationRequest
  FunctionName: "arn:aws:lambda:us-east-1:457968394077:function:pdfloaderagent", // required
//   InvocationType: "Event",
  LogType: "Tail",
//   ClientContext: "STRING_VALUE",
  Payload: JSON.stringify({
            "key" : key,
            "identityId" : id
        }),

};


        const command = new InvokeCommand(input);
        // console.log("COmmand", command)

        const response = await client.send(command);
        // console.log(response)
        const decoder = new TextDecoder('utf-8');
        const text = decoder.decode(response.Payload);
        // console.log(text)
        return text
     
        
    } catch (error) {

        console.log("Error ?", error)
        
    }
}

