/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Divider, Flex, Icon, Menu, MenuButton, MenuItem, Text, View } from "@aws-amplify/ui-react";
import { Grid, useMediaQuery } from "@mui/material";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal'
import realmGetUser from "../realm-scripts/realmGetUser";
import portal from "../aws_utils/checkoutsession";
import portalsession from "../aws_utils/portalsession";
import getuser from "../aws_utils/getAuthuser";
import { motion } from "framer-motion"




export default function Personalaccountnavbar(props) {
  const { overrides, user, ...rest } = props;
  const matches = useMediaQuery('(min-width:100px)');
  const usermatches = useMediaQuery('(min-width:500px)');

  const [subscribed, setsubscribed] = React.useState(false)
  const [customerid, setcustomerid] = React.useState("")
  React.useEffect(() => {
    const currentUser = async () => {

    // console.log("user", user)
    const user = await getuser()
    const sub = user.attributes.sub
    // console.log("Sub",sub)

    try {
      const realmuser = await realmGetUser(sub)
    // console.log("Realm User", realmuser)
    setcustomerid(realmuser.stripe_id)
    
    const allagents = realmuser.all_agents
    // console.log("All agents?", allagents)
    setsubscribed(allagents)
    } catch (error) {
      console.log(error)
    }
    

    }

    currentUser()
  }, [user])
  
  const [clickedProject, setclickedProject] = React.useState(false)
    const closeProject = async () => {
        setclickedProject(false)     
    }
  
  
  const navigate = useNavigate()
  // const history = unstable_HistoryRouter()

  const signOut = async () => {
    await Auth.signOut()
    navigate('/')
  }

  const navAbout = async () => {
    navigate('/')
  }

  const subscribenow = async () => {
    // console.log(user.attributes.email)

    if(subscribed === false){
    // const email = user.attributes.email
    const url = await portal(customerid)
    // console.log(url)
    window.location.href = url
  }

  if(subscribed === true){
    // const email = user.attributes.email
    const url = await portalsession(customerid)
    // console.log(url)
    window.location.href = url
  }
    // navigate('url')
    
    
  }

  // React.useEffect(() => {
  //   const subscribenow = async () => {
  //     const url = await portal()
  //     console.log(url)
  //     navigate(url)
  //   }
  //   subscribenow()
 
  // }, [])
  

  
  return (


    <View
    className="fixed flex justify-between items-center w-full h-[75px] bg-[rgba(225,255,255,.3)] z-10"
    // container
      // // width="100vw"
      // height="75px"
      // display="flex"
      
      // gap="unset"
      // alignItems="center"
      // justifyContent="center"
      // position="absolute"
      // boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
      // // padding="0px 0px 0px 0px"
      // zIndex="9"
      // paddingLeft="20px"
      // paddingRight="6px"
      // // backgroundColor="rgba(222,136,101,.5)"
      // backgroundColor="rgba(225,255,255,.3)"
      // wrap="nowrap"
      // backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "Personalaccountnavbar")}
      {...rest}
    >
      {/* <View
        padding="0px 0px 0px 0px"
        width="1440px"
        height="90px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="relative"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        {...getOverrideProps(
          overrides,
          "Personal account, default no notifcation"
        )}
      > */}

        {/* <View
          width="1440px"
          height="90px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "base")}
        ></View> */}

        {/* <Icon
          width="40px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 1, height: 40 }}
          paths={[
            {
              d: "M0 0L40 0L40 -1L0 -1L0 0Z",
              stroke: "rgba(19,42,83,0.1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="27.78%"
          bottom="72.22%"
          left="79.17%"
          right="18.06%"
          transformOrigin="top left"
          transform="rotate(90deg)"
          {...getOverrideProps(overrides, "Divider")}
        ></Icon> */}


        
          <Grid container
             position="relative"
             width="250px"
             height="auto"
             

             display={matches ? "flex" : "none" }
             justifyContent="flex-start"
             alignItems="center"
             paddingLeft="20px"
             top="-1px"
             gap="15px"
            //  backgroundColor="red"
             wrap="nowrap"
             >

       
              <a href="#Project">
              <motion.button
        whileHover={{scale : 1.1}}
        whileTap={{scale: 0.9}}
        
        >
              <Text
                fontFamily="Nunito"
                variation="link"
                fontSize="16px"
                fontWeight="700"
                color="white"
                lineHeight="21.823999404907227px"
                textAlign="left"
                // display={matches ? "block" : "none"}
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                // backgroundColor="red"
                children="Projects"
                
                className="hover:cursor-pointer"
              
              ></Text>
              </motion.button>
              </a>
            
             
                <a href="#Agents">
                <motion.button
        whileHover={{scale : 1.1}}
        whileTap={{scale: 0.9}}
        
        >
               <Text
                fontFamily="Nunito"
                fontSize="16px"
                fontWeight="700"
                color="white"
                lineHeight="21.823999404907227px"
                textAlign="left"
                // display={matches ? "block" : "none"}
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                // backgroundColor="red"
                children="Agents"
                className="hover:cursor-pointer"
                // onClick={()=>{
                //   setclickedProject(true)
                // }}
               
              
              ></Text>
              </motion.button>
              </a>
            
          </Grid>

          

          <Grid container item
            gap="8px"
            direction="row"
            width="100%"
            height="100%"
            justifyContent="flex-end"
            alignItems="center"
            // shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            // backgroundColor="green"
            wrap="nowrap"
            {...getOverrideProps(overrides, "Profile ")}
          >
            {/* <Icon
              width="48px"
              height="48px"
              viewBox={{ minX: 0, minY: 0, width: 48, height: 48 }}
              paths={[
                {
                  d: "M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z",
                  fill: "rgba(196,196,196,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Ellipse 20")}
            ></Icon> */}
            <Text
              fontFamily="Nunito"
              fontSize="16px"
              fontWeight="700"
              color="white"
              lineHeight="21.823999404907227px"
              textAlign="left"
              display={usermatches ? "block" : "none"}
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              // backgroundColor="red"
              children={user?.attributes?.email}
              {...getOverrideProps(overrides, "Sam Miller")}
            ></Text>
              
            <Text
            position="relative"
            paddingLeft="5px"
            fontWeight="semibold"
            // backgroundColor="red"
            justifyContent="center"
            color="white"
            
            // wrap="nowrap"
            textAlign="center"
            // style={{
            //   wrap: "nowrap"
            // }}
            display={subscribed ? "flex" : "none"}>
             GateLeak Pro 🎉
            </Text>

             <Menu
                triggerClassName="my-menu-trigger"
                className="my-menu-content"
                menuAlign="end"
                width="200px"
                height="auto"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                marginTop="10px"
                trigger={

                <MenuButton
                className="my-menu-trigger"
                
                borderRadius='5px'
                
                 >
                    <Icon
                  width="13.5px"
                  height="6.66px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 13.5,
                    height: 6.662109375,
                  }}
                  paths={[
                    {
                      d: "M0.21913 0.220211C0.511724 -0.0729808 0.986598 -0.0734649 1.27979 0.21913L5.01935 3.95108C5.4238 4.3547 5.69564 4.62517 5.92172 4.81672C6.14109 5.00259 6.26763 5.06987 6.36421 5.10121C6.61497 5.18257 6.88503 5.18257 7.13579 5.10121C7.23237 5.06987 7.35891 5.00259 7.57828 4.81672C7.80436 4.62517 8.0762 4.3547 8.48064 3.95108L12.2202 0.21913C12.5134 -0.0734649 12.9883 -0.0729808 13.2809 0.220211C13.5735 0.513402 13.573 0.988276 13.2798 1.28087L9.54022 5.01282L9.51868 5.03432C9.14132 5.41093 8.82631 5.72531 8.54795 5.96116C8.25761 6.20717 7.95825 6.41133 7.59873 6.52798C7.04707 6.70698 6.45293 6.70698 5.90127 6.52798C5.54175 6.41133 5.24239 6.20717 4.95204 5.96116C4.67368 5.7253 4.35866 5.41091 3.98129 5.03429L3.95978 5.01282L0.220211 1.28087C-0.0729808 0.988276 -0.0734649 0.513402 0.21913 0.220211Z",
                      fill: "white",
                      fillRule: "evenodd",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                >
                  
                    </Icon>   
                </MenuButton>}
                
                /// Arrow next  to user.name
              >
                
                
                <Flex
                height='50px'
                // backgroundColor="red"
                >
                
                <Text
                marginTop="15px"
                marginLeft="18px"
                color="black"
                
                > 
                {user?.attributes?.email}
                </Text>

                </Flex>

                <Divider />
  
               
              

  <MenuItem
  width='100%'
  height='50px'
  className=" hover:text-white"
  onClick={()=>{
    navAbout()
  }}
  
   >
    <Text
    fontWeight="normal"
    display="flex"
    // justifyContent="center"
    alignItems="center"
    className=" hover:text-white"
    width="100%"
    height="100%"
    // backgroundColor="red"
    
    >
    About
    </Text>
  </MenuItem>

 
  <MenuItem
  width='100%'
  height='50px'
  className=" hover:text-white"
  onClick={()=>{
    subscribenow()
  }}
  
   >
    <Text
    fontWeight="normal"
    display="flex"
    // justifyContent="center"
    alignItems="center"
    className=" hover:text-white"
    width="100%"
    height="100%"
    // backgroundColor="red"
    
    >
      {subscribed ? `Manage GateLeak Pro` : `Unlock All Agents!` }
    </Text>
  </MenuItem>


  {/* <Divider /> */}

  <MenuItem
  width='100%'
  height='50px'
   onClick={()=>{
    signOut()
  
   }}>
    Log out
  </MenuItem>
               
          
            </Menu>
            
          </Grid>

        

        {/* <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          position="absolute"
          top="36.67%"
          bottom="36.67%"
          left="23.89%"
          right="42.99%"
          padding="0px 0px 0px 0px"
          // backgroundColor="yellow"
          {...getOverrideProps(overrides, "Tabs")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 10120937")}
          >
            <Text
              fontFamily="Nunito"
              fontSize="16px"
              fontWeight="400"
              color="rgba(26,26,26,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="75px"
              height="18px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Find plans"
              {...getOverrideProps(overrides, "Find plans")}
            ></Text>
            <View
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "chevron-right11519")}
            >
              <Icon
                width="6.67px"
                height="13.5px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 6.67156982421875,
                  height: 13.5,
                }}
                paths={[
                  {
                    d: "M0.21967 0.21967C0.512563 -0.0732233 0.987437 -0.0732233 1.28033 0.21967L5.01759 3.95693L5.03912 3.97846C5.41683 4.35615 5.73209 4.6714 5.9686 4.95C6.21527 5.24056 6.42001 5.54021 6.53698 5.9002C6.71644 6.45252 6.71644 7.04748 6.53698 7.5998C6.42001 7.95979 6.21527 8.25944 5.9686 8.55C5.73209 8.8286 5.41683 9.14385 5.03912 9.52154L5.01759 9.54307L1.28033 13.2803C0.987437 13.5732 0.512563 13.5732 0.21967 13.2803C-0.0732233 12.9874 -0.0732233 12.5126 0.21967 12.2197L3.95693 8.48241C4.3617 8.07764 4.63296 7.80555 4.82509 7.57924C5.01153 7.35962 5.07899 7.23294 5.11039 7.13627C5.19197 6.88522 5.19197 6.61478 5.11039 6.36373C5.07899 6.26706 5.01153 6.14038 4.82509 5.92076C4.63296 5.69445 4.3617 5.42236 3.95693 5.01759L0.21967 1.28033C-0.0732233 0.987437 -0.0732233 0.512563 0.21967 0.21967Z",
                    fill: "rgba(26,26,26,1)",
                    fillRule: "evenodd",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="5.25px"
                left="9.25px"
                {...getOverrideProps(overrides, "Icon11520")}
              >
                <Icon
                  width="6px"
                  height="12px"
                  viewBox={{ minX: 0, minY: 0, width: 6, height: 12 }}
                  paths={[
                    {
                      d: "M0.53033 -0.53033C0.237437 -0.823223 -0.237437 -0.823223 -0.53033 -0.53033C-0.823223 -0.237437 -0.823223 0.237437 -0.53033 0.53033L0.53033 -0.53033ZM-0.53033 11.4697C-0.823223 11.7626 -0.823223 12.2374 -0.53033 12.5303C-0.237437 12.8232 0.237437 12.8232 0.53033 12.5303L-0.53033 11.4697ZM5.07369 5.38197L4.36039 5.61373L5.07369 5.38197ZM5.07369 6.61803L4.36039 6.38627L5.07369 6.61803ZM-0.53033 0.53033L3.20693 4.26759L4.26759 3.20693L0.53033 -0.53033L-0.53033 0.53033ZM3.20693 7.73241L-0.53033 11.4697L0.53033 12.5303L4.26759 8.79307L3.20693 7.73241ZM3.20693 4.26759C3.6117 4.67236 3.88296 4.94445 4.07509 5.17076C4.26153 5.39038 4.32899 5.51706 4.36039 5.61373L5.78698 5.1502C5.67001 4.79021 5.46527 4.49056 5.2186 4.2C4.97761 3.91613 4.65485 3.59419 4.26759 3.20693L3.20693 4.26759ZM4.26759 8.79307C4.65485 8.40581 4.97761 8.08387 5.2186 7.8C5.46527 7.50944 5.67001 7.20979 5.78698 6.8498L4.36039 6.38627C4.32899 6.48294 4.26153 6.60962 4.07509 6.82924C3.88296 7.05555 3.6117 7.32764 3.20693 7.73241L4.26759 8.79307ZM4.36039 5.61373C4.44197 5.86478 4.44197 6.13522 4.36039 6.38627L5.78698 6.8498C5.96644 6.29748 5.96644 5.70252 5.78698 5.1502L4.36039 5.61373Z",
                      stroke: "rgba(34,39,47,1)",
                      fillRule: "nonzero",
                      strokeLinejoin: "round",
                      strokeWidth: 1,
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="3.12%"
                  bottom="46.88%"
                  left="3.12%"
                  right="71.88%"
                  {...getOverrideProps(overrides, "Vector11521")}
                ></Icon>
              </Icon>
            </View>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 10120938")}
          >
            <Text
              fontFamily="Nunito"
              fontSize="16px"
              fontWeight="400"
              color="rgba(26,26,26,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="112px"
              height="18px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Compare plans"
              {...getOverrideProps(overrides, "Compare plans")}
            ></Text>
            <View
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "chevron-right11524")}
            >
              <Icon
                width="6.67px"
                height="13.5px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 6.671573638916016,
                  height: 13.5,
                }}
                paths={[
                  {
                    d: "M0.21967 0.21967C0.512563 -0.0732233 0.987437 -0.0732233 1.28033 0.21967L5.01759 3.95693L5.03912 3.97846C5.41683 4.35615 5.73209 4.6714 5.9686 4.95C6.21527 5.24056 6.42001 5.54021 6.53698 5.9002C6.71644 6.45252 6.71644 7.04748 6.53698 7.5998C6.42001 7.95979 6.21527 8.25944 5.9686 8.55C5.73209 8.8286 5.41683 9.14385 5.03912 9.52154L5.01759 9.54307L1.28033 13.2803C0.987437 13.5732 0.512563 13.5732 0.21967 13.2803C-0.0732233 12.9874 -0.0732233 12.5126 0.21967 12.2197L3.95693 8.48241C4.3617 8.07764 4.63296 7.80555 4.82509 7.57924C5.01153 7.35962 5.07899 7.23294 5.11039 7.13627C5.19197 6.88522 5.19197 6.61478 5.11039 6.36373C5.07899 6.26706 5.01153 6.14038 4.82509 5.92076C4.63296 5.69445 4.3617 5.42236 3.95693 5.01759L0.21967 1.28033C-0.0732233 0.987437 -0.0732233 0.512563 0.21967 0.21967Z",
                    fill: "rgba(26,26,26,1)",
                    fillRule: "evenodd",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="5.25px"
                left="9.25px"
                {...getOverrideProps(overrides, "Icon11525")}
              >
                <Icon
                  width="6px"
                  height="12px"
                  viewBox={{ minX: 0, minY: 0, width: 6, height: 12 }}
                  paths={[
                    {
                      d: "M0.53033 -0.53033C0.237437 -0.823223 -0.237437 -0.823223 -0.53033 -0.53033C-0.823223 -0.237437 -0.823223 0.237437 -0.53033 0.53033L0.53033 -0.53033ZM-0.53033 11.4697C-0.823223 11.7626 -0.823223 12.2374 -0.53033 12.5303C-0.237437 12.8232 0.237437 12.8232 0.53033 12.5303L-0.53033 11.4697ZM5.07369 5.38197L4.36039 5.61373L5.07369 5.38197ZM5.07369 6.61803L4.36039 6.38627L5.07369 6.61803ZM-0.53033 0.53033L3.20693 4.26759L4.26759 3.20693L0.53033 -0.53033L-0.53033 0.53033ZM3.20693 7.73241L-0.53033 11.4697L0.53033 12.5303L4.26759 8.79307L3.20693 7.73241ZM3.20693 4.26759C3.6117 4.67236 3.88296 4.94445 4.07509 5.17076C4.26153 5.39038 4.32899 5.51706 4.36039 5.61373L5.78698 5.1502C5.67001 4.79021 5.46527 4.49056 5.2186 4.2C4.97761 3.91613 4.65485 3.59419 4.26759 3.20693L3.20693 4.26759ZM4.26759 8.79307C4.65485 8.40581 4.97761 8.08387 5.2186 7.8C5.46527 7.50944 5.67001 7.20979 5.78698 6.8498L4.36039 6.38627C4.32899 6.48294 4.26153 6.60962 4.07509 6.82924C3.88296 7.05555 3.6117 7.32764 3.20693 7.73241L4.26759 8.79307ZM4.36039 5.61373C4.44197 5.86478 4.44197 6.13522 4.36039 6.38627L5.78698 6.8498C5.96644 6.29748 5.96644 5.70252 5.78698 5.1502L4.36039 5.61373Z",
                      stroke: "rgba(34,39,47,1)",
                      fillRule: "nonzero",
                      strokeLinejoin: "round",
                      strokeWidth: 1,
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="3.12%"
                  bottom="46.88%"
                  left="3.12%"
                  right="71.88%"
                  {...getOverrideProps(overrides, "Vector11526")}
                ></Icon>
              </Icon>
            </View>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 10120939")}
          >
            <Text
              fontFamily="Nunito"
              fontSize="16px"
              fontWeight="400"
              color="rgba(26,26,26,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="76px"
              height="18px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="View data"
              {...getOverrideProps(overrides, "View data")}
            ></Text>
            <View
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "chevron-right64324")}
            >
              <Icon
                width="6.67px"
                height="13.5px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 6.671573638916016,
                  height: 13.5,
                }}
                paths={[
                  {
                    d: "M0.21967 0.21967C0.512563 -0.0732233 0.987437 -0.0732233 1.28033 0.21967L5.01759 3.95693L5.03912 3.97846C5.41683 4.35615 5.73209 4.6714 5.9686 4.95C6.21527 5.24056 6.42001 5.54021 6.53698 5.9002C6.71644 6.45252 6.71644 7.04748 6.53698 7.5998C6.42001 7.95979 6.21527 8.25944 5.9686 8.55C5.73209 8.8286 5.41683 9.14385 5.03912 9.52154L5.01759 9.54307L1.28033 13.2803C0.987437 13.5732 0.512563 13.5732 0.21967 13.2803C-0.0732233 12.9874 -0.0732233 12.5126 0.21967 12.2197L3.95693 8.48241C4.3617 8.07764 4.63296 7.80555 4.82509 7.57924C5.01153 7.35962 5.07899 7.23294 5.11039 7.13627C5.19197 6.88522 5.19197 6.61478 5.11039 6.36373C5.07899 6.26706 5.01153 6.14038 4.82509 5.92076C4.63296 5.69445 4.3617 5.42236 3.95693 5.01759L0.21967 1.28033C-0.0732233 0.987437 -0.0732233 0.512563 0.21967 0.21967Z",
                    fill: "rgba(26,26,26,1)",
                    fillRule: "evenodd",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="5.25px"
                left="9.25px"
                {...getOverrideProps(overrides, "Icon64325")}
              >
                <Icon
                  width="6px"
                  height="12px"
                  viewBox={{ minX: 0, minY: 0, width: 6, height: 12 }}
                  paths={[
                    {
                      d: "M0.53033 -0.53033C0.237437 -0.823223 -0.237437 -0.823223 -0.53033 -0.53033C-0.823223 -0.237437 -0.823223 0.237437 -0.53033 0.53033L0.53033 -0.53033ZM-0.53033 11.4697C-0.823223 11.7626 -0.823223 12.2374 -0.53033 12.5303C-0.237437 12.8232 0.237437 12.8232 0.53033 12.5303L-0.53033 11.4697ZM5.07369 5.38197L4.36039 5.61373L5.07369 5.38197ZM5.07369 6.61803L4.36039 6.38627L5.07369 6.61803ZM-0.53033 0.53033L3.20693 4.26759L4.26759 3.20693L0.53033 -0.53033L-0.53033 0.53033ZM3.20693 7.73241L-0.53033 11.4697L0.53033 12.5303L4.26759 8.79307L3.20693 7.73241ZM3.20693 4.26759C3.6117 4.67236 3.88296 4.94445 4.07509 5.17076C4.26153 5.39038 4.32899 5.51706 4.36039 5.61373L5.78698 5.1502C5.67001 4.79021 5.46527 4.49056 5.2186 4.2C4.97761 3.91613 4.65485 3.59419 4.26759 3.20693L3.20693 4.26759ZM4.26759 8.79307C4.65485 8.40581 4.97761 8.08387 5.2186 7.8C5.46527 7.50944 5.67001 7.20979 5.78698 6.8498L4.36039 6.38627C4.32899 6.48294 4.26153 6.60962 4.07509 6.82924C3.88296 7.05555 3.6117 7.32764 3.20693 7.73241L4.26759 8.79307ZM4.36039 5.61373C4.44197 5.86478 4.44197 6.13522 4.36039 6.38627L5.78698 6.8498C5.96644 6.29748 5.96644 5.70252 5.78698 5.1502L4.36039 5.61373Z",
                      stroke: "rgba(34,39,47,1)",
                      fillRule: "nonzero",
                      strokeLinejoin: "round",
                      strokeWidth: 1,
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="3.12%"
                  bottom="46.88%"
                  left="3.12%"
                  right="71.88%"
                  {...getOverrideProps(overrides, "Vector64326")}
                ></Icon>
              </Icon>
            </View>
          </Flex>
          <Text
            fontFamily="Nunito"
            fontSize="16px"
            fontWeight="400"
            color="rgba(26,26,26,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="94px"
            height="18px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Membership"
            {...getOverrideProps(overrides, "Membership")}
          ></Text>
        </Flex> */}

        
        {/* <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          position="absolute"
          top="23.33%"
          bottom="23.33%"
          left="63.02%"
          right="21.56%"
          border="1.5px SOLID rgba(19,42,83,0.1)"
          borderRadius="24px"
          padding="10.5px 14.5px 10.5px 14.5px"
          backgroundColor="rgba(250,250,250,1)"
          {...getOverrideProps(overrides, "Button")}
        >
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Center")}
          >
            <Text
              fontFamily="Nunito"
              fontSize="19px"
              fontWeight="600"
              color="rgba(222,136,101,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Business membership"
              {...getOverrideProps(overrides, "Label")}
            ></Text>
          </Flex>
        </Flex> */}
      {/* </View> */}
        <Modal
            isOpen={clickedProject}
            onRequestClose={closeProject}
            style={{
                overlay: {
                position: 'fixed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                zIndex: 999,
                padding: '20px'
                },
                content: {
                position: 'relative',
        //   display
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                border: '1px solid #ccc',
                
        //   background: 'blue',
        //   width: "302px",
        //   overflow: 'auto',
                backgroundColor:"rgba(222,136,101,.9",
                WebkitOverflowScrolling: 'touch',
                borderRadius: '20px',
                outline: 'none',
                padding: '30px'
                
                }
            }}
            >
              
              
              <Flex
              position="relative"
              justifyContent="center"
              alignItems="center"
              direction="column"
              textAlign="center"
              // backgroundColor="rgba(222,136,101,1"
              >
            {/* <ReactMarkdown> */}
            <Text
            position="relative"
            color="white"
            fontSize="20px"
            fontWeight="extrabold"
            // lineHeight="30px"
            // padding=" 20px 20px 20px 20px"
            >
              
                Select an Agent!
                

            </Text>
            
            
              </Flex>
              
              
        
            {/* <NewProject
            closeprojectmodal={closeModal}
            currentproject={currentproject}
            /> */}
            {/* <SignIn /> */}

        </Modal>
    </View>

  );
}
