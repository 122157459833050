import { Button, Icon, Text, TextField, View } from '@aws-amplify/ui-react';
import { Grid } from '@mui/material';
import React from 'react'
import confirmSignUp from '../aws_utils/awsconfirm';
import { useNavigate } from 'react-router-dom';
import mongoadduser from '../aws_utils/mongoadduser';
import getuser from '../aws_utils/getAuthuser';
import createstripecustomer from '../aws_utils/createstripecustomer';

export default function Confirmation(props) {

const email = props.email
const username = props.username

const [code, setcode] = React.useState("")
const [isdisabled, setisdisabled] = React.useState(true)
const [isloading, setisloading] = React.useState(false)
const navigate = useNavigate()

React.useEffect(() => {
       
    if(code === ""){
        setisdisabled(true)
    } else {
        setisdisabled(false)
    }
  
}, [code])

    // const RDelay =  setTimeout(() => {
    // console.log('This will be logged after 3 seconds');
    // navigate("Home")
    //     }, 3000);

    const go = async () => {
        setTimeout(() => {
        //   console.log('This will be logged after 3 seconds');
          
          setisloading(false) 
          navigate("/Home")
          const updateuser = async () => {
           
            const datauser = await getuser()
            const user = datauser.attributes

            const email = user.email
            const sub = user.sub
            let stripeid = ""
            try {
              const suser = await createstripecustomer(email)
              stripeid = suser.id
            } catch (error) {
              // console.log(error)
            }
            // console.log("Customer Id", stripeid)

            
            try {
              await mongoadduser(username, email, sub, stripeid)
              // console.log("Added User to Mongo Successfully", response)
              
              } catch (error) {
                  // console.log(error)
                  // alert(`Error adding`)
                  
              }
            
            // console.log(result)

          }
          updateuser()
          
        }, 4000);
      }

    const confirm = async () => {
        try {
            await confirmSignUp(email, code)
            // console.log("Confirmed User Data", user)
            alert(`Succesfully Signed Up!`)
            
           
            
            
            
            go()
            
            
            // alert(`${email} Succesfully Signed Up!`)
            
        } catch (error) {
            setisloading(false)
            alert("Error With Confirmation Code")
            // console.log(error)
            
        }
    }


  return (

  

    <Grid container
    // width="100%"
    height="auto"
    display="block"
    gap="unset"
    direction="column"
    alignItems="unset"
    justifyContent="unset"
    position="relative"
    padding="20px 20px 40px 20px"
    boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
        borderRadius="16px"
        // padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255, .8)"

 
  >

      <Grid container 
        width="auto"
        // height="200px"
        display="block"
        // gap="20px"
        // columnGap="20px"
        alignItems="unset"
        direction="column"
        justifyContent="unset"
        position="relative"
        // top="38px"
        // left="48px"
        padding="0px 0px 0px 0px"
        // backgroundColor="yellow"
        // marginRight="40px"
        // marginLeft="40px"
        // {...getOverrideProps(overrides, "Frame 10120994")}
      >



        <View
        width="unset"
        height="25px"
        display="flex"
        gap="unset"
        alignItems="center"
        justifyContent="flex-end"
        position="relative"
        // marginBottom="20px"
        // top="-16.91px"
        // left="572px"
        // borderRadius="24px"
        padding="0px 0px 0px 0px"
        className='cursor-pointer'
        
        // backgroundColor="black"
        // marginRight="15px"
        // {...getOverrideProps(overrides, "Back Button")}
      >
      
          <Icon
          
          onClick={()=>{
           props.confirm()
          }
        }
            width="13.5px"
            height="13.5px"
            viewBox={{ minX: 0, minY: 0, width: 13.5, height: 13.5 }}
            paths={[
              {
                d: "M0.21967 0.21967C0.512563 -0.0732233 0.987437 -0.0732233 1.28033 0.21967L6.75 5.68934L12.2197 0.21967C12.5126 -0.0732233 12.9874 -0.0732233 13.2803 0.21967C13.5732 0.512563 13.5732 0.987437 13.2803 1.28033L7.81066 6.75L13.2803 12.2197C13.5732 12.5126 13.5732 12.9874 13.2803 13.2803C12.9874 13.5732 12.5126 13.5732 12.2197 13.2803L6.75 7.81066L1.28033 13.2803C0.987437 13.5732 0.512563 13.5732 0.21967 13.2803C-0.0732233 12.9874 -0.0732233 12.5126 0.21967 12.2197L5.68934 6.75L0.21967 1.28033C-0.0732233 0.987437 -0.0732233 0.512563 0.21967 0.21967Z",
                fill: "rgba(107,107,107,1)",
                fillRule: "evenodd",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="relative"
            // top="5.25px"
            // left="5.25px"
            // {...getOverrideProps(overrides, "Icon")}
          >
            <Icon
            
              width="12px"
              height="12px"
              viewBox={{ minX: 0, minY: 0, width: 12, height: 12 }}
              paths={[
                {
                  d: "M-0.53033 11.4697C-0.823223 11.7626 -0.823223 12.2374 -0.53033 12.5303C-0.237437 12.8232 0.237437 12.8232 0.53033 12.5303L-0.53033 11.4697ZM12.5303 0.53033C12.8232 0.237437 12.8232 -0.237437 12.5303 -0.53033C12.2374 -0.823223 11.7626 -0.823223 11.4697 -0.53033L12.5303 0.53033ZM0.53033 -0.53033C0.237437 -0.823223 -0.237437 -0.823223 -0.53033 -0.53033C-0.823223 -0.237437 -0.823223 0.237437 -0.53033 0.53033L0.53033 -0.53033ZM11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L11.4697 12.5303ZM0.53033 12.5303L12.5303 0.53033L11.4697 -0.53033L-0.53033 11.4697L0.53033 12.5303ZM-0.53033 0.53033L11.4697 12.5303L12.5303 11.4697L0.53033 -0.53033L-0.53033 0.53033Z",
                  stroke: "rgba(34,39,47,1)",
                  fillRule: "nonzero",
                  strokeLinejoin: "round",
                  strokeWidth: 1,
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="3.12%"
              bottom="46.88%"
              left="3.12%"
              right="46.88%"
            //   {...getOverrideProps(overrides, "Vector")}
            ></Icon>
          </Icon>
        {/* </View> */}
       </View>


        <View
          // paddingTop="10px"
          width="unset"
          height="auto"
          display="flex"
          gap="unset"
          alignItems="center"
          justifyContent="center"
          position="relative"
          // top="-10px"
          // left="0px"
          // backgroundColor="red"
        //   {...getOverrideProps(overrides, "Group 10120934")}
        >
          <Text
            fontFamily="Nunito"
            fontSize="40px"
            fontWeight="700"
            color="rgba(222,136,101,1)"
            lineHeight="48px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="-0.85px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="relative"
            top="0px"
            overflow="hidden"
            left="0px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            // backgroundColor="pink"
            children="Confirmation Code"
            // {...getOverrideProps(overrides, "Enter Confirmation Code")}
          ></Text>
        </View>


        <View
          width="unset"
          height="unset"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="relative"
          paddingTop="15px"
          // top="75px"
          // left="0px"
          // padding="0px 0px 0px 0px"
          // backgroundColor="pink"
        //   {...getOverrideProps(overrides, "Frame 10120993")}
        >
          
           
             
          <TextField
                  width="unset"
                //   height="48px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="relative"
                  
                //   top="0%"
                //   bottom="0%"
                //   left="0%"
                //   right="0%"
                  borderRadius="8px"
                  padding="0px 0px 0px 0px"
                  
                  backgroundColor="rgba(243,244,246,1)"
                  placeholder="Enter confirmation code ..."
                //   className="Enter-Confirmation"
                  value={code}
                  onChange={(c) => {
                    let { value } = c.target;
                    setcode(value)
                  }}
                //     if(value === ""){
                //     setisDisabled(true)
                //   } else setisDisabled(false)}}
                //   {...getOverrideProps(overrides, "Input Style")}


          ></TextField>

               
            
              
{/*           
         
          <Flex
            gap="1px"
            direction="row"
            width="100%"
            height="55px"
            justifyContent="space-between"
            alignItems="center"
            // alignItems="center"
            position="relative"
            // top="26px"
            marginTop="10px"
            top="0px"
            left="0px"
            padding="0px 0px 0px 0px"
            // backgroundColor="grey"
         
          >
            <Text
              fontFamily="Nunito"
              fontSize="14px"
              fontWeight="400"
              color="rgba(107,114,128,1)"
              lineHeight="20px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="center"
            //   maxWidth="fit-content"
            //   maxWidth="70px"
            //   minWidth="90px"
              width={matches ? "200px" : "90px"}
              height="40px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
            //   backgroundColor="red"
              children="Didn’t receive confirmation code?"
            
            ></Text>
             <Button
            // variation="primary"
            fontFamily="Nunito"
            fontSize="14px"
            fontWeight="700"
            color="black"
            lineHeight="20px"
            
            // alignSelf="flex-end"
            // alignSelf="self-end"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="right"
            
            

            letterSpacing="0.1px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="flex-end"
            position="relative"
            // top="calc(50% - 10px - -24px)"
            // left="218px"
            padding="0px 5px 0px 5px"
            whiteSpace="pre-wrap"
            children="Resend Code"
            // backgroundColor="pink"
           
            //  boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
            // onClick={() => {
            //  resendConfirmationCode(username)
            // }}
       
          ></Button>
          </Flex>
 */}

         

        </View>

        <View
        position="relative"
        paddingTop="20px"
        >
        <Button
      variation="primary"
      width="100%"
      height="auto"
      display="flex"
      // paddingTop="15px"
      // marginTop="15px"
      gap="unset"


      alignItems="unset"
      justifyContent="center"
      position="relative"

      isDisabled={isdisabled}
      isLoading={isloading}
      // top="65px"
      // left="48px"
      borderRadius="24px"
      // paddingTop="25px"
      // padding="0px 0px 0px 0px"
      backgroundColor="rgba(222,136,101,1)"
      onClick={()=>{
        confirm();
        setisloading(true)
      }}
    
    >
    
        <Text
       
          color="rgba(255,255,255,1)"
    
          children="Confirm"
   
        ></Text>


        </Button>
        </View>
      </Grid>

     

    </Grid>
   
  )
}
